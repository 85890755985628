import React from 'react';
import { MultiDropzone } from '@popsure/dirty-swan';
import { useTranslation, Trans } from '@getpopsure/i18n-react';

import Center from 'components/center';
import connectedAnswer, {
  ViewProps as GenericViewProps,
} from 'pages/questions/connectedAnswer';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { useUploadFiles } from 'utils/hooks';

const View = ({
  onAnswerQuestion,
  answer,
}: GenericViewProps<'financialHistory', 'futureIncome'>) => {
  const { t } = useTranslation();
  const {
    isSubmitValid,
    uploadFile,
    uploadedFiles,
    removeFile,
    loading,
  } = useUploadFiles({
    initialValue: answer ?? [],
    questionId: 'futureIncome',
  });

  return (
    <Center>
      <h1 className="p-h1">{generalQuestionsTitleMapping.futureIncome?.(t)}</h1>
      <p className="p-p mt8">
        <Trans i18nKey="questions.futureincome.description">
          Please provide your <span className="fw-bold">business plan</span> or{' '}
          <span className="fw-bold">contracts</span> with any existing clients.
        </Trans>
      </p>
      <div className="mt16">
        <MultiDropzone
          onFileSelect={uploadFile}
          uploading={loading}
          uploadedFiles={uploadedFiles}
          onRemoveFile={removeFile}
          maxFiles={1}
        />
      </div>
      <button
        data-cy="questionnaire-continue"
        className="p-btn--primary wmn3 mt24"
        type="submit"
        disabled={!isSubmitValid}
        onClick={() => {
          if (!isSubmitValid) {
            return;
          }

          onAnswerQuestion(uploadedFiles);
        }}
      >
        {t('questions.futureincome.continue.action', 'Continue')}
      </button>
      <button
        data-cy="questionnaire-skip"
        className="p-btn--secondary wmn2 ml16"
        type="button"
        onClick={() => onAnswerQuestion([])}
      >
        {t('questions.futureincome.skip.action', 'Skip')}
      </button>
    </Center>
  );
};

export default connectedAnswer('financialHistory', 'futureIncome')(View);
