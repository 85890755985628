import { connect } from 'react-redux';

import View from './view';
import { BrokerInfo } from 'models/brokerInfo';
import { AppState } from 'reducers';
import { getBrokerInfo } from 'selectors/metadata';

interface StateProps {
  brokerInfo?: BrokerInfo;
  dateOfBirth?: string;
}

export type ViewProps = StateProps;

const mapStateToProps = (state: AppState): StateProps => ({
  brokerInfo: getBrokerInfo(state),
  dateOfBirth: state.questionnaire.personalInfo?.dateOfBirth,
});

export default connect(mapStateToProps)(View);
