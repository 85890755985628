import React from 'react';
import { useLocation } from 'react-router-dom';

import { setJWT as setJWTAction } from 'actions/session';
import { connect } from 'react-redux';

const View = ({ setJWT, children }: ViewProps) => {
  const token = new URLSearchParams(useLocation().search).get('token');
  if (token) {
    setJWT(token);
  }
  return <>{children}</>;
};

interface DispatchProps {
  setJWT: (token: string) => void;
}

interface OwnProps {
  children: React.ReactNode;
}

type ViewProps = DispatchProps & OwnProps;

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  setJWT: (token: string) => dispatch(setJWTAction(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
