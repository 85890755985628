import axios from 'axios';

import { transformQuestionnaireModel } from './transformQuestionnaireModel';
import { Quote, User, BankAccountDetails } from 'models';
import { AuthenticatedResponse } from 'models/network';
import { getJWT } from 'selectors/session';
import { store } from 'reducers';
import { isBrokerVersion } from 'utils/isBrokerVersion';
import { BrokerInfo } from 'models/brokerInfo';
import { v4 as uuidv4 } from 'uuid';

const endpoint = isBrokerVersion
  ? process.env.REACT_APP_BROKER_VERSION_ENDPOINT
  : process.env.REACT_APP_ENDPOINT;

export const instance = axios.create({
  baseURL: endpoint,
});

instance.interceptors.request.use((config) => {
  if (!config.headers) return config;
  const state = store.getState();
  const jwt = getJWT(state);

  if (jwt) {
    config.headers.Authorization = `Bearer ${jwt}`;
  } else {
    config.headers.Authorization = '';
  }

  return config;
});

export async function notifyBroker(
  brokerInfo: BrokerInfo,
  event: 'BLOCKER_REACHED' | 'APPLICATION_STARTED',
  dateOfBirth: string
) {
  return instance.post('/notify', { brokerInfo, event, dateOfBirth });
}

export async function sendSignInEmail(email: string) {
  return instance.post('/account/signin', {
    email,
  });
}

export async function verifyCustomerEmail(email: string) {
  return instance.post<{ email: string; userExists: boolean }>(
    '/user/email_validation',
    { email }
  );
}

export async function signInWithTemporaryLoginCode(
  code: string,
  email: string
) {
  return instance.post<AuthenticatedResponse>(
    '/account/signin/code',
    {
      email,
      code,
    },
    {
      withCredentials: true,
    }
  );
}

export async function createQuestionnaireUpload(
  file: File,
  questionId: string
) {
  return instance.post<{
    id: string;
    token: string;
    uploadUrl: string;
    downloadUrl?: string;
  }>(isBrokerVersion ? '/uploads' : '/questionnaires/uploads', {
    filename: file.name,
    questionId,
  });
}

export async function createQuestionnaire(
  questionnaire: ReturnType<typeof transformQuestionnaireModel>
) {
  return instance.post<{ id: string; answers: any }>(
    '/questionnaires/',
    questionnaire
  );
}

export async function downloadTakeawayArchives(questionnaireId: string) {
  return instance.get<{ completed: number; downloadUrl: string }>(
    isBrokerVersion
      ? `/questionnaires/${questionnaireId}/downloads`
      : `/questionnaires/${questionnaireId}/takeaway_archive`
  );
}

export async function downloadApplicationDocuments(applicationId: string) {
  return instance.get<{ completed: number; downloadUrl: string }>(
    `/applications/${applicationId}/document`
  );
}

export async function createUser(email: string) {
  return instance.post<AuthenticatedResponse>(
    isBrokerVersion ? '/account' : '/user',
    { email }
  );
}

export async function updateUser(
  user: Pick<User, 'firstName' | 'lastName' | 'dateOfBirth' | 'gender'>
) {
  return instance.patch('/user', user);
}

export async function uploadFile(
  file: File,
  uploadUrl: string,
  onUploadProgress?: (p: { loaded: number; total: number }) => void
) {
  return instance.put(uploadUrl, file, { timeout: 0, onUploadProgress });
}

export async function startPhoneVerification(phoneNumber: string) {
  return instance.post('/account/phone/verify', { phoneNumber });
}

export async function confirmPhoneNumber(phoneNumber: string, code: string) {
  return instance.post('/account/phone/confirm', { phoneNumber, code });
}

export async function submitBankAccountDetail({
  bankAccountDetails,
  quoteId,
  referralCode,
}: {
  bankAccountDetails: BankAccountDetails;
  quoteId: string;
  referralCode?: string;
}) {
  return instance.post<{ id: string }>(
    `/quotes/${quoteId}/checkout`,
    {
      ...bankAccountDetails,
      ...(referralCode ? { referralCode } : {}),
    },
    {
      headers: {
        'idempotency-key': uuidv4(),
      },
    }
  );
}

export async function getQuote(questionnaireId: string) {
  return instance.post<{ id: string; tariffInfo: Quote; userInfo?: User }[]>(
    isBrokerVersion
      ? `/questionnaires/${questionnaireId}/quotes`
      : `/quotes/for_questionnaire/${questionnaireId}`
  );
}

export async function getUser(userId: string) {
  return instance.get<User>(`/user/${userId}`);
}

export async function getTransitionToken() {
  return instance.post('/account/transition_tokens');
}
