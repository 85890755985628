import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';
import { useSelector } from 'react-redux';
import type { AppState } from 'reducers';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';

import Center from 'components/center';

import connectedAnswer, {
  ViewProps as GenericViewProps,
} from 'pages/questions/connectedAnswer';
import { ReactComponent as PrevExpSvg } from './img/prevexp.svg';
import { ReactComponent as ProofOfIncomeSvg } from './img/proofincome.svg';
import { ReactComponent as StatementSvg } from './img/statement.svg';

import { getReachedAgeThisYear, getReachedAgeWhenPolicyStart } from 'selectors';

import styles from './style.module.scss';

const View = ({
  onAnswerQuestion,
}: GenericViewProps<'financialHistory', 'selfEmploymentIntro'>) => {
  const { t } = useTranslation();
  const age = useSelector(
    (state: AppState) =>
      getReachedAgeWhenPolicyStart(state) ?? getReachedAgeThisYear(state)
  );

  return (
    <Center>
      <h1 className="p-h1">
        {t(
          'page.selfemploymentintro.title',
          'We’ll need to know more about your work'
        )}
      </h1>
      <p className="p-p mt16 wmx9 w100">
        {t(
          'page.selfemploymentintro.description',
          'Good news! Being self-employed allows you to be eligible for private health insurance with an income of {{price}} per year (compared to €64,350 for employed people). To understand your financial history, we will ask you more questions in the next steps.',
          {
            price:
              age && age > 50
                ? `${englishFormattedEuroCurrency(45, 0)}k`
                : `${englishFormattedEuroCurrency(30, 0)}k`,
          }
        )}
      </p>
      <div className={`mt32 mb8 ${styles.cardscontainer}`}>
        <div className="wmn4">
          <div className={styles.card}>
            <PrevExpSvg />
            <h3>
              {t('page.selfemploymentintro.1.title', 'Previous experience')}
            </h3>
          </div>
        </div>
        <div className="wmn4">
          <div className={styles.card}>
            <StatementSvg />
            <h3>{t('page.selfemploymentintro.2.title', 'Bank statements')}</h3>
          </div>
        </div>
        <div className="wmn4">
          <div className={styles.card}>
            <ProofOfIncomeSvg />
            <h3>
              {t('page.selfemploymentintro.3.title', 'Proof of future income')}
            </h3>
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <span
          data-cy="self-employment-intro-step-continue"
          className="p-btn--primary wmn4"
          onClick={() => onAnswerQuestion(true)}
        >
          {t('page.selfemploymentintro.action', 'Continue')}
        </span>
      </div>
    </Center>
  );
};
export default connectedAnswer('financialHistory', 'selfEmploymentIntro')(View);
