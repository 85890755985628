import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  email,
  faq,
  calendly,
  app,
  website,
} from '@getpopsure/private-constants';
import { useTranslation } from '@getpopsure/i18n-react';
import { Trans } from '@getpopsure/i18n-react';

import { captureException } from '@sentry/browser';

import Center from 'components/center';
import { FriendlyBlockerUrl } from 'models/blockers';
import { isBrokerVersion } from 'utils/isBrokerVersion';
import { notifyBroker } from 'network/api';
import { ViewProps } from '..';

import styles from './style.module.scss';

const BlockerIcon = () => (
  <svg
    width="136"
    height="112"
    viewBox="0 0 136 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.246109 43.9995C-3.4232 90.9163 34.8004 112 64.9369 112C95.0734 112 134.246 86.6587 134.246 59.6692C134.246 32.6796 127.053 15.8729 96.7376 5.46562C66.4226 -4.94168 3.91542 -2.91726 0.246109 43.9995Z"
      fill="#F9F9FF"
    />
    <g clipPath="url(#clip0)">
      <path
        d="M34 40.4167C34 32.5926 40.3426 26.25 48.1667 26.25H87.8333C95.6574 26.25 102 32.5926 102 40.4167V60.25C102 68.074 95.6574 74.4167 87.8333 74.4167H48.1667C40.3426 74.4167 34 68.074 34 60.25V40.4167Z"
        className={styles['fill-primary-100']}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.3886 69.4529C56.5583 67.1889 59.501 65.917 62.5694 65.917H79.0972C82.1656 65.917 85.1084 67.1889 87.278 69.4529C89.4477 71.717 90.6667 74.7876 90.6667 77.9895V82.3011C90.6667 84.206 89.1867 85.7503 87.3611 85.7503C86.0089 85.7503 55.6578 85.7503 54.3056 85.7503C52.4799 85.7503 51 84.206 51 82.3011V77.9895C51 74.7876 52.2189 71.717 54.3886 69.4529Z"
        className={styles['fill-primary-500']}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.5 51.7503C59.5 45.4911 64.5741 40.417 70.8333 40.417C77.0926 40.417 82.1667 45.4911 82.1667 51.7503C82.1667 58.0096 77.0926 63.0837 70.8333 63.0837C64.5741 63.0837 59.5 58.0096 59.5 51.7503Z"
        className={styles['fill-primary-500']}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="68"
          height="68"
          fill="white"
          transform="translate(34 22)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ({ brokerInfo, dateOfBirth }: ViewProps) => {
  const { blockerId }: { blockerId: FriendlyBlockerUrl } = useParams();
  const { t } = useTranslation();
  useEffect(() => {
    async function call() {
      try {
        if (isBrokerVersion === false) {
          return;
        }

        if (!brokerInfo || !dateOfBirth) {
          throw new Error(
            'Trying to notify broker of blocker but broker info wasn’t specified'
          );
        }
        await notifyBroker(brokerInfo, 'BLOCKER_REACHED', dateOfBirth);
      } catch (error) {
        // Silently notify sentry if an error occured
        captureException(error);
      }
    }
    call();
  }, [brokerInfo, dateOfBirth]);

  const content: {
    [K in FriendlyBlockerUrl]: {
      title: string;
      description: JSX.Element;
      actions?: JSX.Element;
    };
  } = {
    generic: {
      title: 'We’re sorry…',
      description: (
        <p className="p-p mt16">
          <Trans i18nKey="page.blocker.generic.description">
            You aren’t eligible for private health insurance. We are working
            hard to make sure everyone can be covered.
            <br />
            <br />
            If you’d like to understand why and have any questions please
            contact{' '}
          </Trans>
          {isBrokerVersion ? (
            t('page.blocker.broker.generic.contact', 'your broker.')
          ) : (
            <>
              {' '}
              <Trans i18nKey="page.blocker.generic.contact">
                a member of our team at{' '}
                <a className="p-a" href={`mailto:${email.help}`}>
                  {{ helpEmail: email.help }}
                </a>{' '}
                or if it is urgent you can book an appointment to speak to us
                below.
              </Trans>
            </>
          )}
        </p>
      ),
    },
    'employed-outside-germany': {
      title: t('page.blocker.employedoutsidegermany.title', 'Unfortunately...'),
      description: (
        <div>
          <p className="p-p mt16">
            {t(
              'page.blocker.employedoutsidegermany.description.1',
              'You aren’t eligibile for private health insurance. We are working hard to make sure everyone can be covered.'
            )}
          </p>
          <p className="p-p mt16">
            {t(
              'page.blocker.employedoutsidegermany.description.2',
              'Still, we might have some other options for you. You can try out our digital recommendation tool to determine which health insurance is the best option for you.'
            )}
          </p>
        </div>
      ),
      actions: (
        <div className="mt24">
          <div>
            <a
              className="p-btn--primary"
              target="_blank"
              rel="noopener noreferrer"
              href={app.recommendationTool}
            >
              {t(
                'page.blocker.employedoutsidegermany.action.recommendationtool',
                'Get a recommendation'
              )}
            </a>
          </div>
          <div className="mt16">
            <a
              className="p-btn--secondary"
              target="_blank"
              rel="noopener noreferrer"
              href={website.support}
            >
              {t(
                'page.blocker.employedoutsidegermany.action.support',
                'Speak to us'
              )}
            </a>
          </div>
        </div>
      ),
    },
    'not-in-germany': {
      title: t('page.blocker.notingermany.title', 'We’re sorry…'),
      description: (
        <p className="p-p mt16">
          <Trans i18nKey="page.blocker.notingermany.description">
            To signup for a private health insurance policy, you are required to
            have established a residence in Germany on or before your coverage
            start date.
            <br />
            <br />
            Establishing a residence means to be officially registered with an
            intention to stay in Germany for more than six months.
            <br />
            <br />
            If you’d like to understand why and have any questions please
            contact
          </Trans>{' '}
          {isBrokerVersion ? (
            t('page.blocker.broker.notingermany.contact', 'your broker.')
          ) : (
            <>
              <Trans i18nKey="page.blocker.notingermany.contact">
                a member of our team at{' '}
                <a className="p-a" href={`mailto:${email.help}`}>
                  {{ helpEmail: email.help }}
                </a>{' '}
                or if it is urgent you can book an appointment to speak to us
                below.
              </Trans>
            </>
          )}
        </p>
      ),
    },
    other: {
      title: t('page.blocker.other.title', 'We’re sorry…'),
      description: (
        <p className="p-p mt16">
          <Trans i18nKey="page.blocker.other.description">
            With your current employment status we aren’t able to provide you
            with Private Health insurance - but there are other options!
            <br />
            <br />
            Book an appointment and speak to one of our experts.
          </Trans>
        </p>
      ),
    },
    'pre-existing-condition': {
      title: t('page.blocker.preexisitingcondition.title', 'We’re sorry…'),
      description: (
        <p className="p-p mt16">
          <Trans i18nKey="page.blocker.preexisitingcondition.description">
            Due to a pre-existing condition we cannot sign you up with Hallesche
            Krankenversicherung a.G. Health insurance. This is due to the
            medical underwriter, but fear not – we may have some other
            solutions.
            <br />
            <br />
            If you need to understand more information about Private insurance
            with us, you can see our FAQ article{' '}
            <a
              className="p-a"
              target="_blank"
              rel="noopener noreferrer"
              href={faq.turnedDownByPrivateHealthInsurance}
            >
              here
            </a>
            .
            <br />
            <br />
            Alternatively, you can speak to one of our experts and they can help
            you find something that will help you.
          </Trans>
        </p>
      ),
    },
  };

  const renderActions = () => {
    if (isBrokerVersion) return null;

    if (content[blockerId].actions) return content[blockerId].actions;

    return (
      <button
        className="p-btn--primary mt24 wmn3"
        onClick={() => {
          window.Calendly.initPopupWidget({ url: calendly.genericHelp });
        }}
      >
        {t('page.blocker.contact.action', 'Speak to us')}
      </button>
    );
  };

  return (
    <Center>
      <div className={`wmx8 ${styles.container}`}>
        <BlockerIcon />
        <h1 className="p-h1 mt16">{content[blockerId].title}</h1>
        {content[blockerId].description}
        {renderActions()}
      </div>
    </Center>
  );
};
