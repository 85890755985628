import { AppState } from 'reducers';
import { addQuestionAfter } from '..';
import { TranslationFn } from '@getpopsure/i18n-react';

// A type where 'None' can be selected
export type Noneable<T extends string> = T | 'NONE';

export type SufferedMedicalCondition = Noneable<
  | 'UNTREATED_SICKNESS'
  | 'SUBSTANCE_ADDICTION'
  | 'MENTAL_DISORDER'
  | 'PHYSICAL_DEFECT'
  | 'ASSISTED_CARE'
>;

export const sufferedMedicalConditionMapping = (
  t: TranslationFn
): {
  [K in SufferedMedicalCondition]: string;
} => ({
  UNTREATED_SICKNESS: t(
    'questions.sufferedmedicalcondition.mapping.untreatedsickness',
    'Untreated sickness or health problem'
  ),
  SUBSTANCE_ADDICTION: t(
    'questions.sufferedmedicalcondition.mapping.substanceaddiction',
    'Substance addiction'
  ),
  MENTAL_DISORDER: t(
    'questions.sufferedmedicalcondition.mapping.mentaldisorders',
    'Mental disorders'
  ),
  PHYSICAL_DEFECT: t(
    'questions.sufferedmedicalcondition.mapping.physicaldefects',
    'Physical defects'
  ),
  ASSISTED_CARE: t(
    'questions.sufferedmedicalcondition.mapping.assistedcare',
    'Assisted care'
  ),
  NONE: t(
    'questions.sufferedmedicalcondition.mapping.noneoftheabove',
    'None of the above'
  ),
});

export type SufferingMedicalCondition = Noneable<
  | 'PHYSICAL_DEFECT'
  | 'CHRONIC_ILLNESS'
  | 'RECOGNIZED_HANDICAP'
  | 'REOCCURING_HEALTH_PROBLEM'
>;
export const sufferingMedicalConditionMapping = (
  t: TranslationFn
): {
  [K in SufferingMedicalCondition]: string;
} => ({
  PHYSICAL_DEFECT: t(
    'questions.sufferingmedicalcondition.mapping.physicaldefects',
    'Physical defects'
  ),
  CHRONIC_ILLNESS: t(
    'questions.sufferingmedicalcondition.mapping.chronicillness',
    'Chronic illness'
  ),
  RECOGNIZED_HANDICAP: t(
    'questions.sufferingmedicalcondition.mapping.recognizedhandicap',
    'Recognized physical handicap'
  ),
  REOCCURING_HEALTH_PROBLEM: t(
    'questions.sufferingmedicalcondition.mapping.reoccuringhealthproblems',
    'Reoccuring health problems'
  ),
  NONE: t(
    'questions.sufferingmedicalcondition.mapping.noneoftheabove',
    'None of the above'
  ),
});

export type UndergoingTreatment = Noneable<
  'DENTAL_TREATMENT' | 'ORTHODONTIC_TREATMENT' | 'PARODONTITIS'
>;

export const undergoingTreatmentMapping = (
  t: TranslationFn
): {
  [K in UndergoingTreatment]: string;
} => ({
  DENTAL_TREATMENT: t(
    'questions.undergoingtreatment.mapping.dental',
    'Dental treatment'
  ),
  ORTHODONTIC_TREATMENT: t(
    'questions.undergoingtreatment.mapping.orthodontic',
    'Orthodontic treatment'
  ),
  PARODONTITIS: t(
    'questions.undergoingtreatment.mapping.parodontitis',
    'Treatment for periodontal disease / parodontitis'
  ),
  NONE: t(
    'questions.undergoingtreatment.mapping.noneoftheabove',
    'None of the above'
  ),
});

export type EyeDiopters = 'LESS_THAN_8' | '8-10' | '10-15' | 'MORE_THAN_15';

export const eyeDioptersMapping = (
  t: TranslationFn
): { [K in EyeDiopters]: string } => ({
  LESS_THAN_8: t('questions.eyediopters.mapping.lessthan8', 'Less than 8'),
  '8-10': t('questions.eyediopters.mapping.between8and10', 'Between 8 and 10'),
  '10-15': t(
    'questions.eyediopters.mapping.between10and15',
    'Between 10 and 15'
  ),
  MORE_THAN_15: t('questions.eyediopters.mapping.morethan15', 'More than 15'),
});

export type MissingBodyPart = 'HIP' | 'BREAST' | 'OTHER';

export const missingBodyPartMapping = (
  t: TranslationFn
): {
  [K in MissingBodyPart]: string;
} => ({
  HIP: t('questions.missingbodypart.mapping.hip', 'Hip'),
  BREAST: t('questions.missingbodypart.mapping.breast', 'Breast'),
  OTHER: t('questions.missingbodypart.mapping.other', 'Other'),
});

export type DentalCondition = Noneable<
  | 'CROWNED_TOOTH'
  | 'FIXED_DENTURE'
  | 'TOOTH_IMPLANT'
  | 'PROSTHESIS'
  | 'BRIDGES'
  | 'OTHER_DENTURES'
>;

export const dentalConditionMapping = (
  t: TranslationFn
): { [K in DentalCondition]: string } => ({
  CROWNED_TOOTH: t(
    'questions.dentalcondition.mapping.crownedtooth',
    'Crowned tooth'
  ),
  FIXED_DENTURE: t(
    'questions.dentalcondition.mapping.fixeddenture',
    'Fixed denture'
  ),
  TOOTH_IMPLANT: t(
    'questions.dentalcondition.mapping.toothimplant',
    'Tooth implant'
  ),
  PROSTHESIS: t('questions.dentalcondition.mapping.prosthesis', 'Prosthesis'),
  BRIDGES: t('questions.dentalcondition.mapping.bridges', 'Bridges'),
  OTHER_DENTURES: t(
    'questions.dentalcondition.mapping.othertype',
    'Other types of dentures'
  ),
  NONE: t(
    'questions.dentalcondition.mapping.noneoftheabove',
    'None of the above'
  ),
});

export type MissingTeethUpperOrLowerJaw = 'UPPER' | 'LOWER' | 'BOTH';

export const missingTeethUpperOrLowerJawMapping = (
  t: TranslationFn
): {
  [K in MissingTeethUpperOrLowerJaw]: string;
} => ({
  UPPER: t('questions.missingteethjaw.mapping.upper', 'UPPER'),
  LOWER: t('questions.missingteethjaw.mapping.lower', 'LOWER'),
  BOTH: t('questions.missingteethjaw.mapping.both', 'Both'),
});

export type NumberOfAffectedTeeth = 'BETWEEN_ONE_AND_TEN' | 'MORE_THAN_TEN';

export const numberOfAffectedTeethMapping = (
  t: TranslationFn
): {
  [K in NumberOfAffectedTeeth]: string;
} => ({
  BETWEEN_ONE_AND_TEN: t(
    'questions.numberofaffectedteeth.mapping.1to10',
    'Between 1 and 10 teeth'
  ),
  MORE_THAN_TEN: t(
    'questions.numberofaffectedteeth.mapping.morethan10',
    'More than 10 teeth'
  ),
});

export type JawCondition = Noneable<
  'PARODONTITIS_PERIODONTITIS' | 'JAW_MALPOSITION' | 'MISALIGNED_TEETH'
>;

export const jawConditionMapping = (
  t: TranslationFn
): { [K in JawCondition]: string } => ({
  PARODONTITIS_PERIODONTITIS: t(
    'questions.jawcondition.mapping.parodontitis',
    'Parodontitis / Periodontitis'
  ),
  JAW_MALPOSITION: t(
    'questions.jawcondition.mapping.jawmalposition',
    'Jaw malposition'
  ),
  MISALIGNED_TEETH: t(
    'questions.jawcondition.mapping.misalignedteeth',
    'Misaligned teeth'
  ),
  NONE: t('questions.jawcondition.mapping.noneoftheabove', 'None of the above'),
});

export type MedicalHistoryQuestion =
  | {
      id: 'insuranceLawDisclaimer';
      answer: boolean;
    }
  | {
      id: 'dataProcessingDisclaimer';
      answer: boolean;
    }
  | {
      id: 'heightInCm';
      answer: number;
    }
  | {
      id: 'weightInKg';
      answer: number;
    }
  | {
      id: 'diagnosedTreatedOrGivenMedicalAdvice';
      answer: boolean;
    }
  | {
      id: 'admittedToHospitalPast10years';
      answer: boolean;
    }
  | {
      id: 'hivPositive';
      answer: boolean;
    }
  | {
      id: 'hasReceivedPsychotherapy';
      answer: boolean;
    }
  | {
      id: 'unableToProduceChildren';
      answer: boolean;
    }
  | {
      id: 'sufferedFromCondition';
      answer: SufferedMedicalCondition[];
    }
  | {
      id: 'planToUndergoMedicalTreatment';
      answer: boolean;
    }
  | {
      id: 'planToUndergoMedicalTreatmentExplanation';
      answer: string;
    }
  | {
      id: 'hasMissingBodyPart';
      answer: boolean;
    }
  | {
      id: 'missingBodyPart';
      answer: MissingBodyPart;
    }
  | {
      id: 'missinBodyPartExplanation';
      answer: string;
    }
  | { id: 'sufferingFromCondition'; answer: SufferingMedicalCondition[] }
  | { id: 'sufferingFromConditionExplanation'; answer: string }
  | { id: 'hasTakenMedication'; answer: boolean }
  | {
      id: 'hasTakenMedicationExplanation';
      answer: string;
    }
  | {
      id: 'assistingVisionDevice';
      answer: boolean;
    }
  | {
      id: 'hasBeenAtHospitalBecauseOfVision';
      answer: boolean;
    }
  | {
      id: 'hadOperationBecauseOfVision';
      answer: boolean;
    }
  | {
      id: 'maximumEyeDiopters';
      answer: EyeDiopters;
    }
  | {
      id: 'undergoingTreatment';
      answer: UndergoingTreatment[];
    }
  | {
      id: 'hasMissingTeeth';
      answer: boolean;
    }
  | {
      id: 'missingTeethUpperOrLowerJaw';
      answer: MissingTeethUpperOrLowerJaw;
    }
  | {
      id: 'upperJawMissingTeeth';
      answer: number[];
    }
  | {
      id: 'lowerJawMissingTeeth';
      answer: number[];
    }
  | {
      id: 'dentalCondition';
      answer: DentalCondition[];
    }
  | {
      id: 'howManyTeethAffectedByCondition';
      answer: NumberOfAffectedTeeth;
    }
  | {
      id: 'jawCondition';
      answer: JawCondition[];
    }
  | {
      id: 'medicalHistoryReview';
      answer: boolean;
    }
  | {
      id: 'answersCheck';
      answer: boolean;
    }
  | {
      id: 'finalSteps';
      answer: boolean;
    };

export const medicalHistoryQuestionOrder = (
  state: AppState
): MedicalHistoryQuestion['id'][] => {
  let order: MedicalHistoryQuestion['id'][] = [
    'dataProcessingDisclaimer',
    'insuranceLawDisclaimer',
    'heightInCm',
    'weightInKg',
    'diagnosedTreatedOrGivenMedicalAdvice',
    'admittedToHospitalPast10years',
    'hivPositive',
    'hasReceivedPsychotherapy',
    'unableToProduceChildren',
    'sufferedFromCondition',
    'planToUndergoMedicalTreatment',
    'hasMissingBodyPart',
    'sufferingFromCondition',
    'hasTakenMedication',
    'assistingVisionDevice',
    'undergoingTreatment',
    'hasMissingTeeth',
    'dentalCondition',
    'jawCondition',
    'medicalHistoryReview',
    'answersCheck',
    'finalSteps',
  ];

  const medicalHistory = state.questionnaire.medicalHistory;

  if (medicalHistory?.hasMissingBodyPart === true) {
    order = addQuestionAfter({
      questionsToAdd: ['missingBodyPart'],
      afterQuestion: 'hasMissingBodyPart',
      order,
    }) as MedicalHistoryQuestion['id'][];
  }

  if (
    medicalHistory?.missingBodyPart &&
    medicalHistory?.missingBodyPart !== 'BREAST'
  ) {
    order = addQuestionAfter({
      questionsToAdd: ['missinBodyPartExplanation'],
      afterQuestion: 'missingBodyPart',
      order,
    }) as MedicalHistoryQuestion['id'][];
  }

  if (medicalHistory?.planToUndergoMedicalTreatment === true) {
    order = addQuestionAfter({
      questionsToAdd: ['planToUndergoMedicalTreatmentExplanation'],
      afterQuestion: 'planToUndergoMedicalTreatment',
      order,
    }) as MedicalHistoryQuestion['id'][];
  }

  if (medicalHistory?.hasTakenMedication === true) {
    order = addQuestionAfter({
      questionsToAdd: ['hasTakenMedicationExplanation'],
      afterQuestion: 'hasTakenMedication',
      order,
    }) as MedicalHistoryQuestion['id'][];
  }

  if (medicalHistory?.sufferingFromCondition?.includes('RECOGNIZED_HANDICAP')) {
    order = addQuestionAfter({
      questionsToAdd: ['sufferingFromConditionExplanation'],
      afterQuestion: 'sufferingFromCondition',
      order,
    }) as MedicalHistoryQuestion['id'][];
  }

  if (medicalHistory?.assistingVisionDevice === true) {
    order = addQuestionAfter({
      questionsToAdd: ['hasBeenAtHospitalBecauseOfVision'],
      afterQuestion: 'assistingVisionDevice',
      order,
    }) as MedicalHistoryQuestion['id'][];
  }

  if (medicalHistory?.hasBeenAtHospitalBecauseOfVision === false) {
    order = addQuestionAfter({
      questionsToAdd: ['hadOperationBecauseOfVision'],
      afterQuestion: 'hasBeenAtHospitalBecauseOfVision',
      order,
    }) as MedicalHistoryQuestion['id'][];
  }

  if (medicalHistory?.hadOperationBecauseOfVision === false) {
    order = addQuestionAfter({
      questionsToAdd: ['maximumEyeDiopters'],
      afterQuestion: 'hadOperationBecauseOfVision',
      order,
    }) as MedicalHistoryQuestion['id'][];
  }

  if (medicalHistory?.hasMissingTeeth === true) {
    order = addQuestionAfter({
      questionsToAdd: ['missingTeethUpperOrLowerJaw'],
      afterQuestion: 'hasMissingTeeth',
      order,
    }) as MedicalHistoryQuestion['id'][];
  }

  if (medicalHistory?.missingTeethUpperOrLowerJaw === 'UPPER') {
    order = addQuestionAfter({
      questionsToAdd: ['upperJawMissingTeeth'],
      afterQuestion: 'missingTeethUpperOrLowerJaw',
      order,
    }) as MedicalHistoryQuestion['id'][];
  }

  if (medicalHistory?.missingTeethUpperOrLowerJaw === 'LOWER') {
    order = addQuestionAfter({
      questionsToAdd: ['lowerJawMissingTeeth'],
      afterQuestion: 'missingTeethUpperOrLowerJaw',
      order,
    }) as MedicalHistoryQuestion['id'][];
  }

  if (medicalHistory?.missingTeethUpperOrLowerJaw === 'BOTH') {
    order = addQuestionAfter({
      questionsToAdd: ['upperJawMissingTeeth', 'lowerJawMissingTeeth'],
      afterQuestion: 'missingTeethUpperOrLowerJaw',
      order,
    }) as MedicalHistoryQuestion['id'][];
  }

  if (medicalHistory?.dentalCondition?.includes('NONE') === false) {
    order = addQuestionAfter({
      questionsToAdd: ['howManyTeethAffectedByCondition'],
      afterQuestion: 'dentalCondition',
      order,
    }) as MedicalHistoryQuestion['id'][];
  }

  return order;
};
