import {
  isDeductible,
  isEmploymentStatus,
  isTariff,
  isValidISODate,
  isValidISODateWithoutDay,
  rawQueryParametersAddOnstoAddOn,
} from 'pages/welcome/component/util';
import {
  setTariffAndDeductible,
  setAddOns,
  setSickDayPayout,
  setBrokerIdAndEmail,
} from 'actions';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBrokerInfo } from 'selectors/metadata';
import { getTariffAndDeductible } from 'selectors/tariff';
import { AppState } from 'reducers';
import moment from 'moment';
import { isBrokerVersion } from 'utils/isBrokerVersion';
import { BrokerInfo } from 'models/brokerInfo';
import deepEqual from 'deep-equal';
import { notifyBroker } from 'network/api';
import { captureException } from '@sentry/browser';

import {
  setCoverageStartDate,
  setDateOfBirth,
  setEmploymentStatus,
  setIncome,
} from './actions';

import MissingInfoError from '../missingInfoError';
import { useLocation } from 'react-router-dom';

/**
 * WithTariff wrapper
 *
 * This wrapper component makes sure that the tariff & deductible
 * variables are correctly set on every question of the flow,
 * either in the store or in the URL params and returns an error
 * if these values are missing.
 *
 * @param children React.Element
 *
 * @return React.Element Children | MissingInfoError component
 *
 */
const WithTariff = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const deductible = params.get('deductible');
  const tariff = params.get('tariff');
  const sickDayPayout = params.get('sickDayPayout');
  const dateOfBirth = params.get('dateOfBirth');
  const coverageStartDate = params.get('coverageStartDate');
  const employmentStatus = params.get('employmentStatus');
  const income = params.get('income');
  const addons = params.get('addOns');

  const storedTariff = useSelector((state: AppState) =>
    getTariffAndDeductible(state)
  );
  const storedDateOfBirth = useSelector(
    (state: AppState) => state.questionnaire.personalInfo?.dateOfBirth
  );
  const storedBrokerInfo = useSelector((state: AppState) =>
    getBrokerInfo(state)
  );

  const dispatch = useDispatch();
  const isTariffInTheStore = storedTariff?.deductible && storedTariff.tariff;
  const isTariffInQueryParams = isDeductible(deductible) && isTariff(tariff);

  useEffect(() => {
    const addOns = rawQueryParametersAddOnstoAddOn(addons);

    if (isDeductible(deductible) && isTariff(tariff)) {
      dispatch(setTariffAndDeductible(tariff, deductible));
    }

    if (addons !== null && addOns) {
      dispatch(setAddOns(addOns));
    }

    if (sickDayPayout && !isNaN(Number(sickDayPayout))) {
      dispatch(setSickDayPayout(Number(sickDayPayout)));
    }

    if (income && !isNaN(Number(income))) {
      dispatch(setIncome(Number(income)));
    }

    if (isValidISODate(dateOfBirth)) {
      dispatch(setDateOfBirth(dateOfBirth));
    }

    if (isValidISODateWithoutDay(coverageStartDate)) {
      dispatch(
        setCoverageStartDate(
          moment(coverageStartDate).startOf('month').format('YYYY-MM-DD')
        )
      );
    }

    if (isEmploymentStatus(employmentStatus)) {
      dispatch(setEmploymentStatus(employmentStatus));
    }

    if (isBrokerVersion) {
      const brokerId = params.get('brokerID');
      const brokerEmail = params.get('brokerEmail');
      const brokerSubId = params.get('brokerSubID');
      const customerDateOfBirth =
        dateOfBirth !== null ? dateOfBirth : storedDateOfBirth;

      const brokerInfo: BrokerInfo | undefined =
        brokerId !== null && brokerEmail !== null
          ? {
              id: brokerId,
              email: brokerEmail,
              subId: brokerSubId !== null ? brokerSubId : undefined,
            }
          : storedBrokerInfo;

      if (!brokerInfo) {
        throw new Error('Missing or invalid broker informations');
      }

      if (deepEqual(brokerInfo, storedBrokerInfo) === false) {
        dispatch(setBrokerIdAndEmail(brokerInfo));
      }

      const call = async () => {
        if (!brokerInfo || !customerDateOfBirth) {
          return;
        }
        try {
          await notifyBroker(
            brokerInfo,
            'APPLICATION_STARTED',
            customerDateOfBirth
          );
        } catch (error) {
          // Silently notify sentry if an error occured
          captureException(error);
        }
      };

      call();
    }
    // eslint-disable-next-line
  }, []);

  if (isTariffInQueryParams || isTariffInTheStore) {
    return children;
  }

  return <MissingInfoError />;
};

export default WithTariff;
