import { isBrokerVersion } from 'utils/isBrokerVersion';
import { hasAcceptedCookies } from 'utils/tracking';
import { initialize } from '@getpopsure/analytics';

export function initializeTracker() {
  if (hasAcceptedCookies()) {
    initialize({
      snowplow:
        process.env.REACT_APP_COLLECTOR_APP_ID &&
        process.env.REACT_APP_COLLECTOR_URL
          ? {
              appId: process.env.REACT_APP_COLLECTOR_APP_ID,
              collector: process.env.REACT_APP_COLLECTOR_URL,
            }
          : undefined,
      googleAnalytics: process.env.REACT_APP_GA_TRACKING
        ? {
            trackingCode: isBrokerVersion
              ? 'G-TL5SCF1N67'
              : process.env.REACT_APP_GA_TRACKING,
          }
        : undefined,
      googleTagManager: process.env.REACT_APP_GOOGLE_TAG_MANAGER
        ? {
            containerId: process.env.REACT_APP_GOOGLE_TAG_MANAGER,
          }
        : undefined,
    });
  }
}

export default initializeTracker();
