import { isBrokerVersion as _isBrokerVersion } from 'utils/isBrokerVersion';

const COOKIES_ACCEPTED_KEY = 'COOKIES_ACCEPTED_KEY';

export function hasAcceptedCookies(
  isBrokerVersion = _isBrokerVersion,
  cookiesAcceptedValue = localStorage.getItem(COOKIES_ACCEPTED_KEY)
): boolean | undefined {
  if (isBrokerVersion === false) {
    return true;
  }

  switch (cookiesAcceptedValue) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return undefined;
  }
}

export function acceptCookies(accepted: boolean) {
  localStorage.setItem(COOKIES_ACCEPTED_KEY, accepted ? 'true' : 'false');
}
