import { take, put, call, select } from 'redux-saga/effects';

import { ActionType } from 'actions/type';
import { resetStore } from 'actions';
import { createState } from 'utils/state';
import { path } from 'routes/path';
import { AppState } from 'reducers';
import { isBrokerVersion as _isBrokerVersion } from 'utils/isBrokerVersion';
import { TariffReducerState } from 'reducers/tariff';
import { push } from '..';

export function* beginNewApplication(isBrokerVersion = _isBrokerVersion) {
  while (true) {
    const actionType: ActionType = 'RESTART_QUESTIONNAIRE';

    yield take(actionType);

    const tariff: TariffReducerState = yield select(
      (state: AppState) => state.tariff
    );

    const getBrokerVersionStoredProperties = (state: AppState) => ({
      dateOfBirth: state.questionnaire.personalInfo?.dateOfBirth,
      income: state.questionnaire.financialHistory?.income,
      employmentStatus: state.questionnaire.financialHistory?.employmentStatus,
      brokerInfo: state.metadata.brokerInfo,
    });

    if (isBrokerVersion === true) {
      const {
        dateOfBirth,
        income,
        employmentStatus,
        brokerInfo,
      }: ReturnType<typeof getBrokerVersionStoredProperties> = yield select(
        getBrokerVersionStoredProperties
      );
      yield put(
        resetStore(
          createState({
            tariff,
            questionnaire: {
              personalInfo: { dateOfBirth },
              financialHistory: { income, employmentStatus },
            },
            metadata: { brokerInfo },
          })
        )
      );
    } else {
      yield put(resetStore(createState({ tariff })));
    }

    yield call(push, path.welcome);
  }
}
