import React from 'react';
import { AutocompleteAddress } from '@popsure/dirty-swan';
import { Address } from '@popsure/public-models';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { addressValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">{generalQuestionsTitleMapping.address?.(t)}</h1>
      <WrappedForm
        validator={addressValidator}
        sectionId="personalInfo"
        questionId="address"
      >
        {({ value, setValue }) => (
          <div className="mt8">
            <AutocompleteAddress
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
              address={value}
              onAddressChange={(a) => setValue(a as Address)}
            />
          </div>
        )}
      </WrappedForm>
    </Center>
  );
};
