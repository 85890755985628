import React from 'react';
import { useSelector } from 'react-redux';
import SepaMandate from 'pages/sepaMandate';
import Loading from 'components/loading';

import { getIsExistingCustomer } from './selectors';
import { usePrivatePaymentsFlag } from './usePrivatePaymentsFlag';
import { SubmitApplication } from './modules/SubmitApplication';

export const PrivatePaymentsContainer = () => {
  const isExistingCustomer = useSelector(getIsExistingCustomer);
  const { isLoading, isFlagEnabled } = usePrivatePaymentsFlag();

  if (isLoading) {
    return <Loading />;
  }

  if (!isExistingCustomer && isFlagEnabled) {
    return <SubmitApplication />;
  }

  return <SepaMandate />;
};
