import React, { useState } from 'react';
import { Router } from 'react-router-dom';

import ErrorBoundary from 'components/errorBoundary';
import Helmet from 'components/helmet';
import WithTariff from 'components/withTariff';
import browserHistory from 'utils/browserHistory';

import App from './App';

export const DisplayLiveQuoteContext = React.createContext<{
  displayLiveQuote: boolean;
  toggleDisplayLiveQuote?: (value: boolean) => void;
}>({
  displayLiveQuote: false,
});

const Root = () => {
  const [displayLiveQuote, toggleDisplayLiveQuote] = useState(false);
  return (
    <>
      <Helmet />
      <ErrorBoundary>
        <Router history={browserHistory}>
          <WithTariff>
            <DisplayLiveQuoteContext.Provider
              value={{
                displayLiveQuote,
                toggleDisplayLiveQuote,
              }}
            >
              <App />
            </DisplayLiveQuoteContext.Provider>
          </WithTariff>
        </Router>
      </ErrorBoundary>
    </>
  );
};

export default Root;
