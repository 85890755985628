import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import styles from './style.module.scss';

export function generateRadioInput<Q extends string>(
  name: string,
  selectedValue: Q | undefined,
  mapping: { [k in Q]: string },
  onChange: (value: Q) => void
) {
  const values = Object.keys(mapping).map((k) => ({
    value: k,
    text: mapping[k as Q],
  }));

  return (
    <div className={`wmx5 ${styles.container}`}>
      {values.map(({ text, value }) => (
        <div
          data-cy={`questionnaire-${value.toLowerCase()}`}
          className="p-label-container"
          key={value}
        >
          <input
            className="p-radio"
            type="radio"
            id={value}
            name={name}
            checked={value === selectedValue}
            onChange={() => {
              onChange(value as Q);
            }}
          />
          <label className="p-label p-label--bordered" htmlFor={value}>
            {text}
          </label>
        </div>
      ))}
    </div>
  );
}

export function generateCheckboxInput<Q extends string>(
  name: string,
  selectedValues: Q[] = [],
  mapping: { [k in Q]: string },
  onChange: (value: Q[]) => void
) {
  const values = Object.keys(mapping).map((k) => ({
    value: k,
    text: mapping[k as Q],
  }));

  return (
    <div className={`wmx5 ${styles.container}`}>
      {values.map(({ text, value }) => (
        <div
          data-cy={`questionnaire-${value.toLowerCase()}`}
          className="p-label-container"
          key={value}
        >
          <input
            className="p-checkbox"
            type="checkbox"
            id={value}
            name={name}
            checked={selectedValues.includes(value as any)}
            onChange={() => {
              if (selectedValues.includes(value as any)) {
                onChange(selectedValues.filter((v) => v !== value));
              } else {
                onChange([...selectedValues, value] as Q[]);
              }
            }}
          />
          <label className="p-label p-label--bordered" htmlFor={value}>
            {text}
          </label>
        </div>
      ))}
    </div>
  );
}

export const YesNoQuestion = ({
  name,
  value,
  setValue,
}: {
  name: string;
  value: boolean | undefined;
  setValue: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="wmx5">
      <div className="p-label-container" data-cy="questionnaire-yes">
        <input
          className="p-radio"
          type="radio"
          id="true"
          name={name}
          checked={value === true}
          onClick={() => setValue(true)}
          onChange={() => setValue(true)}
        />
        <label className="p-label p-label--bordered" htmlFor="true">
          {t('questions.general.boolean.yes', 'Yes')}
        </label>
      </div>
      <div
        className={`p-label-container ${styles['no-input']}`}
        data-cy="questionnaire-no"
      >
        <input
          className="p-radio"
          type="radio"
          id="false"
          name={name}
          checked={value === false}
          onClick={() => setValue(false)}
          onChange={() => setValue(false)}
        />
        <label className="p-label p-label--bordered" htmlFor="false">
          {t('questions.general.boolean.no', 'No')}
        </label>
      </div>
    </div>
  );
};
