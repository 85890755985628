import React from 'react';
import { MultiDropzone } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';

import Center from 'components/center';
import connectedAnswer, {
  ViewProps as GenericViewProps,
} from 'pages/questions/connectedAnswer';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { useUploadFiles } from 'utils/hooks';

const View = ({
  onAnswerQuestion,
  answer,
}: GenericViewProps<'financialHistory', 'bankStatement'>) => {
  const { t } = useTranslation();
  const {
    isSubmitValid,
    uploadFile,
    uploadedFiles,
    removeFile,
    loading,
  } = useUploadFiles({
    initialValue: answer ?? [],
    questionId: 'bankStatement',
  });

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.bankStatement?.(t)}
      </h1>
      <p className="p-p mt8">
        {t(
          'questions.bankstatement.description',
          'Please provide bank statements showing your income in the last 3 months.'
        )}
      </p>
      <div className="mt16">
        <MultiDropzone
          onFileSelect={uploadFile}
          uploading={loading}
          uploadedFiles={uploadedFiles}
          onRemoveFile={removeFile}
          maxFiles={1}
        />
      </div>
      <button
        data-cy="questionnaire-continue"
        className="p-btn--primary wmn3 mt24"
        type="submit"
        disabled={!isSubmitValid}
        onClick={() => {
          if (!isSubmitValid) {
            return;
          }

          onAnswerQuestion(uploadedFiles);
        }}
      >
        {t('questions.bankstatement.continue.action', 'Continue')}
      </button>
    </Center>
  );
};

export default connectedAnswer('financialHistory', 'bankStatement')(View);
