import { captureException } from '@sentry/browser';
import React, { useEffect } from 'react';
import Center from 'components/center';

const MissingInfoError = () => {
  useEffect(() => {
    captureException(
      new Error('[MISSING_INFO]: Missing or invalid deductible or tariff')
    );
  }, []);

  return (
    <Center>
      <div data-testid="missing-info-error">
        <h1 className="p-h2 d-block ta-center mt24 tc-grey-900">
          Some information is missing
        </h1>
        <p className="p-p ta-center tc-grey-600 mt8 w100 wmx6 ml-auto mr-auto">
          Sorry, some of the information required to sign you up seems to be
          missing. Starting over from the quote page should fix this issue.
        </p>
      </div>
    </Center>
  );
};

export default MissingInfoError;
