import { instance } from 'network/api';

import type {
  SubmitApplicationRequest,
  SubmitApplicationResponse,
} from '../models';

export const submitApplication = (payload: SubmitApplicationRequest) => {
  return instance.post<SubmitApplicationRequest, SubmitApplicationResponse>(
    '/private-health/application',
    payload
  );
};
