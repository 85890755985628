import React from 'react';
import {
  allCountries,
  associatedCountryFlagForCountryCode,
} from '@popsure/public-models';
import { AutoSuggestMultiSelect } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyArrayValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <WrappedForm
        validator={nonEmptyArrayValidator}
        sectionId="personalInfo"
        questionId="nationality"
      >
        {({ value, setValue }) => {
          return (
            <>
              <h1 className="p-h1">
                {generalQuestionsTitleMapping.nationality?.(t)}
              </h1>
              <div style={{ position: 'relative' }} className="mt16">
                <AutoSuggestMultiSelect
                  options={allCountries.map((country) => ({
                    value: country.name,
                    leftIcon: associatedCountryFlagForCountryCode(country.code),
                  }))}
                  setValues={(values) => {
                    const selectedCountriesWithCode = allCountries.filter(
                      (country) =>
                        values
                          .map((value) => value.value)
                          .includes(country.name)
                    );
                    setValue(selectedCountriesWithCode);
                  }}
                  selectedValues={value?.map((country) => ({
                    value: country.name,
                    leftIcon: associatedCountryFlagForCountryCode(country.code),
                  }))}
                  placeholder={t(
                    'questions.nationality.input.placeholder',
                    'Country'
                  )}
                  chipsListClassName="wmx9"
                  multiSelectClassName="wmx5"
                />
              </div>
            </>
          );
        }}
      </WrappedForm>
    </Center>
  );
};
