import { getTrackingObject } from '@getpopsure/tracker';
import { getFixedT } from '@getpopsure/i18n-react';

import { isArrayOfUploadedFiles } from 'models';
import {
  GeneralQuestionnaireModel,
  GeneralQuestionId,
} from 'models/questions/general';
import { RecursivePartial } from 'utils/types';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { questionnaireLegalText } from 'models/questions/text/legal';
import { specifyQuestionsTitleMapping } from 'models/questions/specify/mapping';
import { StoredSpecifyQuestionnaireModel } from 'reducers/specify';
import { TariffPayload } from 'models/price';
import { State as MetadataState } from 'reducers/metadata';
import { Question } from 'models/questions';
import { BrokerInfo } from 'models/brokerInfo';
import { InvestigationQuestionnaireModel } from 'reducers/investigation';

export function extractQuestionnaireUploadTokens(
  questionnaire: GeneralQuestionnaireModel | StoredSpecifyQuestionnaireModel
): string[] {
  const arr: string[] = [];
  function eachRecursive(obj: any) {
    for (const v of Object.values(obj)) {
      if (v && isArrayOfUploadedFiles(v)) {
        arr.push(...(v.map(({ token }) => token) as string[]));
      } else if (typeof v === 'object' && v !== null && v !== undefined) {
        eachRecursive(v);
      }
    }
  }

  eachRecursive(questionnaire);
  return arr;
}

export function metadataFromSpecifyQuestionnaireModel(
  questionnaire: RecursivePartial<StoredSpecifyQuestionnaireModel> = {}
) {
  const duplicate = { ...questionnaire };
  const t = getFixedT();

  const result: { [x in any]: any } = {};
  // TODO: Make this mess a bit better… someday
  Object.entries(duplicate).forEach(([questionId, tree]) => {
    result[questionId] = {};
    if (tree) {
      Object.entries(tree).forEach(([treeId, condition]) => {
        if (treeId === 'generic') {
          return;
        }

        result[questionId][treeId] = {};
        if (condition) {
          result[questionId][treeId] = {};
          Object.values(condition).forEach((answer) => {
            Object.keys(answer).forEach((specifyQuestionId) => {
              result[questionId][treeId][
                specifyQuestionId
              ] = (specifyQuestionsTitleMapping as any)[treeId][
                specifyQuestionId
              ].title;
            });
          });
        }
      });
    }
  });

  result.generic = {
    description: t(
      'api.metadata.genericquestion.description',
      'Would you explain this to us in a little more detail?'
    ),
  };

  return result;
}

export function metadataFromQuestionnaireModel(
  questionnaire: GeneralQuestionnaireModel
) {
  const t = getFixedT();
  return Object.entries(questionnaire).reduce((a, [k, v]) => {
    if (v === undefined) {
      return a;
    }

    return {
      ...a,
      [k]: Object.keys(v).reduce((b, m) => {
        const questionTitle = generalQuestionsTitleMapping[
          m as GeneralQuestionId
        ]?.(t);

        if (questionTitle) {
          return {
            ...b,
            [m]: questionTitle,
          };
        }

        return b;
      }, {}),
    };
  }, {});
}

export function transformQuestionnaireModel(
  questionnaire: GeneralQuestionnaireModel,
  specify: StoredSpecifyQuestionnaireModel = {},
  investigation: InvestigationQuestionnaireModel,
  tariffInfo: TariffPayload,
  answersMetadata: MetadataState,
  questionOrder: Question[],
  brokerInfo?: BrokerInfo
) {
  const uploadTokens = [
    ...extractQuestionnaireUploadTokens(questionnaire),
    ...extractQuestionnaireUploadTokens(specify),
  ];

  const legalTexts = conditionallyAddRequiredLegalTexts(questionnaire);

  return {
    answers: {
      ...questionnaire,
      specify,
      tariffInfo,
      ...{ investigation: { medicalHistory: investigation } },
    },
    questionnaireType: 'PRIVATE_HEALTH',
    uploadTokens,
    metadata: {
      labels: {
        answers: answersMetadata,
        title: {
          specify: metadataFromSpecifyQuestionnaireModel(specify),
          ...metadataFromQuestionnaireModel(questionnaire),
        },
      },
      legal: legalTexts,
      questionOrder,
      broker: brokerInfo,
      source: getTrackingObject(),
    },
  };
}

export function conditionallyAddRequiredLegalTexts(
  questionnaire: GeneralQuestionnaireModel
) {
  const t = getFixedT();
  const selectedCurrentHealthInsurance =
    questionnaire.insuranceHistory?.currentHealthInsurance;
  if (selectedCurrentHealthInsurance === 'GERMAN_PUBLIC_INSURANCE') {
    questionnaireLegalText.insuranceHistory.currentHealthInsurance = t(
      'api.submittedtext.currentinsurance.germanpublic',
      'If you are currently insured publicly in Germany then you need to cancel your policy with your provider before the start of your private insurance. You cannot be insured by both Public and Private Health Insurance at the same time. Be aware that there is a cancellation period of about 3-months on Public Health Insurance in Germany.'
    );
  }
  if (selectedCurrentHealthInsurance === 'GERMAN_CIVIL_SERVANT_INSURANCE') {
    questionnaireLegalText.insuranceHistory.currentHealthInsurance = t(
      'api.submittedtext.currentinsurance.civilservant',
      'Civil servants commonly use a special type of insurance called "Restkostenversicherung" or "Beihilfeversicherung". Some civil servants such as soldiers can also receive free medical care ("freie Heilfürsorge"). Civil servants are all government employees including police officers, firefighters, judges and soldiers.'
    );
  }
  return questionnaireLegalText;
}
