import { AppState } from 'reducers';

import jwtDecode from 'jwt-decode';

export const getIsAuthenticated = (state: AppState): boolean =>
  state.session.jwt !== undefined;

export const getJWT = (state: AppState): string | undefined =>
  state.session.jwt;

export const getUserId = (state: AppState): string | undefined => {
  const jwt = getJWT(state);

  if (!jwt) {
    return undefined;
  }

  const { sub } = jwtDecode(jwt);

  if (!sub) {
    throw new Error('Couldn’t find sub on jwt');
  }

  return sub;
};
