import { RiskLevel } from 'models/price';

export const preExistingConditionTitle = ({
  level,
  reason,
}: {
  level: RiskLevel;
  reason?: string;
}): string => {
  if (level === 'X' || !reason) {
    return 'Risk surcharge';
  }

  return reason;
};
