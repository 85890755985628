import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@getpopsure/i18n-react';

import { isBrokerVersion } from 'utils/isBrokerVersion';

export default () => {
  const { t } = useTranslation();
  const content = {
    title: isBrokerVersion
      ? t('hallesche.meta.title', 'HALLESCHE - Private health insurance')
      : 'Feather',
    description: isBrokerVersion
      ? ''
      : t(
          'global.meta.title',
          'Honest, simple insurance for expats in Germany. English-language support for health insurance, personal liability insurance, household contents insurance, and more.'
        ),
    favicon: isBrokerVersion ? '/hallesche/favicon.ico' : '/favicon.png',
    manifest: isBrokerVersion ? 'hallesche/manifest.json' : '/manifest.json',
    appleTouchIcon: isBrokerVersion ? 'hallesche/logo192.png' : '/logo192.png',
  };

  return (
    <Helmet>
      <meta name="description" content={content.description} />
      <link rel="icon" href={content.favicon} />
      <link rel="apple-touch-icon" href={content.appleTouchIcon} />
      <link rel="manifest" href={content.manifest} />
      <title>{content.title}</title>
    </Helmet>
  );
};
