import { call, put, select, take } from 'redux-saga/effects';
import { trackConversion } from '@getpopsure/analytics';
import { app } from '@getpopsure/private-constants';
import { getTransitionToken } from 'network/api';

import {
  setAsyncOperationErrored,
  setAsyncOperationFinished,
  setAsyncOperationInProgress,
} from 'actions/asyncOperation';

import {
  updateReferralInfo,
  getValidReferralCode,
} from 'features/referralEngine';

import { SUBMIT_APPLICATION_ACTION } from '../actions';
import { getQuestionnaireId, getQuoteId } from '../selectors';
import { PRIVATE_PAYMENTS_SUBMIT_OPERATION_ID } from '../constants';
import * as privatePaymentsApi from '../api';

export function* submitApplicationSaga(
  api = { ...privatePaymentsApi, getTransitionToken }
) {
  while (true) {
    yield take(SUBMIT_APPLICATION_ACTION);

    const questionnaireId = yield select(getQuestionnaireId);
    const quoteId = yield select(getQuoteId);
    const referralCode = yield select(getValidReferralCode);

    try {
      yield put(
        setAsyncOperationInProgress(PRIVATE_PAYMENTS_SUBMIT_OPERATION_ID)
      );

      /**
       * Submit application
       */
      yield call(api.submitApplication, {
        questionnaireId,
        quoteId,
        ...(referralCode && { referralCode }),
      });

      trackConversion({ vertical: 'private-health' });

      /**
       * Clear referral code after successful checkout
       */
      if (referralCode) {
        yield put(updateReferralInfo({ referralCode: undefined }));
      }

      /**
       * Redirect to success page
       */
      const {
        data: { auth_token: transition_token },
      } = yield call(api.getTransitionToken);

      window.location.href = `${app.myPolicies}?token=${transition_token}&signupSuccess=privateHealth`;

      yield put(
        setAsyncOperationFinished(PRIVATE_PAYMENTS_SUBMIT_OPERATION_ID)
      );
    } catch (error) {
      yield put(
        setAsyncOperationErrored(PRIVATE_PAYMENTS_SUBMIT_OPERATION_ID, error)
      );
    }
  }
}
