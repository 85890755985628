import {
  Tariff,
  DeductibleForTariff,
  AddOn,
} from '@getpopsure/private-health-insurance-pricing-engine';

import { Action, LabelType } from './type';
import { Section, GeneralQuestionnaireModel } from 'models/questions/general';
import { Quote, User, BankAccountDetails } from 'models';
import { AppState } from 'reducers';
import { State as RemoteDataState } from 'reducers/remoteData';
import { Condition } from 'models/medicalConditions';
import { Question } from 'models/questions';
import { BrokerInfo } from 'models/brokerInfo';

export function answeredQuestion(
  question: Question,
  answer: Section['question']['answer'],
  label?: LabelType
): Action {
  return {
    type: 'ANSWERED_QUESTION',
    question,
    answer,
    label,
  };
}

export function storeAnsweredQuestion(
  question: Question,
  answer: Section['question']['answer'],
  label?: LabelType
): Action {
  return {
    type: 'STORE_ANSWERED_QUESTION',
    question,
    answer,
    label,
  };
}

export function answeredInvestigationForQuestion(
  question: Question,
  conditions: Condition[]
): Action {
  return {
    type: 'ANSWERED_INVESTIGATION',
    question,
    conditions,
  };
}

export function answeredSpecifyQuestion(
  question: Question,
  answer: any,
  label?: LabelType
): Action {
  return {
    type: 'ANSWERED_SPECIFY_QUESTION',
    question,
    answer,
    label,
  };
}

export function restartQuestionnaire(): Action {
  return {
    type: 'RESTART_QUESTIONNAIRE',
  };
}

export function continueQuestionnaire(): Action {
  return {
    type: 'CONTINUE_QUESTIONNAIRE',
  };
}

export function resetStore(newStore?: AppState): Action {
  return {
    type: 'RESET_STORE',
    newStore,
  };
}

export function submitQuestionnaire(): Action {
  return {
    type: 'SUBMIT_QUESTIONNAIRE',
  };
}

export function submitIban(bankAccountDetails: BankAccountDetails): Action {
  return {
    type: 'SUBMIT_BANK_ACCOUNT_DETAIL',
    bankAccountDetails,
  };
}

export function fetchQuote(questionnaireId: string): Action {
  return {
    type: 'FETCH_QUOTE',
    questionnaireId,
  };
}

export function fetchUser(): Action {
  return {
    type: 'FETCH_USER',
  };
}

export function mergeQuote(quote: Quote, id: string, userInfo?: User): Action {
  return {
    type: 'MERGE_QUOTE',
    quote,
    id,
    userInfo,
  };
}

export function mergeUser(user: User): Action {
  return {
    type: 'MERGE_USER',
    user,
  };
}

export function mergeQuestionnaire(questionnaire: {
  id: string;
  answers: GeneralQuestionnaireModel;
}): Action {
  return {
    type: 'MERGE_QUESTIONNAIRE',
    questionnaire,
  };
}

export function clearRemoteData(key: keyof RemoteDataState): Action {
  return {
    type: 'CLEAR_REMOTE_DATA',
    key,
  };
}

export function clearAllRemoteData(): Action {
  return {
    type: 'CLEAR_ALL_REMOTE_DATA',
  };
}

export function startPhoneNumberVerification(phoneNumber: string): Action {
  return {
    type: 'START_PHONE_VERIFICATION',
    phoneNumber,
  };
}

export function confirmPhoneNumber(phoneNumber: string, code: string): Action {
  return {
    type: 'CONFIRM_PHONE_NUMBER',
    phoneNumber,
    code,
  };
}

export function setTariffAndDeductible(
  tariff: Tariff,
  deductible: DeductibleForTariff<Tariff>
): Action {
  return {
    type: 'SET_TARIFF_AND_DEDUCTIBLE',
    tariff,
    deductible,
  };
}

export const setAddOns = (addOns: AddOn[]): Action => ({
  type: 'SET_ADD_ONS',
  addOns,
});

export const setSickDayPayout = (sickDayPayout: number): Action => ({
  type: 'SET_SICK_DAY_PAYOUT',
  sickDayPayout,
});

export const onLoginCodeEntered = (
  code: string,
  email: string,
  signedInCallback?: () => void
): Action => ({
  type: 'SIGN_IN_WITH_LOGIN_CODE',
  code,
  email,
  signedInCallback,
});

export const setBrokerIdAndEmail = (info: BrokerInfo): Action => ({
  type: 'STORE_BROKER_INFO',
  info,
});
