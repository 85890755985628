import React from 'react';

import { AppState } from 'reducers';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';

import View from './view';
import { answeredSpecifyQuestion } from 'actions';
import {
  SpecifyQuestionnaireModel,
  QuestionType,
  Tree,
} from 'models/questions/specify';
import { StringOrNever } from 'utils/types';
import {
  conditionNameFromConditionId,
  treeIdFromConditionId,
} from 'models/medicalConditions';
import { MedicalHistoryQuestion } from 'models/questions/general/medicalHistory';
import { specifyQuestionsTitleMapping } from 'models/questions/specify/mapping';
import {
  sectionIdFromKebabCaseSectionId,
  questionIdFromKebabCaseQuestionId,
  specifyQuestionIdFromKebabCaseSpecifyQuestionId,
} from 'routes';
import { LabelType } from 'actions/type';
import { connectedRedirect } from 'components/connectedRedirect';

type StateProps<
  S extends keyof SpecifyQuestionnaireModel,
  Q extends keyof SpecifyQuestionnaireModel[S]
> = {
  answer: SpecifyQuestionnaireModel[S][Q];
  conditionName: string;
};

type DispatchProps<
  S extends keyof SpecifyQuestionnaireModel,
  Q extends keyof SpecifyQuestionnaireModel[S]
> = {
  onAnswerQuestion: (answer: SpecifyQuestionnaireModel[S][Q]) => void;
};

type OwnProps<
  S extends keyof SpecifyQuestionnaireModel,
  Q extends keyof SpecifyQuestionnaireModel[S]
> = {
  conditionId: string;
  questionId: MedicalHistoryQuestion['id'];
  treeId: S;
  specifyQuestionId: Q;
  title: string;
  questionType: QuestionType<StringOrNever<SpecifyQuestionnaireModel[S][Q]>>;
  label: LabelType;
};

export type ViewProps<
  S extends keyof SpecifyQuestionnaireModel,
  Q extends keyof SpecifyQuestionnaireModel[S]
> = StateProps<S, Q> & DispatchProps<S, Q> & OwnProps<S, Q>;

const mapStateToProps = (
  state: AppState,
  {
    questionId,
    specifyQuestionId,
    match: {
      params: { conditionId },
    },
  }: any
) => {
  const treeId = treeIdFromConditionId(conditionId);

  if (treeId === null || treeId === 'ZZ') {
    throw new Error(`Can’t find tree id for conditionId: ${conditionId}`);
  }

  return {
    answer: (state.specify?.[questionId as MedicalHistoryQuestion['id']]?.[
      treeId
    ]?.[conditionId] as any)?.[specifyQuestionId],
    conditionName: conditionNameFromConditionId(conditionId),
  };
};

const mapDispatchToProps = (
  dispatch: any,
  { specifyQuestionId, conditionId, sectionId, questionId, label }: any
) => ({
  onAnswerQuestion: (answer: any) =>
    dispatch(
      answeredSpecifyQuestion(
        {
          type: 'specify',
          sectionId,
          conditionId,
          specifyQuestionId,
          questionId,
        },
        answer,
        label
      )
    ),
});

export default () => {
  // TODO: Move this into a custom hook
  const {
    sectionId: kebabCaseSectionId,
    questionId: kebabCaseQuestionId,
    conditionId,
    specifyQuestionId: kebabCaseSpecifyQuestionId,
  } = useParams();

  const sectionId = sectionIdFromKebabCaseSectionId(kebabCaseSectionId);
  if (!sectionId) {
    throw new Error(
      `Couldn’t find corresponding section id for: :${kebabCaseSectionId}`
    );
  }

  const questionId = questionIdFromKebabCaseQuestionId(
    sectionId,
    kebabCaseQuestionId
  ) as MedicalHistoryQuestion['id'];

  if (!questionId) {
    throw new Error(
      `Couldn’t find corresponding question id for: :${kebabCaseQuestionId}`
    );
  }
  const specifyQuestionId = specifyQuestionIdFromKebabCaseSpecifyQuestionId(
    kebabCaseSpecifyQuestionId
  );

  const treeId = treeIdFromConditionId(conditionId);

  if (!treeId || treeId === 'ZZ') {
    throw new Error(`No associated tree id for condition id: ${conditionId}`);
  }

  const { title, questionType } = (specifyQuestionsTitleMapping as any)[treeId][
    specifyQuestionId
  ];

  const label = specifyQuestionLabel(treeId, specifyQuestionId);

  const ConnectedView = withRouter(
    connectedRedirect(
      {
        type: 'specify',
        sectionId,
        questionId,
        conditionId,
        specifyQuestionId,
      },
      connect(mapStateToProps, mapDispatchToProps)(View)
    )
  );
  return (
    <ConnectedView
      conditionId={conditionId}
      treeId={treeId}
      sectionId={sectionId}
      questionId={questionId}
      specifyQuestionId={specifyQuestionId}
      title={title}
      questionType={questionType}
      label={label}
    />
  );
};

export function specifyQuestionLabel(
  treeId: Tree['id'],
  questionId: Tree['question']['id']
): LabelType | undefined {
  if (treeId === null) {
    return undefined;
  }

  const questionType: QuestionType<any> = (specifyQuestionsTitleMapping[
    treeId
  ] as any)[questionId].questionType;

  if (questionType.type === 'enum') {
    return questionType.mapping;
  }

  if (questionType.type === 'legal') {
    return questionType.text;
  }

  return undefined;
}
