import { insurance } from '@getpopsure/private-constants';
import * as Sentry from '@sentry/browser';

import { AppState } from 'reducers';
import { BlockerId } from 'models/blockers';
import { Section, GeneralSectionId } from 'models/questions/general';
import { AssociatedShapeFromIdentifier, noEmpty } from 'utils/types';
import { containsEEACountry } from 'utils/containsEEACountry';
import { getInvestigatedConditions } from 'selectors/specify';
import { getReachedAgeThisYear } from 'selectors';

const BMI_UPPER_BOUND = 37;
const BMI_LOWER_BOUND = 15;

export const FREELANCER_MIN_INCOME_THRESHOLD_UNDER_51 = 30_000;
export const FREELANCER_MIN_INCOME_THRESHOLD_51_OR_OLDER = 45_000;

export const getBMI = (state: AppState): number | undefined => {
  const weightInKg = state.questionnaire.medicalHistory?.weightInKg;
  const heightInCm = state.questionnaire.medicalHistory?.heightInCm;

  if (!weightInKg || !heightInCm) {
    return undefined;
  }

  return weightInKg / Math.pow(heightInCm / 100, 2);
};

export const missingTeethBlocker = (state: AppState): BlockerId | undefined => {
  const wisdownTeeth = [18, 28, 48, 38];
  const allMissingTeeth = [
    ...(state.questionnaire.medicalHistory?.lowerJawMissingTeeth ?? []),
    ...(state.questionnaire.medicalHistory?.upperJawMissingTeeth ?? []),
  ];

  const allMissingTeethExcludingWisdomTeeth = allMissingTeeth.filter(
    (teeth) => wisdownTeeth.includes(teeth) !== true
  );

  return allMissingTeethExcludingWisdomTeeth.length >= 4
    ? 'TOO_MANY_MISSING_TEETH'
    : undefined;
};

export const getBlocker = <
  S extends GeneralSectionId,
  Q extends AssociatedShapeFromIdentifier<Section, 'id', S>['question']['id']
>(
  state: AppState,
  sectionId: S,
  questionId: Q
): BlockerId | undefined => {
  const blockerForQuestions: {
    [K in GeneralSectionId]?: {
      [L in AssociatedShapeFromIdentifier<
        Section,
        'id',
        K
      >['question']['id']]?: () => BlockerId | undefined;
    };
  } = {
    personalInfo: {
      currentlyLivingInGermany: () => {
        return state.questionnaire.personalInfo?.currentlyLivingInGermany ===
          false
          ? 'NOT_IN_GERMANY'
          : undefined;
      },
      nationality: () => {
        const isHiGermanyTariff =
          state.tariff.tariff === 'HiMedical' ||
          state.tariff.tariff === 'HiMedicalPlus';
        const isNationalityEEACountry =
          state.questionnaire.personalInfo?.nationality &&
          containsEEACountry(state.questionnaire.personalInfo?.nationality) ===
            true;
        if (isHiGermanyTariff && isNationalityEEACountry) {
          return 'GENERIC';
        }
        return undefined;
      },
    },
    financialHistory: {
      income: () => {
        const income = state.questionnaire.financialHistory?.income;
        if (!income) {
          return undefined;
        }

        const age = getReachedAgeThisYear(state);

        if (age === undefined) {
          Sentry.captureException('Incorrect age for employedOutsideGermany');
        }

        if (
          income >
          insurance.healthInsurance.privateHealthInsuranceThreshold * 12
        ) {
          return undefined;
        }

        const isUnder51 = age === undefined || age < 51;
        const threshold = isUnder51
          ? FREELANCER_MIN_INCOME_THRESHOLD_UNDER_51
          : FREELANCER_MIN_INCOME_THRESHOLD_51_OR_OLDER;

        return income < threshold ? 'INCOME_TOO_LOW' : undefined;
      },
      employedOutsideGermany: () => {
        const age = getReachedAgeThisYear(state);
        const income = state.questionnaire.financialHistory?.income;
        if (!income) {
          return undefined;
        }
        const isUnder51 = age === undefined || age < 51;
        const threshold = isUnder51
          ? FREELANCER_MIN_INCOME_THRESHOLD_UNDER_51
          : FREELANCER_MIN_INCOME_THRESHOLD_51_OR_OLDER;
        const employedOutsideGermany =
          state.questionnaire.financialHistory?.employedOutsideGermany;

        return income > threshold && !employedOutsideGermany
          ? 'EMPLOYED_OUTSIDE_GERMANY'
          : undefined;
      },
      employmentStatus: () => {
        const employmentStatus =
          state.questionnaire.financialHistory?.employmentStatus;
        if (employmentStatus === 'UNIVERSITY_STUDENT') {
          return 'UNIVERSITY_STUDENT';
        } else if (employmentStatus === 'CIVIL_SERVANT') {
          return 'CIVIL_SERVANT';
        }
        return undefined;
      },
      whatKindOfOtherEmployment: () => {
        return state.questionnaire.financialHistory
          ?.whatKindOfOtherEmployment !== undefined
          ? 'OTHER_EMPLOYMENT'
          : undefined;
      },
      incomeEstimate: () => {
        const age = getReachedAgeThisYear(state);

        if (age === undefined) {
          Sentry.captureException('Incorrect age for income estimate');
        }

        const threshold =
          age === undefined || age < 51
            ? FREELANCER_MIN_INCOME_THRESHOLD_UNDER_51
            : FREELANCER_MIN_INCOME_THRESHOLD_51_OR_OLDER;

        return state.questionnaire.financialHistory?.incomeEstimate &&
          state.questionnaire.financialHistory.incomeEstimate < threshold
          ? 'INCOME_ESTIMATE_TOO_LOW'
          : undefined;
      },
      incomeExpectations: () => {
        const age = getReachedAgeThisYear(state);

        if (age === undefined) {
          Sentry.captureException('Incorrect age for income expactations');
        }

        const threshold =
          age === undefined || age < 51
            ? FREELANCER_MIN_INCOME_THRESHOLD_UNDER_51
            : FREELANCER_MIN_INCOME_THRESHOLD_51_OR_OLDER;

        return state.questionnaire.financialHistory?.incomeExpectations &&
          state.questionnaire.financialHistory.incomeExpectations < threshold
          ? 'INCOME_EXPECTATION_TOO_LOW'
          : undefined;
      },
    },
    medicalHistory: {
      answersCheck: () => {
        const substanceAdditionBlocker = state.questionnaire.medicalHistory?.sufferedFromCondition?.includes(
          'SUBSTANCE_ADDICTION'
        )
          ? 'SUBSTANCE_ADDICTION'
          : undefined;

        const hivBlocker =
          state.questionnaire.medicalHistory?.hivPositive === true
            ? 'HIV'
            : undefined;

        const missingBodyPartBlocker =
          state.questionnaire.medicalHistory?.missingBodyPart === 'BREAST'
            ? 'MISSING_BODY_PART'
            : undefined;

        const bmi = getBMI(state);

        const bmiBlocker =
          bmi !== undefined
            ? bmi < BMI_LOWER_BOUND || bmi >= BMI_UPPER_BOUND
              ? 'BMI_OUT_OF_BOUND'
              : undefined
            : undefined;

        const teethConditionsBlocker =
          state.questionnaire.medicalHistory
            ?.howManyTeethAffectedByCondition === 'MORE_THAN_TEN'
            ? 'MORE_THAN_TEN_TEETH_AFFECTED'
            : undefined;

        const eyeDiopterBlocker =
          state.questionnaire.medicalHistory?.maximumEyeDiopters ===
          'MORE_THAN_15'
            ? 'HIGH_DIOPTERS'
            : undefined;

        const hasZZTreeCondition = Object.values(
          getInvestigatedConditions(state)
        )
          .flat()
          .filter(noEmpty)
          .find(({ treeId }) => treeId === 'ZZ')
          ? 'ZZ_CONDITION'
          : undefined;

        return (
          substanceAdditionBlocker ||
          hivBlocker ||
          missingBodyPartBlocker ||
          bmiBlocker ||
          teethConditionsBlocker ||
          eyeDiopterBlocker ||
          hasZZTreeCondition ||
          missingTeethBlocker(state)
        );
      },
    },
  };

  const blockerFunction = (blockerForQuestions as any)[sectionId]?.[questionId];

  if (blockerFunction) {
    return blockerFunction();
  }

  return undefined;
};

export const getHasGapInInsuranceCoverage = (state: AppState): boolean => {
  if (
    state.questionnaire.insuranceHistory
      ?.hasBeenInsuredBetweenHealthInsurances === false
  ) {
    return true;
  }

  if (state.questionnaire.insuranceHistory?.hasHealthInsurance === false) {
    return true;
  }

  return false;
};
