import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { captureException } from '@sentry/browser';
import rootSaga from 'sagas';

import questionnaire from 'reducers/questionnaire';
import metadata from 'reducers/metadata';
import asyncOperation from 'reducers/asyncOperation';
import specify from 'reducers/specify';
import remoteData from 'reducers/remoteData';
import session from 'reducers/session';
import tariff from 'reducers/tariff';
import investigation from 'reducers/investigation';
import { Action } from 'actions/type';
import { createState } from 'utils/state';

export const appReducer = combineReducers({
  questionnaire,
  metadata,
  asyncOperation,
  specify,
  tariff,
  remoteData,
  session,
  investigation,
});

const rootReducer = (state: AppState, action: Action) => {
  if (action.type === 'RESET_STORE') {
    state = action.newStore ?? createState({});
  }

  return appReducer(state, action);
};

export type AppState = ReturnType<typeof appReducer>;

const persistedReducer = persistReducer(
  { key: 'root', storage, blacklist: ['asyncOperation'] },
  rootReducer as typeof appReducer
);

function configureStore(
  initialState = (window.Cypress && window.initialState) || {}
) {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error) => {
      captureException(error);
      if (process.env.NODE_ENV === 'development') {
        // tslint:disable-next-line:no-console
        console.error(error);
      }
    },
  });
  const middlewares = [sagaMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middleWareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const s = createStore(persistedReducer, initialState, composedEnhancers);
  const p = persistStore(s);

  sagaMiddleware.run(rootSaga);

  return { store: s, persistor: p };
}

export const { store, persistor } = configureStore();
