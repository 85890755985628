import { Action } from 'actions/type';

export function setJWT(jwt: string): Action {
  return {
    type: 'SIGN_IN',
    jwt,
  };
}

export function invalidateJWT(): Action {
  return { type: 'INVALIDATE_JWT' };
}

export function existingCustomer(existingCustomer: boolean): Action {
  return { type: 'EXISTING_CUSTOMER', existingCustomer };
}
