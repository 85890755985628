import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Center from 'components/center';
import ProgressRow from './progressRow';
import { getHasGapInInsuranceCoverage, getBlocker } from 'selectors/blocker';
import { TranslationFn, useTranslation } from '@getpopsure/i18n-react';

import { AppState } from 'reducers';
import { noEmpty } from 'utils/types';
import { CalculatingCompleteIcon, CalculatingIcon } from './img';
import { answeredQuestion } from 'actions';

import styles from './style.module.scss';

export function generateLoadingContent({
  skipDoctorVisit,
  warning,
  t,
}: {
  skipDoctorVisit: boolean;
  warning: boolean;
  t: TranslationFn;
}): { description: string; timingFunction: number[] }[] {
  const timingFunction = [
    [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 95, 98, 100],
    [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      10,
      30,
      40,
      55,
      65,
      70,
      75,
      78,
      warning ? -1 : 80,
      90,
      100,
    ],
    [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      30,
      60,
      80,
      90,
      100,
    ],
    [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      25,
      50,
      85,
      90,
      100,
    ],
  ];

  return [
    t('page.answercheck.progress.reviewing', 'Reviewing answers'),
    t('page.answercheck.progress.analysing', 'Analysing risk profile'),
    skipDoctorVisit
      ? t('page.answercheck.skipdoctor', 'Skipping the doctor’s visit')
      : undefined,
    t('page.answercheck.lastcheck', 'Doing last checks'),
  ]
    .filter(noEmpty)
    .map((description, index) => {
      return {
        description,
        timingFunction: timingFunction[index],
      };
    });
}

const AnswerCheck = () => {
  const { t } = useTranslation();
  const [tick, setTick] = useState(0);
  const [hasCompleteLoading, setHasCompletedLoading] = useState(false);
  const interval = useRef<NodeJS.Timeout>();
  const hasGapInInsuranceCoverage = useSelector(getHasGapInInsuranceCoverage);
  const hasBlocker =
    useSelector((state: AppState) =>
      getBlocker(state, 'medicalHistory', 'answersCheck')
    ) !== undefined;
  const dispatch = useDispatch();

  useEffect(() => {
    interval.current = setInterval(() => {
      setTick((tick) => tick + 1);
    }, 300);

    return () => interval.current && clearInterval(interval.current);
  }, []);

  const stopTimer = () => {
    interval.current && clearInterval(interval.current);
  };

  const handleContinue = () => {
    dispatch(
      answeredQuestion(
        {
          type: 'general',
          sectionId: 'medicalHistory',
          questionId: 'answersCheck',
        },
        true
      )
    );
  };

  const content = generateLoadingContent({
    skipDoctorVisit: !hasGapInInsuranceCoverage,
    warning: hasBlocker,
    t,
  });

  return (
    <Center>
      <div className="wmx8 ml-auto mr-auto">
        {hasCompleteLoading ? (
          <CalculatingCompleteIcon className="d-block ml-auto mr-auto" />
        ) : (
          <CalculatingIcon className="d-block ml-auto mr-auto" />
        )}
        <h1 className="p-h1 ta-center mt16">
          {hasCompleteLoading
            ? t('page.answercheck.eligible', 'You’re eligible 🎉')
            : t('page.answercheck.loading', 'Checking your answers')}
        </h1>
        <p className="p-p mt8 ta-center">
          {hasCompleteLoading
            ? t('page.answercheck.steps', 'Only a few steps remaining.')
            : t(
                'page.answercheck.reviewingdata',
                'We’re reviewing your data to assess your eligibility'
              )}
        </p>
        <div className="mt48">
          {content.map(({ description, timingFunction }, index) => {
            const progress =
              timingFunction[Math.min(tick, timingFunction.length - 1)];

            if (progress === -1) {
              // We use -1 to indicate a warning on loading
              stopTimer();
              setTimeout(() => {
                handleContinue();
              }, 1000);
            }

            if (
              index === content.length - 1 &&
              progress === 100 &&
              hasCompleteLoading === false
            ) {
              setHasCompletedLoading(true);
              stopTimer();
            }

            return (
              <ProgressRow
                key={description}
                description={description}
                progress={progress}
                warning={progress === -1}
              />
            );
          })}
        </div>
        <button
          data-cy="answer-check-continue"
          className={`p-btn--primary wmn3 ml-auto mr-auto mt48 ${
            styles['continue-button']
          } ${
            hasCompleteLoading === true
              ? styles['continue-button--display']
              : ''
          }`}
          onClick={handleContinue}
        >
          {t('page.answercheck.continue', 'Continue')}
        </button>
      </div>
    </Center>
  );
};

export default AnswerCheck;
