import moment from 'moment';

import { AppState } from 'reducers';
import { addQuestionAfter, GeneralQuestionId } from '..';
import { TranslationFn } from '@getpopsure/i18n-react';

type HealthInsuranceOptions =
  | 'GERMAN_PUBLIC_INSURANCE'
  | 'GERMAN_PRIVATE_INSURANCE'
  | 'GERMAN_CIVIL_SERVANT_INSURANCE'
  | 'NON_GERMAN_INSURANCE_INSURANCE'
  | 'HOLIDAY_TRAVEL_INSURANCE';

export const healthInsuranceOptionMapping = (
  t: TranslationFn
): {
  [K in HealthInsuranceOptions]: string;
} => ({
  GERMAN_PUBLIC_INSURANCE: t(
    'questions.healthinsurances.mapping.germanpublic',
    'German public health insurance'
  ),
  GERMAN_PRIVATE_INSURANCE: t(
    'questions.healthinsurances.mapping.germanprivate',
    'German private health insurance'
  ),
  GERMAN_CIVIL_SERVANT_INSURANCE: t(
    'questions.healthinsurances.mapping.civilservant',
    'German health insurance for civil servant or soldiers'
  ),
  NON_GERMAN_INSURANCE_INSURANCE: t(
    'questions.healthinsurances.mapping.nongermanhealth',
    'Non-German health insurance'
  ),
  HOLIDAY_TRAVEL_INSURANCE: t(
    'questions.healthinsurances.mapping.holidaytravel',
    'Holiday travel insurance only'
  ),
});

export type InsuranceHistoryQuestion =
  | {
      id: 'hadConsistentHealthInsurance';
      answer: boolean;
    }
  | { id: 'endOfLastHealthInsurance'; answer: string }
  | { id: 'hasBeenInsuredBetweenHealthInsurances'; answer: boolean }
  | { id: 'currentHealthInsurance'; answer: HealthInsuranceOptions }
  | {
      id: 'hasHealthInsurance';
      answer: boolean;
    }
  | {
      id: 'insuranceHistoryReview';
      answer: boolean;
    };

export const insuranceHistoryQuestionOrder = (
  state: AppState
): InsuranceHistoryQuestion['id'][] => {
  const insuranceHistory = state.questionnaire.insuranceHistory;

  let order: GeneralQuestionId[] = [
    'hasHealthInsurance',
    'insuranceHistoryReview',
  ];

  if (insuranceHistory?.hasHealthInsurance === true) {
    order = addQuestionAfter({
      questionsToAdd: [
        'currentHealthInsurance',
        'hadConsistentHealthInsurance',
      ],
      afterQuestion: 'hasHealthInsurance',
      order,
    });
  }

  if (insuranceHistory?.hasHealthInsurance === false) {
    order = addQuestionAfter({
      questionsToAdd: ['endOfLastHealthInsurance'],
      afterQuestion: 'hasHealthInsurance',
      order,
    });

    if (!insuranceHistory.endOfLastHealthInsurance) {
      return order as InsuranceHistoryQuestion['id'][];
    }

    const today = new Date(Date.now());
    const lastInsuranceEndedIn = new Date(
      insuranceHistory?.endOfLastHealthInsurance
    );
    const diff = moment(today).diff(
      moment(lastInsuranceEndedIn),
      'months',
      false
    );

    if (diff < 2) {
      order = addQuestionAfter({
        questionsToAdd: ['hasBeenInsuredBetweenHealthInsurances'],
        afterQuestion: 'endOfLastHealthInsurance',
        order,
      });
    }
  }

  return order as InsuranceHistoryQuestion['id'][];
};
