import React, { useState, useContext, useEffect } from 'react';
import AnimateHeight from 'react-animate-height';
import { useLocation } from 'react-router-dom';
import { addOnNameMapping } from '@getpopsure/private-health-insurance-pricing-engine';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { useTranslation } from '@getpopsure/i18n-react';

import { ViewProps } from '..';
import { DisplayLiveQuoteContext } from 'Root';
import { preExistingConditionTitle } from './util';

import styles from './style.module.scss';

const Cell = ({ title, value }: { title: string; value: string }) => (
  <div className={styles.cell}>
    <div className="p-p--small">{title}</div>
    <div className="p-p--small fw-bold">{value}</div>
  </div>
);

export default ({
  totalPerMonth,
  premium,
  longTermCare,
  riskLevel,
  statutorySurcharge,
  addOns,
  riskFactorSurcharge,
  policyName,
  ownContribution,
  employerContribution,
}: ViewProps) => {
  const [expanded, setExpanded] = useState(false);
  const { displayLiveQuote } = useContext(DisplayLiveQuoteContext);
  const { t } = useTranslation();

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes('final-steps')) {
      setExpanded(true);
    }
  }, [pathname]);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  if (
    !totalPerMonth ||
    displayLiveQuote === false ||
    !premium ||
    !longTermCare ||
    !policyName
  ) {
    return <></>;
  }

  const isMobile = window.screen.width < 544;

  if (isMobile === true) {
    if (expanded === true) {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.width = `${window.screen.width}px`;
      document.body.style.position = 'fixed';
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      window.scrollTo(0, parseInt(scrollY || '0', 0) * -1);
    }
  }

  return (
    <>
      {expanded && isMobile && (
        <div className={styles.overlay} onClick={handleClick} />
      )}
      <div className={styles.container} onClick={handleClick}>
        <div
          className={`${styles.chevron} ${
            expanded ? styles['chevron--expanded'] : ''
          }`}
        />{' '}
        <div
          className={
            expanded
              ? styles['total-per-month--expanded']
              : styles['total-per-month']
          }
        >
          <div className="">
            {t('component.livequote.monthlytotal.title', 'Your monthly total')}
          </div>
          <div className="fw-bold">
            {englishFormattedEuroCurrency(ownContribution ?? totalPerMonth)}
          </div>
        </div>
        <AnimateHeight duration={300} height={expanded ? 'auto' : 0}>
          <div data-cy="live-pricing-extended">
            <Cell
              title={`${policyName} Policy`}
              value={englishFormattedEuroCurrency(
                premium + longTermCare + (statutorySurcharge ?? 0)
              )}
            />
            {addOns && addOns.length > 0 && (
              <>
                <h4 className="p-p--small fw-bold mt8">Add ons</h4>
                {addOns.map(({ id, surcharge }) => (
                  <Cell
                    key={id}
                    title={addOnNameMapping[id]}
                    value={englishFormattedEuroCurrency(surcharge)}
                  />
                ))}
              </>
            )}
            {riskLevel && riskLevel.level !== 'RG0' && (
              <>
                <h4 className="p-p--small fw-bold mt8">
                  {t(
                    'component.livequote.preexistingconditions.title',
                    'Pre-existing conditions'
                  )}
                </h4>
                <Cell
                  title={preExistingConditionTitle(riskLevel)}
                  value={
                    riskLevel.level === 'X'
                      ? '???'
                      : englishFormattedEuroCurrency(riskFactorSurcharge ?? 0)
                  }
                />
              </>
            )}
            {riskLevel?.level === 'X' && (
              <div className="p-notice--warning">
                <p className="p-p--small">
                  {t(
                    'component.livequote.risklevel.x.title',
                    'Because of your medical history, further assessment of your responses will be needed to determine your precise risk level. If this results in a change in price, we will get in touch with you.'
                  )}
                </p>
              </div>
            )}
            {employerContribution && (
              <>
                <hr className={styles.separator} />
                <div className={styles.cell}>
                  <div className="p-p--small">
                    {t(
                      'component.livequote.employercontribution.title',
                      'Employer pays'
                    )}
                  </div>
                  <div className="p-p--small tc-grey-500">
                    {englishFormattedEuroCurrency(employerContribution)}
                  </div>
                </div>
              </>
            )}
          </div>
        </AnimateHeight>
      </div>
    </>
  );
};
