import React, { useEffect } from 'react';
import { SubmitApplicationView } from './SubmitApplication.view';
import { getQuestionnaire, getQuote, getUser } from 'selectors/remoteData';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuote, fetchUser } from 'actions';
import Loading from 'components/loading';
import {
  getAsyncOperationError,
  getAsyncOperationInProgress,
} from 'selectors/asyncOperation';
import { AppState } from 'reducers';
import { OperationId } from 'models/asyncOperation';
import { submitApplicationAction } from 'features/privatePayments/actions';
import { PRIVATE_PAYMENTS_SUBMIT_OPERATION_ID } from 'features/privatePayments/constants';

export const SubmitApplicationContainer = () => {
  const dispatch = useDispatch();
  const quote = useSelector(getQuote);
  const user = useSelector(getUser);
  const questionnaire = useSelector(getQuestionnaire);
  const operations: OperationId[] = ['get.quote', 'get.user'];
  const loading = useSelector((state: AppState) =>
    getAsyncOperationInProgress(state, ...operations)
  );
  const error = useSelector((state: AppState) =>
    getAsyncOperationError(state, ...operations)
  );
  const submitLoading = useSelector((state: AppState) =>
    getAsyncOperationInProgress(state, PRIVATE_PAYMENTS_SUBMIT_OPERATION_ID)
  );
  const submitError = useSelector((state: AppState) =>
    getAsyncOperationError(state, PRIVATE_PAYMENTS_SUBMIT_OPERATION_ID)
  );

  useEffect(() => {
    if (questionnaire === undefined) {
      throw new Error(
        '[PRIVATE_PAYMENTS]: Trying to submit application without a valid questionnaire'
      );
    }

    dispatch(fetchQuote(questionnaire.id));
    dispatch(fetchUser());
  }, [questionnaire, dispatch]);

  if (loading || quote === undefined || user === undefined) {
    return <Loading />;
  }

  const handleSubmitApplication = () => {
    dispatch(submitApplicationAction());
  };

  return (
    <SubmitApplicationView
      questionnaire={questionnaire}
      user={user}
      quote={quote}
      error={error || submitError}
      onSubmitApplication={handleSubmitApplication}
      loading={submitLoading}
    />
  );
};
