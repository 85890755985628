import React from 'react';
import { DateSelector } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <WrappedForm
        validator={nonEmptyValidator}
        sectionId="insuranceHistory"
        questionId="endOfLastHealthInsurance"
      >
        {({ value, setValue }) => (
          <>
            <h1 className="p-h1">
              {generalQuestionsTitleMapping.endOfLastHealthInsurance?.(t)}
            </h1>
            <div className="mt8">
              <DateSelector
                value={value}
                onChange={(date) => setValue(date)}
                yearBoundaries={{
                  min: 1990,
                  max: new Date().getFullYear() + 1,
                }}
              />
            </div>
          </>
        )}
      </WrappedForm>
    </Center>
  );
};
