import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from '@getpopsure/i18n-react';
import { Trans } from '@getpopsure/i18n-react';

import styles from './style.module.scss';

export default ({ className }: { className: string }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={className}>
      <AnimateHeight duration={300} height={expanded ? 'auto' : 20}>
        {expanded ? (
          <p className={`p-p p-p--small wmx8 ta-center ${styles.legal}`}>
            <Trans i18nKey="page.sepa.iban.legal.text">
              By setting up direct debit I authorize Hallesche
              Krankenversicherung a.G. to send instructions to my bank to debit
              my account each month and to my bank to execute the direct debit
              in accordance with the instructions from Hallesche
              Krankenversicherung a.G. (Creditor ID: DE89ZZZ00000031444). I
              confirm that I am authorized to set up direct debit for this
              account. Hallesche Krankenversicherung a.G. will send the
              instructions to my bank at least six days prior to the first due
              date. In case of repeated direct debit with equal or fixed direct
              debit amounts a single information before the first direct debit
              and the details of the due dates is sufficient. As part of my
              rights, I am entitled to a refund from my bank under the terms and
              conditions of the agreement with my bank. A refund must be claimed
              within 8 weeks starting from the date on which my account was
              debited.
            </Trans>
          </p>
        ) : (
          <div
            className={`ta-center ${styles.button}`}
            role="button"
            onClick={() => {
              setExpanded(true);
            }}
          >
            {t(
              'page.sepa.iban.legal.action',
              'How payment by direct debit works +'
            )}
          </div>
        )}
      </AnimateHeight>
    </div>
  );
};
