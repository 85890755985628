import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '@getpopsure/i18n-react';

import Center from 'components/center';
import { connectedRedirect } from 'components/connectedRedirect';
import { ReactComponent as DowloadDocsSvg } from './img/download-docs.svg';
import { ReactComponent as SignatureSvg } from './img/signature.svg';
import { ReactComponent as PaymentSvg } from './img/payment.svg';

import { path } from 'routes/path';

import styles from './style.module.scss';

const View = () => {
  const { t } = useTranslation();
  return (
    <Center>
      <h1 className="p-h1">
        {t('page.finalstep.title', 'Your application is almost complete')}
      </h1>
      <p className="p-p mt16 wmx9 w100">
        {t(
          'page.finalstep.description',
          'You’ve nearly made it. There are three quick steps left:'
        )}
      </p>
      <div className={`d-flex mt32 mb8 ${styles.cardscontainer}`}>
        <div className="wmn4">
          <div className={styles.card}>
            <SignatureSvg />
            <h3>{t('page.finalstep.1.title', 'Provide digital signature')}</h3>
          </div>
        </div>
        <div className="wmn4">
          <div className={styles.card}>
            <DowloadDocsSvg />
            <h3>{t('page.finalstep.2.title', 'Download documents')}</h3>
          </div>
        </div>
        <div className="wmn4">
          <div className={styles.card}>
            <PaymentSvg />
            <h3>{t('page.finalstep.3.title', 'Set up payment')}</h3>
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Link
          data-cy="final-step-continue"
          className="p-btn--primary wmn4"
          to={path.brokerMandate}
        >
          {t('page.finalstep.action', 'Continue')}
        </Link>
      </div>
    </Center>
  );
};

export default connectedRedirect(
  {
    type: 'general',
    sectionId: 'medicalHistory',
    questionId: 'finalSteps',
  },
  View
);
