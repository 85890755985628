import { AppState } from 'reducers';
import { getReachableSections } from 'selectors';
import { getQuestionOrder } from 'selectors/ordering';
import { Question } from 'models/questions';
import { BrokerInfo } from 'models/brokerInfo';

export const getAnswersMetadata = (state: AppState) => state.metadata.answers;

export const getQuestionOrderMetadata = (state: AppState) => {
  const reachableSections = getReachableSections(state);
  let toReturn: Question[] = [];
  reachableSections.forEach((sectionId) => {
    const order = getQuestionOrder(state, sectionId);
    toReturn = toReturn.concat(order);
  });

  return toReturn;
};

export const getBrokerInfo = (state: AppState): BrokerInfo | undefined => {
  return state.metadata.brokerInfo;
};
