import { AppState } from 'reducers';

export const createState = (partialState: Partial<AppState>): AppState => ({
  questionnaire: {},
  remoteData: {},
  asyncOperation: {},
  specify: {},
  session: {},
  tariff: {},
  metadata: {},
  investigation: {},
  ...partialState,
});
