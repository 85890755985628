import React from 'react';
import { MultiDropzone } from '@popsure/dirty-swan';
import { Trans, useTranslation } from '@getpopsure/i18n-react';

import Center from 'components/center';
import connectedAnswer, {
  ViewProps as GenericViewProps,
} from 'pages/questions/connectedAnswer';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { useUploadFiles } from 'utils/hooks';

export type ViewProps = GenericViewProps<'financialHistory', 'workContract'>;

const View = ({ onAnswerQuestion, answer }: ViewProps) => {
  const { t } = useTranslation();
  const {
    isSubmitValid,
    uploadFile,
    uploadedFiles,
    removeFile,
    loading,
  } = useUploadFiles({
    initialValue: answer ?? [],
    questionId: 'workContract',
  });

  return (
    <Center>
      <h1 className="p-h1">{generalQuestionsTitleMapping.workContract?.(t)}</h1>
      <Trans i18nKey="questions.workcontract.description">
        <p className="p-p mt8">
          Since you are employed we require a{' '}
          <span className="fw-bold">signed</span> copy of the employment
          contract from your employer.
        </p>
      </Trans>
      <div className="mt16">
        <MultiDropzone
          onFileSelect={uploadFile}
          uploading={loading}
          uploadedFiles={uploadedFiles}
          onRemoveFile={removeFile}
          maxFiles={1}
        />
      </div>
      <button
        data-cy="questionnaire-continue"
        className="p-btn--primary wmn3 mt24"
        type="submit"
        disabled={!isSubmitValid}
        onClick={() => {
          if (!isSubmitValid) {
            return;
          }

          onAnswerQuestion(uploadedFiles);
        }}
      >
        {t('questions.workcontract.continue.action', 'Continue')}
      </button>
    </Center>
  );
};
export default connectedAnswer('financialHistory', 'workContract')(View);
