import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

import { REFERRAL_ENGINE_FEATURE_FLAG_ID } from '../constants';

export const useReferralEngineFlag = (): boolean => {
  const { flagsReady } = useFlagsStatus();
  const referralEngineFlag = useFlag(REFERRAL_ENGINE_FEATURE_FLAG_ID);

  return flagsReady && referralEngineFlag;
};
