import moment from 'moment';

import {
  DeductibleForTariff,
  Tariff,
  AddOn,
} from '@getpopsure/private-health-insurance-pricing-engine';
import { EmploymentStatus } from 'models/questions/general/financialHistory';

export const isTariff = (input?: string | null): input is Tariff => {
  if (!input || input === null) {
    return false;
  }

  const _tariffs: { [K in Tariff]: any } = {
    NK: '',
    NKSelectS: '',
    NKSelectL: '',
    NKSelectXL: '',
    KS: '',
    Primo: '',
    PrimoPlus: '',
    HiMedical: '',
    HiMedicalPlus: '',
  };
  const tariffs = Object.keys(_tariffs);

  return tariffs.includes(input);
};

export const isDeductible = (
  input?: string | null
): input is DeductibleForTariff<Tariff> => {
  if (!input || input === null) {
    return false;
  }

  const _deductibles: { [K in DeductibleForTariff<Tariff>]: any } = {
    0: '',
    300: '',
    500: '',
    600: '',
    1200: '',
    3000: '',
  };

  const deductibles = Object.keys(_deductibles);

  return deductibles.includes(input);
};

export const rawQueryParametersAddOnstoAddOn = (
  input: string | null
): AddOn[] => {
  const arr = (input ?? '').split(',');
  const possibleAddOnsValues: { [K in AddOn]: number } = {
    URZ: 0,
    KT: 1,
    FLEX: 2,
    HD: 3,
    HDL: 4,
    NKSFLEX: 5,
  };

  return arr.filter((v) =>
    Object.keys(possibleAddOnsValues).includes(v)
  ) as AddOn[];
};

export const isEmploymentStatus = (
  input?: string | null
): input is EmploymentStatus => {
  if (!input) {
    return false;
  }

  const possibleEmploymentStatus: { [K in EmploymentStatus]: number } = {
    EMPLOYED: 0,
    SELF_EMPLOYED: 1,
    CIVIL_SERVANT: 2,
    UNIVERSITY_STUDENT: 3,
    OTHER: 4,
  };

  return Object.keys(possibleEmploymentStatus).includes(input);
};

export const isValidISODate = (input?: string | null): input is string => {
  if (!input) {
    return false;
  }
  return moment(input, 'YYYY-MM-DD', true).isValid();
};

export const isValidISODateWithoutDay = (
  input?: string | null
): input is string => {
  if (!input) {
    return false;
  }
  return moment(input, 'YYYY-MM', true).isValid();
};
