import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';

import { flushOperationError } from 'actions/asyncOperation';
import {
  flushReferralEngineError,
  updateReferralInfo,
  verifyReferralCode,
} from 'features/referralEngine/actions';
import {
  getReferralCodeError,
  getValidReferralCode,
} from 'features/referralEngine/selectors';
import { REFERRAL_VERIFY_OPERATION_ID } from 'features/referralEngine/constants';

import {
  getAsyncOperationErrorMessage,
  getAsyncOperationInProgress,
} from 'selectors/asyncOperation';

import { ReferrerCodeVerifyView } from './ReferrerCodeVerifyView';

export const ReferrerCodeVerifyContainer = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const referralCode = useSelector(getValidReferralCode);
  const dispatch = useDispatch();
  const loading = useSelector((state: AppState) =>
    getAsyncOperationInProgress(state, REFERRAL_VERIFY_OPERATION_ID)
  );
  const error = useSelector((state: AppState) =>
    getAsyncOperationErrorMessage(state, REFERRAL_VERIFY_OPERATION_ID)
  );
  const referralCodeError = useSelector(getReferralCodeError);

  useEffect(() => {
    // Close modal when a valid referral code is applied
    if (referralCode && !referralCodeError) {
      setOpenModal(false);
    }
  }, [referralCode, referralCodeError]);

  const handleSubmitCode = (code: string) => {
    dispatch(verifyReferralCode(code));
  };

  const handleRemoveValidReferrerCode = () => {
    dispatch(updateReferralInfo({ referralCode: undefined }));
    setOpenModal(false);
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => {
    if (error || referralCodeError) {
      dispatch(flushOperationError(REFERRAL_VERIFY_OPERATION_ID));
      dispatch(flushReferralEngineError());
    }
    setOpenModal(false);
  };

  return (
    <ReferrerCodeVerifyView
      validReferrerCode={referralCode}
      openModal={openModal}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      onSubmitValidReferrerCode={handleSubmitCode}
      onRemoveValidReferrerCode={handleRemoveValidReferrerCode}
      loading={loading}
      error={error || referralCodeError}
    />
  );
};
