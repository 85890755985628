import { isEEACountry, Country } from '@popsure/public-models';

export const containsEEACountry = (countries: Country[]): boolean => {
  // TODO: Check for countries is for some reason needed for e2e tests to pass
  if (countries.length > 0) {
    const EEACountries = countries.filter(
      (country) => isEEACountry(country) === true
    );
    return EEACountries.length > 0;
  }
  return false;
};
