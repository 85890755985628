import {
  pricesForTariff,
  contributionsForTariff,
  EmploymentStatus as PricingEngineEmploymentStatus,
} from '@getpopsure/private-health-insurance-pricing-engine';

import { AppState } from 'reducers';
import { getReachedAgeThisYear, getReachedAgeWhenPolicyStart } from 'selectors';
import {
  getTariffAndDeductible,
  getAddOns,
  getSickDayPayout,
} from 'selectors/tariff';
import { getRiskFactor } from 'selectors/specify';
import { EmploymentStatus } from 'models/questions/general/financialHistory';

export const getPricesForTariff = (state: AppState) => {
  const tariffAndDeductible = getTariffAndDeductible(state);

  if (!tariffAndDeductible) {
    return undefined;
  }

  const age =
    getReachedAgeWhenPolicyStart(state) ?? getReachedAgeThisYear(state);
  const riskFactor = getRiskFactor(state);
  const sickDayPayout = getSickDayPayout(state);
  const addOns = getAddOns(state);

  if (!age || !tariffAndDeductible) {
    return undefined;
  }

  const { tariff, deductible } = tariffAndDeductible;
  const birthYear = new Date().getFullYear() - age;

  return pricesForTariff({
    birthYear,
    tariff,
    deductible,
    selectedAddOns: addOns,
    riskFactor,
    sickDayPayout,
  });
};

export const getContributions = (
  state: AppState
): {
  ownContributions: number;
  employerContributions: number;
} | null => {
  const tariff = getPricesForTariff(state);
  const rawEmploymentStatus =
    state.questionnaire.financialHistory?.employmentStatus;

  if (!tariff || !rawEmploymentStatus) {
    return null;
  }

  const employmentStatusMapping: Record<
    EmploymentStatus,
    PricingEngineEmploymentStatus
  > = {
    EMPLOYED: 'EMPLOYED_IN_GERMANY',
    SELF_EMPLOYED: 'SELF_EMPLOYED',
    CIVIL_SERVANT: 'OTHER',
    UNIVERSITY_STUDENT: 'OTHER',
    OTHER: 'OTHER',
  };

  const { ownContributions, employerContributions } = contributionsForTariff({
    tariff,
    employmentStatus: employmentStatusMapping[rawEmploymentStatus],
  });

  if (!employerContributions) {
    return null;
  }

  return { ownContributions, employerContributions };
};
