import { TranslationFn } from '@getpopsure/i18n-react';
import { Address } from '@popsure/public-models';

export const getPersonalInfoMapping = ({
  t,
  firstName,
  lastName,
  dateOfBirth,
  address,
  phoneNumber,
  email,
}: {
  t: TranslationFn;
  firstName?: string;
  lastName?: string;
  dateOfBirth: string;
  address?: Address;
  phoneNumber: string;
  email: string;
}): { key: string; value: string }[] => [
  {
    key: t('page.sepa.personalinfo.name', 'Name'),
    value: `${firstName} ${lastName}`,
  },
  {
    key: t('page.sepa.personalinfo.dateofbirth', 'Date of birth'),
    value: dateOfBirth,
  },
  {
    key: t('page.sepa.personalinfo.address', 'Address'),
    value: `${address?.street} ${address?.houseNumber}, ${address?.postcode} ${address?.city}`,
  },
  {
    key: t('page.sepa.personalinfo.telephone', 'Telephone'),
    value: phoneNumber,
  },
  { key: t('page.sepa.personalinfo.email', 'Email'), value: email },
];
