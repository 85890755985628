import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useTracker, trackPageView } from '@getpopsure/analytics';

import '@popsure/dirty-swan/dist/index.css';

import Progress from 'components/progress';
import LiveQuote from 'components/liveQuote';
import StickyContainer from 'components/stickyContainer';
import ReturningUserPrompt from 'pages/returningUserPrompt';
import Header from 'pages/header';
import routes from 'routes';
import CurrentSection from 'components/currentSection';
import NotFound404 from 'components/404';
import CookiesBanner from 'components/cookiesBanner';
import Session from 'components/session';
import SigninModal from 'components/SigninModal/index';

import 'headerScripts';

function App() {
  useTracker();
  const location = useLocation();

  useEffect(() => {
    trackPageView();
  }, [location]);

  return (
    <Session>
      <ReturningUserPrompt>
        <>
          <Header />
          <CurrentSection />
          <Switch>
            {routes.map(({ path, component, ...props }) => (
              <Route
                key={path}
                path={path}
                exact={true}
                component={component}
                {...props}
              />
            ))}
            <Route component={NotFound404} />
          </Switch>
          <SigninModal />
        </>
      </ReturningUserPrompt>
      <StickyContainer />
      <LiveQuote />
      <Progress />
      <CookiesBanner />
    </Session>
  );
}

export default App;
