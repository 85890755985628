import React from 'react';
import { render } from 'react-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import 'utils/errorReporter';
import 'utils/i18n';

import Root from './Root';
import * as serviceWorker from './serviceWorker';

import { store, persistor } from 'reducers';

import FlagProvider from '@unleash/proxy-client-react';
import { unleashConfig } from './unleash.config';

if (window.Cypress) {
  window.store = store;
}

declare global {
  interface Window {
    Calendly: any;
    Cypress: any;
    store: any;
    initialState: any;
  }
}

render(
  <FlagProvider config={unleashConfig}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Root />
      </PersistGate>
    </Provider>
  </FlagProvider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
