import { all, call, put, select, take } from 'redux-saga/effects';
import { app } from '@getpopsure/private-constants';

import { transformQuestionnaireModel } from 'network/api/transformQuestionnaireModel';
import {
  clearAllRemoteData,
  clearRemoteData,
  fetchUser as fetchUserAction,
  mergeQuestionnaire,
  mergeQuote,
  mergeUser,
  storeAnsweredQuestion,
  submitQuestionnaire as submitQnrAction,
} from 'actions';
import {
  getQuestionAfter,
  getQuestionnaireWithoutUnreachableQuestions,
} from 'selectors';
import {
  setAsyncOperationErrored,
  setAsyncOperationFinished,
  setAsyncOperationInProgress,
} from 'actions/asyncOperation';
import { Action, ActionType } from 'actions/type';
import { path, pathForBlockerId, pathForQuestion } from 'routes/path';
import { getBlocker } from 'selectors/blocker';
import * as API from 'network/api';
import { getRiskLevel, getSpecifyQuestionnaire } from 'selectors/specify';
import { AssociatedShapeFromIdentifier, AsyncReturnType } from 'utils/types';
import { invalidateJWT, setJWT } from 'actions/session';
import { User } from 'models';
import { Question } from 'models/questions';
import { getSickDayPayout, getTariffAndDeductible } from 'selectors/tariff';
import { getIsAuthenticated, getUserId } from 'selectors/session';
import { getQuote, getUser } from 'selectors/remoteData';
import {
  getAnswersMetadata,
  getBrokerInfo,
  getQuestionOrderMetadata,
} from 'selectors/metadata';
import { getPricesForTariff, getContributions } from 'selectors/price';
import { AddOn } from '@getpopsure/private-health-insurance-pricing-engine';
import { isBrokerVersion as _isBrokerVersion } from 'utils/isBrokerVersion';
import { downloadBlob as _downloadBlob } from 'utils/downloadBlob';
import { sleep } from 'utils/sleep';
import Axios, { AxiosResponse } from 'axios';
import { setTokenCheckTimer } from 'utils/session';
import browserHistory from 'utils/browserHistory';
import { getInvestigationQuestionnaire } from 'selectors/investigation';

import { beginNewApplication } from './beginNewApplication';
import { trackConversion } from '@getpopsure/analytics';
import { verifyReferralCode } from 'features/referralEngine/sagas';
import { getValidReferralCode } from 'features/referralEngine/selectors';
import { updateReferralInfo } from 'features/referralEngine/actions';
import { submitApplicationSaga } from 'features/privatePayments';

export type AssociatedActionFromType<
  T extends Action['type']
> = AssociatedShapeFromIdentifier<Action, 'type', T>;

export function push(toPath: string) {
  browserHistory.push(toPath);
}

export function* verifyPhoneNumber(api = API) {
  while (true) {
    const actionType: ActionType = 'START_PHONE_VERIFICATION';

    const {
      phoneNumber,
    }: AssociatedActionFromType<typeof actionType> = yield take(actionType);

    yield put(setAsyncOperationInProgress('post.phone.verify'));

    try {
      yield call(api.startPhoneVerification, phoneNumber);
      yield put(setAsyncOperationFinished('post.phone.verify'));
    } catch (error) {
      yield put(setAsyncOperationErrored('post.phone.verify', error));
    }
  }
}

export function* confirmPhoneNumber(api = API) {
  while (true) {
    const actionType: ActionType = 'CONFIRM_PHONE_NUMBER';

    const {
      phoneNumber,
      code,
    }: AssociatedActionFromType<typeof actionType> = yield take(actionType);

    yield put(setAsyncOperationInProgress('post.phone.confirm'));
    try {
      yield call(api.confirmPhoneNumber, phoneNumber, code);
      yield call(push, path.legalDocuments);
      yield put(setAsyncOperationFinished('post.phone.confirm'));
    } catch (error) {
      yield put(setAsyncOperationErrored('post.phone.confirm', error));
    }
  }
}

export function* signInWithTemporaryLoginCode(api = API) {
  while (true) {
    const actionType: ActionType = 'SIGN_IN_WITH_LOGIN_CODE';

    const {
      code,
      email,
      signedInCallback,
    }: AssociatedActionFromType<typeof actionType> = yield take(actionType);
    yield put(setAsyncOperationInProgress('post.signin.code'));
    try {
      const {
        data: { auth_token: jwt },
      }: AsyncReturnType<typeof API.signInWithTemporaryLoginCode> = yield call(
        api.signInWithTemporaryLoginCode,
        code,
        email
      );
      yield put(setAsyncOperationFinished('post.signin.code'));
      yield put(setJWT(jwt));
      signedInCallback?.();
    } catch (error) {
      yield put(setAsyncOperationErrored('post.signin.code', error));
    }
  }
}

export function* updateUser(
  user: Pick<User, 'firstName' | 'lastName' | 'dateOfBirth' | 'gender'>,
  api = API
) {
  try {
    yield put(setAsyncOperationInProgress('patch.user'));
    yield call(api.updateUser, user);
    yield put(setAsyncOperationFinished('patch.user'));
  } catch (error) {
    yield put(setAsyncOperationErrored('patch.user', error));
    throw error;
  }
}

export function* createUser(email: string, api = API) {
  try {
    yield put(setAsyncOperationInProgress('post.user'));
    const {
      data: { auth_token: jwt },
    }: AsyncReturnType<typeof API.createUser> = yield call(
      api.createUser,
      email
    );
    // setJWT calls fetchUser then somewhat we get 404
    yield put(setJWT(jwt));
    yield put(setAsyncOperationFinished('post.user'));
  } catch (error) {
    yield put(setAsyncOperationErrored('post.user', error));
    throw error;
  }
}

export function* submitQuestionnaire(
  api = API,
  isBrokerVersion = _isBrokerVersion
) {
  while (true) {
    const actionType: ActionType = 'SUBMIT_QUESTIONNAIRE';

    yield take(actionType);

    yield put(clearRemoteData('questionnaire'));
    yield put(setAsyncOperationInProgress('post.questionnaire'));

    const questionnaire: ReturnType<
      typeof getQuestionnaireWithoutUnreachableQuestions
    > = yield select(getQuestionnaireWithoutUnreachableQuestions);

    if (
      !questionnaire.personalInfo ||
      !questionnaire.personalInfo.name ||
      !questionnaire.personalInfo.gender ||
      !questionnaire.personalInfo.dateOfBirth ||
      !questionnaire.personalInfo.email
    ) {
      throw new Error('Trying to submit missing or incomplete questionnaire');
    }

    const remoteUser: ReturnType<typeof getUser> = yield select(getUser);

    if (remoteUser && remoteUser.email !== questionnaire.personalInfo.email) {
      // User signed in with an account that has a different email that the one used in the questionnaire
      // We invalidate the JWT associated with the user
      yield put(clearAllRemoteData());
      yield put(invalidateJWT());
    }

    const specify: ReturnType<typeof getSpecifyQuestionnaire> = yield select(
      getSpecifyQuestionnaire
    );

    const investigation: ReturnType<
      typeof getInvestigationQuestionnaire
    > = yield select(getInvestigationQuestionnaire);

    const tarrifAndDeductible: ReturnType<
      typeof getTariffAndDeductible
    > = yield select(getTariffAndDeductible);

    if (!tarrifAndDeductible) {
      throw new Error(
        'Trying to submit questionnaire without any tariff or deductible'
      );
    }

    const { tariff, deductible } = tarrifAndDeductible;

    const answersMetadata: ReturnType<typeof getAnswersMetadata> = yield select(
      getAnswersMetadata
    );

    const prices: ReturnType<typeof getPricesForTariff> = yield select(
      getPricesForTariff
    );

    const sickDayPayout: ReturnType<typeof getSickDayPayout> = yield select(
      getSickDayPayout
    );

    const questionOrder: ReturnType<
      typeof getQuestionOrderMetadata
    > = yield select(getQuestionOrderMetadata);
    const manipulatedQuestionOrderToPutPhoneNumberInDocs = [
      ...questionOrder,
      {
        type: 'general',
        sectionId: 'personalInfo',
        questionId: 'phoneNumber',
      } as Question,
    ];

    const riskLevel: ReturnType<typeof getRiskLevel> = yield select(
      getRiskLevel
    );

    const brokerInfo: ReturnType<typeof getBrokerInfo> = yield select(
      getBrokerInfo
    );

    const contribution: ReturnType<typeof getContributions> = yield select(
      getContributions
    );

    if (!prices) {
      throw new Error('Trying to submit questionnaire with missing prices');
    }

    const transformedQuestionnaire = transformQuestionnaireModel(
      questionnaire,
      specify,
      investigation,
      {
        tariff,
        deductible,
        addOns: Object.entries(prices.addOns).map(([k, v]) => ({
          addOn: k as AddOn,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          monthlyPrice: v!,
        })),
        tariffMonthlyPrice: prices.tariffPrice,
        totalMonthlyPrice: prices.totalPrice,
        riskFactorSurcharge: prices.riskSurcharge,
        statutorySurcharge: prices.statutorySurcharge,
        longTermCare: prices.longTermCare,
        ownContributions: contribution?.ownContributions,
        employerContributions: contribution?.employerContributions,
        sickDayPayout,
        riskLevel,
      },
      answersMetadata,
      manipulatedQuestionOrderToPutPhoneNumberInDocs,
      brokerInfo
    );

    try {
      const authenticated: ReturnType<typeof getIsAuthenticated> = yield select(
        getIsAuthenticated
      );

      const {
        personalInfo: {
          email,
          name: { firstName, lastName },
          dateOfBirth,
          gender,
        },
      } = questionnaire;

      if (!authenticated) {
        yield createUser(email, api);
      }

      if (!isBrokerVersion) {
        yield updateUser(
          {
            firstName,
            lastName,
            dateOfBirth,
            gender,
          },
          api
        );
      }

      const {
        data: returnedQuestionnaire,
      }: AsyncReturnType<typeof API.createQuestionnaire> = yield call(
        api.createQuestionnaire,
        transformedQuestionnaire
      );
      yield put(mergeQuestionnaire(returnedQuestionnaire));
      yield put(setAsyncOperationFinished('post.questionnaire'));
    } catch (error) {
      yield put(setAsyncOperationErrored('post.questionnaire', error));
    }
  }
}

export function* submitBankAccountDetail(
  api = API,
  isBrokerVersion = _isBrokerVersion,
  downloadBlob = _downloadBlob
) {
  while (true) {
    const actionType: ActionType = 'SUBMIT_BANK_ACCOUNT_DETAIL';
    const {
      bankAccountDetails,
    }: AssociatedActionFromType<typeof actionType> = yield take(actionType);
    const quote: ReturnType<typeof getQuote> = yield select(getQuote);
    const referralCode: string | undefined = yield select(getValidReferralCode);

    if (!quote) {
      throw new Error('Trying to submit bank account detail without a quote');
    }

    try {
      yield put(setAsyncOperationInProgress('post.bankaccountdetail'));
      const {
        data: { id: applicationId },
      }: AsyncReturnType<typeof API.submitBankAccountDetail> = yield call(
        api.submitBankAccountDetail,
        {
          bankAccountDetails,
          quoteId: quote.id,
          ...(referralCode ? { referralCode } : {}),
        }
      );

      /**
       * Clear referral code after successful checkout
       */
      if (referralCode) {
        yield put(updateReferralInfo({ referralCode: undefined }));
      }

      trackConversion({ vertical: 'private-health' });

      if (isBrokerVersion) {
        while (true) {
          const {
            data: { downloadUrl },
          }: AsyncReturnType<
            typeof API.downloadApplicationDocuments
          > = yield call(api.downloadApplicationDocuments, applicationId);

          // Wait for 1 second to make the next call
          yield call(sleep, 1000);

          if (downloadUrl !== null) {
            const { data }: AxiosResponse<any> = yield call(
              Axios.get,
              downloadUrl,
              {
                responseType: 'blob',
              }
            );
            yield call(downloadBlob, data, 'Application documents.pdf');
            break;
          }
        }
      }

      yield put(setAsyncOperationFinished('post.bankaccountdetail'));

      /** Automatically redirect user to the Feather App after purchase */
      if (isBrokerVersion) {
        yield call(push, path.confirmation);
      } else {
        const {
          data: { auth_token: transition_token },
        } = yield call(api.getTransitionToken);
        window.location.href = `${app.myPolicies}?token=${transition_token}&signupSuccess=privateHealth`;
      }
    } catch (error) {
      yield put(setAsyncOperationErrored('post.bankaccountdetail', error));
    }
  }
}

function* fetchQuote() {
  while (true) {
    const actionType: ActionType = 'FETCH_QUOTE';
    const {
      questionnaireId,
    }: AssociatedActionFromType<typeof actionType> = yield take(actionType);

    try {
      yield put(setAsyncOperationInProgress('get.quote'));
      const { data: quote }: AsyncReturnType<typeof API.getQuote> = yield call(
        API.getQuote,
        questionnaireId
      );
      yield put(setAsyncOperationFinished('get.quote'));
      yield put(
        mergeQuote(quote[0].tariffInfo, quote[0].id, quote[0].userInfo)
      );
    } catch (error) {
      yield put(setAsyncOperationErrored('get.quote', error));
    }
  }
}

function* fetchUser() {
  while (true) {
    const actionType: ActionType = 'FETCH_USER';

    yield take(actionType);

    const userId: ReturnType<typeof getUserId> = yield select(getUserId);

    if (!userId) {
      throw new Error('Trying to fetch a user without a user id');
    }

    try {
      yield put(setAsyncOperationInProgress('get.user'));
      const { data: user }: AsyncReturnType<typeof API.getUser> = yield call(
        API.getUser,
        userId
      );
      yield put(setAsyncOperationFinished('get.user'));
      yield put(mergeUser(user));
    } catch (error) {
      yield put(setAsyncOperationErrored('get.user', error));
    }
  }
}

export function* signInWithJWT(isBrokerVersion = _isBrokerVersion) {
  while (true) {
    const actionType: ActionType = 'SIGN_IN';
    const { jwt }: AssociatedActionFromType<typeof actionType> = yield take(
      actionType
    );

    if (!isBrokerVersion) {
      yield call(setTokenCheckTimer, jwt);
    }
    yield put(fetchUserAction());
  }
}

export function* answeredSpecifyQuestion() {
  while (true) {
    const actionType: ActionType = 'ANSWERED_SPECIFY_QUESTION';
    const {
      question,
    }: AssociatedActionFromType<typeof actionType> = yield take(actionType);

    const nextQuestion: ReturnType<typeof getQuestionAfter> = yield select(
      getQuestionAfter,
      question
    );

    if (nextQuestion === undefined) {
      // This is the last question
      yield call(push, path.legalDocuments);
      continue;
    }

    yield call(push, pathForQuestion(nextQuestion));
  }
}

export function* answeredQuestion() {
  while (true) {
    const actionType: ActionType = 'ANSWERED_QUESTION';
    const action: AssociatedActionFromType<typeof actionType> = yield take(
      actionType
    );

    const { question, answer, label } = action;
    const { sectionId, questionId } = question;

    yield put(storeAnsweredQuestion(question, answer, label));

    if (questionId === 'phoneNumber') {
      yield put(submitQnrAction());
      continue;
    }

    const blockerId: ReturnType<typeof getBlocker> = yield select(
      getBlocker,
      sectionId,
      questionId
    );

    if (blockerId) {
      yield call(push, pathForBlockerId(blockerId));
      continue;
    }

    const nextQuestion: ReturnType<typeof getQuestionAfter> = yield select(
      getQuestionAfter,
      question
    );

    if (nextQuestion === undefined) {
      // This is the last question
      yield call(push, path.legalDocuments);
      continue;
    }

    yield call(push, pathForQuestion(nextQuestion));
  }
}

export function* answeredInvestigation() {
  while (true) {
    const actionType: ActionType = 'ANSWERED_INVESTIGATION';
    const {
      question,
    }: AssociatedActionFromType<typeof actionType> = yield take(actionType);

    const nextQuestion: ReturnType<typeof getQuestionAfter> = yield select(
      getQuestionAfter,
      question
    );

    if (nextQuestion === undefined) {
      // This is the last question
      yield call(push, path.legalDocuments);
      continue;
    }

    yield call(push, pathForQuestion(nextQuestion));
  }
}

export default function* root() {
  yield all([
    beginNewApplication(),
    answeredQuestion(),
    answeredInvestigation(),
    submitQuestionnaire(),
    answeredSpecifyQuestion(),
    submitBankAccountDetail(),
    fetchQuote(),
    fetchUser(),
    verifyPhoneNumber(),
    confirmPhoneNumber(),
    signInWithTemporaryLoginCode(),
    signInWithJWT(),
    verifyReferralCode(),
    submitApplicationSaga(),
  ]);
}
