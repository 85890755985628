import React, { useState } from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import Form from 'components/form';
import connectedAnswer from 'pages/questions/connectedAnswer';
import { GeneralQuestionnaireModel } from 'models/questions/general';
import { ViewProps } from 'pages/questions/connectedAnswer';
import { connectedRedirect } from 'components/connectedRedirect';

import styles from './style.module.scss';
import { LabelType } from 'actions/type';

export const WrappedForm = <
  S extends keyof GeneralQuestionnaireModel,
  Q extends keyof Required<GeneralQuestionnaireModel>[S]
>({
  validator,
  children: Children,
  sectionId,
  questionId,
  label,
}: {
  validator: (input?: Required<GeneralQuestionnaireModel>[S][Q]) => boolean;
  children: React.ComponentType<{
    setValue: (answer: Required<GeneralQuestionnaireModel>[S][Q]) => void;
    value?: Required<GeneralQuestionnaireModel>[S][Q];
  }>;
  questionId: Q;
  sectionId: S;
  label?: LabelType;
}) => {
  const View = ({ answer, onAnswerQuestion }: ViewProps<S, Q>) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(answer);
    const valid = validator(value);

    return (
      <Form
        valid={valid}
        value={value}
        onAnswerQuestion={() => onAnswerQuestion(value!, label)}
      >
        <Children setValue={setValue} value={value} />
        <button
          data-cy="questionnaire-continue"
          className={`p-btn--primary mt24 ${styles.button}`}
          type="submit"
          disabled={!valid}
        >
          {t('questions.general.continue.action', 'Continue')}
        </button>
      </Form>
    );
  };

  const ConnectedView = connectedRedirect(
    { type: 'general', sectionId, questionId: questionId as any },
    connectedAnswer(sectionId, questionId)(View)
  );
  return <ConnectedView />;
};
