import React, { useState } from 'react';
import { cerris, website } from '@getpopsure/private-constants';
import { useTranslation } from 'react-i18next';
import { Trans } from '@getpopsure/i18n-react';

import Center from 'components/center';
import {
  Section,
  sectionFriendlyName as sectionFriendlyNameFunction,
} from 'models/questions/general';
import { isBrokerVersion } from 'utils/isBrokerVersion';

import { ViewProps } from '..';
import styles from './style.module.scss';

const SectionView = ({ Image, title }: { Image: any; title: string }) => (
  <div className={`mt16 ${styles['section-chip']}`}>
    <Image />
    <div
      className={`p-p--small ta-center tc-grey-600 mt8 ${styles['section-chip-title']}`}
    >
      {title}
    </div>
  </div>
);

export default ({ firstQuestionUrl, history }: ViewProps) => {
  const { t } = useTranslation();
  const PersonalInfoImage = () => (
    <div className={styles['personal-info-icon']} />
  );
  const FinancialHistoryImage = () => (
    <div className={styles['financial-info-icon']} />
  );
  const InsuranceHistoryImage = () => (
    <div className={styles['insurance-history-icon']} />
  );
  const MedicalHistoryImage = () => (
    <div className={styles['medical-history-icon']} />
  );

  const sectionFriendlyName = sectionFriendlyNameFunction(t);

  const mapping: {
    [K in Section['id']]: { title: string; Image: React.ReactNode };
  } = {
    personalInfo: {
      title: sectionFriendlyName.personalInfo,
      Image: PersonalInfoImage,
    },
    financialHistory: {
      title: sectionFriendlyName.financialHistory,
      Image: FinancialHistoryImage,
    },
    insuranceHistory: {
      title: sectionFriendlyName.insuranceHistory,
      Image: InsuranceHistoryImage,
    },
    medicalHistory: {
      title: sectionFriendlyName.medicalHistory,
      Image: MedicalHistoryImage,
    },
  };

  /**
   * Get the info from the pre-signup using URL params
   * the tariff & deductible params are being fetched
   * on src/components/withTariff/index.tsx
   */
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  return (
    <>
      <Center>
        <div data-cy="main-page">
          <h1 className="p-h1 wmx8">
            {t('page.welcome.title', 'Welcome! Your application starts here')}
          </h1>
          <p className={`p-p wmx8 ${styles.description}`}>
            {t(
              'page.welcome.subtitle',
              'No medical exams, 100% digital signup'
            )}{' '}
            <span role="img" aria-label="">
              🎉
            </span>
          </p>
          <div className={styles['section-container']}>
            {Object.values(mapping).map(({ title, Image }) => (
              <SectionView key={title} Image={Image} title={title} />
            ))}
          </div>
          <p className={`p-p wmx7 tc-grey-600 ${styles.description}`}>
            {t(
              'page.welcome.legal.description',
              'The following questions are asked by the insurer (Hallesche Krankenversicherung a.G.) to determine the details of your coverage.'
            )}
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              history.push(firstQuestionUrl);
            }}
          >
            <div className="p-label-container mt24" data-cy="legal-accept-tnc">
              <input
                id="terms-checkbox"
                className="p-checkbox"
                type="checkbox"
                checked={agreedToTerms}
                onChange={() => {
                  setAgreedToTerms(!agreedToTerms);
                }}
                onClick={() => {
                  setAgreedToTerms(!agreedToTerms);
                }}
              />
              <label
                data-cy="privacy-policy-agree"
                style={{ minHeight: 0, display: 'block' }} // TODO: Issue with dirtyswan here
                className="p-label"
                htmlFor="terms-checkbox"
              >
                <Trans i18nKey="page.welcome.legal.tnc">
                  I have read and agree to the{' '}
                  <a
                    className="p-a"
                    href={
                      isBrokerVersion
                        ? cerris.datenschutzerklaerung
                        : website.privacyPolicy
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy
                  </a>{' '}
                  and{' '}
                  <a
                    className="p-a"
                    href={
                      isBrokerVersion
                        ? cerris.termsOfService
                        : website.termsAndConditions
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    T&Cs.
                  </a>
                </Trans>
              </label>
            </div>
            <button
              disabled={!agreedToTerms}
              data-cy="questionnaire-start"
              className="p-btn--primary wmn3 mt24"
            >
              {t('page.welcome.start', 'Let’s go!')}
            </button>
          </form>
        </div>
      </Center>
      {isBrokerVersion && (
        <a
          className="ta-center d-block w100 p-a"
          target="_blank"
          rel="noopener noreferrer"
          href={cerris.impressum}
        >
          {t('page.welcome.broker.impressum', 'Impressum')}
        </a>
      )}
    </>
  );
};
