import { Address, Gender, Country } from '@popsure/public-models';
import { RiskLevel } from './price';
import { Tariff } from '@getpopsure/private-health-insurance-pricing-engine';
import { Section } from './questions/general';

export type MaritalStatus = 'MARRIED' | 'SINGLE' | 'WIDOWED' | 'DIVORCED';

export interface File {
  url: string;
  name: string;
}

export interface Name {
  firstName: string;
  lastName: string;
}

export interface Quote {
  tariff: Tariff;
  deductible: number;
  tariffMonthlyPrice: number;
  totalMonthlyPrice: number;
  statutorySurcharge: number;
  riskFactorSurcharge: number;
  ownContributions?: number;
  employerContributions?: number;
  longTermCare: number;
  riskLevel: { level: RiskLevel; reason?: string };
  addOns: { addOn: string; monthlyPrice: number }[];
  fullTariffName: string;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  gender: Gender;
}

export interface BankAccountDetails {
  iban: string;
  bic?: string;
}

export function isName(object: any): object is Name {
  const castedObject: Name = object as Name;
  return (
    castedObject.firstName !== undefined && castedObject.lastName !== undefined
  );
}

export function isAddress(object: any): object is Address {
  const castedObject: Address = object as Address;
  return (
    castedObject.street !== undefined &&
    castedObject.city !== undefined &&
    castedObject.country !== undefined &&
    castedObject.houseNumber !== undefined &&
    castedObject.postcode !== undefined
  );
}

function isCountry(object: any): object is Country {
  const castedObject: Country = object as Country;
  return castedObject.code !== undefined && castedObject.name !== undefined;
}

export function isArrayOfCountries(object: any): object is Country[] {
  if (Array.isArray(object) && object.length > 0) {
    return object.find((c) => isCountry(c) === false) === undefined;
  }
  return false;
}

const currencyAnswerIds: Section['question']['id'][] = [
  'income',
  'incomeEstimate',
  'incomeExpectations',
  'incomeStatement',
  'futureIncome',
  'monthlyIncomeBeforeTaxWhenEmployed',
];

export const isAnswerCurrency = (id: Section['question']['id']): boolean =>
  currencyAnswerIds.includes(id);
export interface UploadedFile {
  id: string;
  name: string;
  type: string;
  progress: number;
  error?: string;
  token?: string;
  uploadUrl: string;
  downloadUrl?: string;
  previewUrl?: string;
}

function isUploadedFile(object: any) {
  const castedObject: UploadedFile = object as UploadedFile;
  return (
    castedObject.id !== undefined &&
    castedObject.name !== undefined &&
    castedObject.type !== undefined
  );
}

export function isArrayOfUploadedFiles(object: any): object is UploadedFile[] {
  if (Array.isArray(object) && object.length > 0) {
    return isUploadedFile(object[0]);
  } else {
    return false;
  }
}
