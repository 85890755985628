import React from 'react';
import { Trans, useTranslation } from '@getpopsure/i18n-react';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import day from 'dayjs';

import styles from 'pages/sepaMandate/component/style.module.scss';
import healthInsuranceIcon from 'features/privatePayments/assets/health-insurance-icon.svg';
import warningIcon from 'features/privatePayments/assets/warning-icon.svg';

import {
  AddOn,
  addOnNameMapping,
  tariffLongNameMapping,
} from '@getpopsure/private-health-insurance-pricing-engine';
import {
  ReferrerCodeVerify,
  useReferralEngineFlag,
} from 'features/referralEngine';
import { Quote, User } from 'models';
import { GeneralQuestionnaireModel } from 'models/questions/general';
import { preExistingConditionTitle } from 'components/liveQuote/components/util';
import { getPersonalInfoMapping } from 'features/privatePayments/utils';
import { Button } from '@popsure/dirty-swan';
import AnimateHeight from 'react-animate-height';

interface SubmitApplicationViewProps {
  error?: Error;
  quote: Quote;
  user: User;
  questionnaire?: { id: string; answers: GeneralQuestionnaireModel };
  onSubmitApplication(): void;
  loading: boolean;
}

export const SubmitApplicationView = (props: SubmitApplicationViewProps) => {
  const { t } = useTranslation();
  const withReferralEngine = useReferralEngineFlag();

  const {
    quote,
    user,
    questionnaire,
    onSubmitApplication,
    loading,
    error,
  } = props;
  const {
    ownContributions,
    employerContributions,
    tariffMonthlyPrice,
    totalMonthlyPrice,
    longTermCare,
    addOns,
    riskFactorSurcharge,
    statutorySurcharge,
    riskLevel,
  } = quote;

  const { firstName, lastName, dateOfBirth, email } = user;

  if (
    !questionnaire ||
    !questionnaire.answers ||
    !questionnaire.answers.personalInfo ||
    !questionnaire.answers.personalInfo.phoneNumber
  ) {
    throw new Error(`Incomplete questionnaire: ${questionnaire?.id}`);
  }

  const {
    answers: {
      personalInfo: { address, phoneNumber, coverageStartDate },
    },
  } = questionnaire;

  const policyName = tariffLongNameMapping[quote.tariff];

  const personalInfoMapping = getPersonalInfoMapping({
    t,
    firstName,
    lastName,
    dateOfBirth,
    email,
    address,
    phoneNumber,
  });

  const handleSubmitApplication = () => onSubmitApplication();

  return (
    <div className="p-body">
      <img
        className={`d-block mt16 ${styles.logo}`}
        src={healthInsuranceIcon}
        alt=""
      />
      <div className="p-h4 tc-primary-500 ta-center mt16">
        {t('page.submitApplication.step.title', 'Step 3')}
      </div>
      <h1 className="p-h1 ta-center ws100">
        {t('page.submitApplication.title', 'Review and purchase')}
      </h1>
      {riskLevel.level === 'X' && (
        <div
          className={`w100 mt32 d-flex ai-start ${styles['policy-information']}`}
        >
          <img src={warningIcon} alt="" className="mr8" />
          <div className="w100">
            <h4 className="p-h4">
              {t(
                'page.submitApplication.medicalhistory.warning.title',
                'Your final price and coverage might change'
              )}
            </h4>
            <p className="p-p mt8">
              {t(
                'page.submitApplication.medicalhistory.warning.description',
                'Because of pre-existing conditions, a coverage exclusion or an increase in your monthly payment might be needed. In the next few days, you’ll receive step-by-step guidance from the Feather Team if that’s the case.'
              )}
            </p>
          </div>
        </div>
      )}
      <div className={`mt32 ${styles['informations-container']}`}>
        <div className="w100">
          <div className={styles['policy-information']}>
            <h2 className="p-h2">
              {t(
                'page.submitApplication.policyinfo.title',
                'Policy information'
              )}
            </h2>
            <div className={`mt24 ${styles.cell}`}>
              <div>
                {t(
                  'page.submitApplication.policyinfo.name',
                  '{{policyName}} Policy',
                  {
                    policyName,
                  }
                )}
              </div>
              <div className={`ta-right ${styles.value}`}>
                {englishFormattedEuroCurrency(
                  tariffMonthlyPrice + longTermCare + (statutorySurcharge ?? 0)
                )}
              </div>
            </div>
            <div className="p-p tc-grey-500 mt8">
              {t(
                'page.submitApplication.deductible',
                '{{deductible}} annual deductible',
                {
                  deductible: englishFormattedEuroCurrency(quote.deductible, 0),
                }
              )}
            </div>

            {riskLevel.level !== 'RG0' && (
              <>
                <h4 className="p-h4 mt24">
                  {t(
                    'page.submitApplication.preexistingconditions.title',
                    'Pre-existing conditions'
                  )}
                </h4>
                <div className={`mt16 ${styles.cell}`}>
                  <div>{preExistingConditionTitle(riskLevel)}</div>
                  <div className={`ta-right ${styles.value}`}>
                    {riskLevel.level === 'X'
                      ? '???'
                      : englishFormattedEuroCurrency(riskFactorSurcharge)}
                  </div>
                </div>
              </>
            )}

            {addOns.length > 0 && (
              <>
                <h4 className="p-h4 mt24">Add ons</h4>
                {addOns.map(({ addOn, monthlyPrice }) => (
                  <div key={addOn} className={`mt16 ${styles.cell}`}>
                    <div>{addOnNameMapping[addOn as AddOn]}</div>
                    <div className={styles.value}>
                      {englishFormattedEuroCurrency(monthlyPrice)}
                    </div>
                  </div>
                ))}
              </>
            )}
            <hr className={`mt24 ${styles.separator}`} />
            <div className={`mt16 ${styles.cell}`}>
              <div className={styles.total}>
                {t(
                  'page.submitApplication.monthlytotal.title',
                  'Your monthly total'
                )}
              </div>
              <div className={styles.total}>
                {englishFormattedEuroCurrency(
                  ownContributions ?? totalMonthlyPrice
                )}
              </div>
            </div>
            {employerContributions && (
              <div className={`mt16 ${styles.cell}`}>
                <div>
                  {t(
                    'page.submitApplication.monthlytotal.employercontribution.title',
                    'Your employer will pay'
                  )}
                </div>
                <div className={styles.value}>
                  {englishFormattedEuroCurrency(employerContributions)}
                </div>
              </div>
            )}
            {withReferralEngine && <ReferrerCodeVerify />}
          </div>
        </div>
        <div className="w100">
          <div className={styles['personal-information']}>
            <h2 className="p-h2">
              {t(
                'page.submitApplication.personalinfo.title',
                'Personal information'
              )}
            </h2>
            <div className="mt8">
              {personalInfoMapping.map(({ key, value }) => (
                <div key={key} className="mt16 d-flex jc-between">
                  <div className="tc-grey-500 mr8">{key}</div>
                  <div className="tc-grey-900 ta-right">{value}</div>
                </div>
              ))}
            </div>
          </div>
          <div className={`mt16 ${styles['personal-information']}`}>
            <h2 className="p-h2">
              {t('page.submitApplication.payment.title', 'Payment')}
            </h2>
            <p className="p-p tc-grey-700 mt8">
              {t(
                'page.submitApplication.payment.description',
                '⏱ It will be set up once we confirm your coverage'
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex jc-center w100 mt32 mb32">
        <Button
          className="w100 wmx4"
          onClick={handleSubmitApplication}
          buttonTitle={t('page.submitApplication.submit.button', 'Buy now')}
          loading={loading}
        />
      </div>

      <AnimateHeight duration={300} height={error ? 'auto' : 0}>
        <div className="d-flex jc-center w100 mb32">
          <p className="p-notice--danger w100 wmx8">
            {error && `Error: ${error.message}`}
          </p>
        </div>
      </AnimateHeight>
      <div className="w100">
        <Trans i18nKey="page.submitApplication.terms.description">
          <p className="p-p tc-grey-900 p-p--small mb72 w100 wmx8 mx-auto ta-center">
            Your cover will begin on{' '}
            {{
              date: day(coverageStartDate).format('DD MMM YYYY'),
            }}{' '}
            if you click “Buy now“ but only if you have received the official
            confirmation of insurance coverage.{' '}
            <b className="fw-bold">
              A withdrawal of the contract can be declared within 14 days after
              receiving the official confirmation of insurance coverage.
            </b>
          </p>
        </Trans>
      </div>
    </div>
  );
};
