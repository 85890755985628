import React from 'react';
import { BottomOrRegularModal, Button } from '@popsure/dirty-swan';
import { ReferrerCodeVerifyModal } from 'features/referralEngine/components';
import { useTranslation } from '@getpopsure/i18n-react';

interface ReferrerCodeVerifyViewProps {
  openModal: boolean;
  validReferrerCode?: string;
  onRemoveValidReferrerCode(): void;
  onSubmitValidReferrerCode(code: string): void;
  handleCloseModal(): void;
  handleOpenModal(): void;
  loading?: boolean;
  error?: string;
}

export const ReferrerCodeVerifyView = (props: ReferrerCodeVerifyViewProps) => {
  const {
    onSubmitValidReferrerCode,
    openModal,
    handleCloseModal,
    handleOpenModal,
    validReferrerCode,
    onRemoveValidReferrerCode,
    loading,
    error,
  } = props;
  const { t } = useTranslation();

  if (validReferrerCode)
    return (
      <div className="d-flex ai-center jc-center mt24">
        <p className="p-p mr8">
          {t(
            'referralengine.verify.confirmation.title',
            '🎉 Referral code applied'
          )}
        </p>
        <Button
          buttonTitle={t('referralengine.verify.remove.button.title', 'Remove')}
          buttonType="secondary"
          onClick={onRemoveValidReferrerCode}
        />
      </div>
    );

  return (
    <div>
      <Button
        className="w100 mt24"
        buttonTitle="Add referral code"
        buttonType="secondary"
        onClick={handleOpenModal}
      />
      {openModal && (
        <BottomOrRegularModal
          title={t(
            'referralengine.verify.add.button.title',
            'Add a referral code'
          )}
          isOpen
          onClose={handleCloseModal}
        >
          <ReferrerCodeVerifyModal
            onSubmitCode={onSubmitValidReferrerCode}
            loading={loading}
            error={error}
          />
        </BottomOrRegularModal>
      )}
    </div>
  );
};
