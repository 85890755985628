import classNames from 'classnames';
import React, { useState } from 'react';
import { cerris } from '@getpopsure/private-constants';
import { useTranslation } from '@getpopsure/i18n-react';
import { Trans } from '@getpopsure/i18n-react';

import styles from './style.module.scss';
import { acceptCookies, hasAcceptedCookies } from 'utils/tracking';
import { initializeTracker } from 'components/tracker';

export default () => {
  const [display, setDisplay] = useState(hasAcceptedCookies() === undefined);
  const [isHidding, setIsHidding] = useState(false);
  const { t } = useTranslation();

  const handleCookies = (accepted: boolean) => {
    setIsHidding(true);
    acceptCookies(accepted);
    initializeTracker();
    setTimeout(() => {
      setDisplay(false);
      setIsHidding(false);
    }, 300);
  };

  if (display === false) {
    return <></>;
  }

  return (
    <div
      className={`${classNames(styles.container, {
        [styles['container--hiding']]: isHidding,
      })} wmx10`}
    >
      <p className="p-p">
        <Trans i18nKey="component.cookie.legal.text">
          You are now on the website of Cerris GmbH. Cerris GmbH is an IT
          service provider for Hallesche Krankenversicherung a.G. In order to
          optimize our website for you and to be able to improve it
          continuously, we use cookies. More information on consent (incl.
          revocation) can be found in our{' '}
          <a className="p-a" href={cerris.datenschutzerklaerung}>
            privacy policy
          </a>
          . By clicking on the link{' '}
          <span
            className="p-a"
            data-cy="cookies-banner-refuse"
            onClick={() => {
              handleCookies(false);
            }}
          >
            “Refuse cookies”
          </span>{' '}
          you can revoke your consent.
        </Trans>
      </p>
      <button
        onClick={() => {
          handleCookies(true);
        }}
        className="p-btn--primary wmn2"
        data-cy="cookies-banner-accept"
      >
        {t('component.cookie.confirm.action', 'Okay')}
      </button>
    </div>
  );
};
