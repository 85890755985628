import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import { InfoHint, InfoHintContainer } from 'components/infoHint';
import { generateRadioInput } from 'pages/questions/generateInput';
import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { healthInsuranceOptionMapping } from 'models/questions/general/insuranceHistory';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.currentHealthInsurance?.(t)}
      </h1>
      <WrappedForm
        validator={nonEmptyValidator}
        sectionId="insuranceHistory"
        questionId="currentHealthInsurance"
        label={healthInsuranceOptionMapping(t)}
      >
        {({ value, setValue }) => (
          <InfoHintContainer className="mt8">
            {generateRadioInput(
              'currentHealthInsurance',
              value,
              healthInsuranceOptionMapping(t),
              setValue
            )}
            {value && value === 'GERMAN_PUBLIC_INSURANCE' && (
              <InfoHint
                title={t(
                  'questions.currenthealthinsurance.hint.germanpublic.title',
                  'I have public insurance in Germany'
                )}
              >
                {t(
                  'questions.currenthealthinsurance.hint.germanpublic.description',
                  'If you are currently insured publicly in Germany then you need to cancel your policy with your provider before the start of your private insurance. You cannot be insured by both Public and Private Health Insurance at the same time. Be aware that there is a cancellation period of about 3-months on Public Health Insurance in Germany. Please be aware of this as you continue your application.'
                )}
              </InfoHint>
            )}
            {value && value === 'GERMAN_CIVIL_SERVANT_INSURANCE' && (
              <InfoHint
                title={t(
                  'questions.currenthealthinsurance.hint.civilservant.title',
                  'What is health insurance for civil servant or soldiers?'
                )}
              >
                {t(
                  'questions.currenthealthinsurance.hint.civilservant.description',
                  'Civil servants commonly use a special type of insurance called "Restkostenversicherung" or "Beihilfeversicherung". Some civil servants such as soldiers can also receive free medical care ("freie Heilfürsorge"). Civil servants are all government employees including police officers, firefighters, judges and soldiers.'
                )}
              </InfoHint>
            )}
          </InfoHintContainer>
        )}
      </WrappedForm>
    </Center>
  );
};
