import { MaritalStatus, Name } from 'models';
import { Gender, Address, Country } from '@popsure/public-models';
import { TranslationFn } from '@getpopsure/i18n-react';
import { AppState } from 'reducers';
import { addQuestionAfter } from 'models/questions/general';

import { containsEEACountry } from 'utils/containsEEACountry';

type ResidencePermitType =
  | 'LIMITED_RESIDENCE_PERMIT'
  | 'UNLIMITED_RESIDENCE_PERMIT';

export type ResidencePermitStatus = 'HAVE' | 'APPLIED' | 'WILL_APPLY';

export const residencePermitTypeMapping = (
  t: TranslationFn
): {
  [K in ResidencePermitType]: string;
} => ({
  LIMITED_RESIDENCE_PERMIT: t(
    'questions.residencepermittype.mapping.limited',
    'Limited residence permit'
  ),
  UNLIMITED_RESIDENCE_PERMIT: t(
    'questions.residencepermittype.mapping.unlimited',
    'Unlimited residence permit'
  ),
});

export type PersonalInfoQuestion =
  | {
      id: 'name';
      answer: Name;
    }
  | {
      id: 'email';
      answer: string;
    }
  | {
      id: 'phoneNumber';
      answer: string;
    }
  | {
      id: 'dateOfBirth';
      answer: string;
    }
  | {
      id: 'gender';
      answer: Gender;
    }
  | {
      id: 'address';
      answer: Address;
    }
  | {
      id: 'maritalStatus';
      answer: MaritalStatus;
    }
  | {
      id: 'coverageStartDate';
      answer: string;
    }
  | {
      id: 'currentlyLivingInGermany';
      answer: boolean;
    }
  | {
      id: 'nationality';
      answer: Country[];
    }
  | {
      id: 'residencePermitStatus';
      answer: ResidencePermitStatus;
    }
  | {
      id: 'whatTypeOfResidencePermit';
      answer: ResidencePermitType;
    }
  | {
      id: 'passportNumber';
      answer: string;
    }
  | {
      id: 'passportExpiry';
      answer: string;
    }
  | {
      id: 'personalInfoReview';
      answer: boolean;
    };

export const personalInfoQuestionOrder = (
  state: AppState
): PersonalInfoQuestion['id'][] => {
  const nationality = state.questionnaire?.personalInfo?.nationality;
  const isEEANational = nationality && containsEEACountry(nationality);

  let order: PersonalInfoQuestion['id'][] = [
    'name',
    'email',
    'dateOfBirth',
    'gender',
    'address',
    'maritalStatus',
    'coverageStartDate',
    'currentlyLivingInGermany',
    'nationality',
    'passportNumber',
    'passportExpiry',
    'personalInfoReview',
  ];

  if (isEEANational === false) {
    order = addQuestionAfter({
      questionsToAdd: ['residencePermitStatus', 'whatTypeOfResidencePermit'],
      afterQuestion: 'nationality',
      order,
    }) as PersonalInfoQuestion['id'][];
  }

  return order;
};
