import { APIResponseError } from 'models/error';
import { useEffect, useState } from 'react';
import {
  createQuestionnaireUpload,
  uploadFile as requestFileUpload,
} from 'network/api';
import { getFileType } from 'utils/getFileType';
import { getPreviewUrl } from 'utils/getPreviewUrl';
import { formatErrorMessageFromError } from 'models/error';
import { UploadedFile } from 'models';

export const useUploadFiles = ({
  initialValue,
  questionId,
}: {
  initialValue: UploadedFile[];
  questionId: string;
}): {
  uploadFile: (files: File[]) => void;
  uploadedFiles: UploadedFile[];
  loading: boolean;
  removeFile: (fileId: string) => void;
  isSubmitValid: boolean;
} => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>(
    initialValue
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const uploadComplete: boolean =
      uploadedFiles.filter(({ progress, error }) => progress === 100 || error)
        .length === uploadedFiles.length;
    if (uploadComplete) {
      setLoading(false);
    }
  }, [uploadedFiles]);

  const uploadFile = (files: File[]) => {
    setLoading(true);

    files.forEach(async (file: File) => {
      let fileId: string | null = null;

      try {
        const {
          data: { id, uploadUrl, token, downloadUrl },
        } = await createQuestionnaireUpload(file, questionId);

        fileId = id;

        await requestFileUpload(file, uploadUrl, (p) => {
          const fileProgress = (p.loaded / p.total) * 100;
          const fileType = getFileType(file.name);
          const newUploadedFile: UploadedFile = {
            id: fileId ?? '',
            type: fileType,
            progress: fileProgress,
            name: file.name,
            token,
            uploadUrl,
            downloadUrl,
            previewUrl: getPreviewUrl(uploadUrl, fileType),
          };
          setUploadedFiles((prevState: UploadedFile[]) => {
            const filteredFiles = prevState.filter(
              ({ id: fileIdToCheckAgainst, error }) =>
                fileIdToCheckAgainst !== fileId || error
            );
            return [...filteredFiles, { ...newUploadedFile }];
          });
        });
      } catch (error) {
        const erroredFile: UploadedFile = {
          id: fileId ?? '',
          type: getFileType(file.name),
          name: file.name,
          progress: 0,
          uploadUrl: '',
          error: formatErrorMessageFromError(error as APIResponseError),
        };
        setUploadedFiles((prevState: UploadedFile[]) => {
          const filteredFiles = prevState.filter(({ id }) => id !== fileId);
          return [...filteredFiles, { ...erroredFile }];
        });
      }
    });
  };

  const removeFile = (fileId: string) => {
    const filteredUploadedFiles = uploadedFiles.filter(
      ({ id }) => id !== fileId
    );
    setUploadedFiles([...filteredUploadedFiles]);
  };

  const isSubmitValid =
    uploadedFiles.length > 0 &&
    uploadedFiles.filter(({ token, progress }) => token && progress === 100)
      .length === uploadedFiles.length;

  return { uploadFile, uploadedFiles, loading, removeFile, isSubmitValid };
};
