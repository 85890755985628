import React, { useState } from 'react';
import { Input } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';
import { Trans } from '@getpopsure/i18n-react';

import styles from './style.module.scss';
import Form from 'components/form';
import Center from 'components/center';
import { InfoHint, InfoHintContainer } from 'components/infoHint';
import connectedAnswer from 'pages/questions/connectedAnswer';
import { nonEmptyStringValidator } from 'validators';
import { ViewProps } from 'pages/questions/connectedAnswer';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default connectedAnswer(
  'financialHistory',
  'germanTaxId'
)(
  ({
    answer,
    onAnswerQuestion,
  }: ViewProps<'financialHistory', 'germanTaxId'>) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(answer);
    const valid = nonEmptyStringValidator(value);

    return (
      <Center>
        <h1 className="p-h1">
          {generalQuestionsTitleMapping.germanTaxId?.(t)}
        </h1>
        <InfoHintContainer className="mt8">
          <Form valid={valid} value={value} onAnswerQuestion={onAnswerQuestion}>
            <div>
              <Input
                data-cy="questionnaire-tax-id"
                className="w100 wmx5"
                placeholder={t(
                  'questions.germantaxid.input.placeholder',
                  'German Tax-ID'
                )}
                type="text"
                id="germanTaxId"
                name="germanTaxId"
                value={value}
                onChange={(v) => {
                  setValue(v.target.value);
                }}
                required={true}
              />
            </div>

            <div className="mt24">
              <button
                data-cy="questionnaire-continue"
                className="p-btn--primary wmn3"
                type="submit"
                disabled={!valid}
              >
                {t('questions.germantaxid.continue.action', 'Continue')}
              </button>
              <button
                data-cy="questionnaire-skip"
                className={`p-btn--secondary wmn2 ${styles.skip}`}
                type="button"
                onClick={() => onAnswerQuestion('')}
              >
                {t('questions.germantaxid.skip.action', 'Skip')}
              </button>
            </div>
          </Form>
          <InfoHint
            title={t(
              'questions.germantaxid.hint.title',
              '"Why do you need this information?"'
            )}
          >
            <Trans i18nKey="questions.germantaxid.hint.description">
              Health insurance cost is partially tax deductible. To make it easy
              to claim those tax deductions we automatically pass on all
              necessary data to the tax authorities. This includes the amounts
              of the tax reducing health and compulsory nursing care premiums,
              personal data and data about any other persons insured (e.g.
              children, spouse). If you do not provide your tax ID, we are
              entitled to obtain it from the federal central tax office.
            </Trans>
          </InfoHint>
        </InfoHintContainer>
      </Center>
    );
  }
);
