import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';
import { Trans } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { InfoHint, InfoHintContainer } from 'components/infoHint';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { YesNoQuestion } from 'pages/questions/generateInput';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.currentlyLivingInGermany?.(t)}
      </h1>
      <InfoHintContainer className="mt8">
        <WrappedForm
          validator={nonEmptyValidator}
          sectionId="personalInfo"
          questionId="currentlyLivingInGermany"
        >
          {({ value, setValue }) => (
            <div className="mt8">
              <YesNoQuestion
                name="hasLinkedInProfile"
                value={value}
                setValue={setValue}
              />
            </div>
          )}
        </WrappedForm>
        <InfoHint
          title={t(
            'questions.currentlylivingingermany.hint.title',
            'Established residence in Germany'
          )}
        >
          <Trans i18nKey="questions.currentlylivingingermany.hint.description">
            To signup for a private health insurance policy, you are required to
            have established a residence in Germany on or before your coverage
            start date.
            <br />
            <br />
            Establishing a residence means to be officially registered with an
            intention to stay in Germany for more than six months.
          </Trans>
        </InfoHint>
      </InfoHintContainer>
    </Center>
  );
};
