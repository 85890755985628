import { Reducer } from 'redux';
import { Action } from 'actions/type';
import axios from 'axios';
import { UserReferralInfo } from 'features/referralEngine/models';

type State = {
  jwt?: string;
  existingCustomer?: boolean;
  displayLogin?: boolean;
  referralEngine?: UserReferralInfo;
};

const reducer: Reducer<State, Action> = (state = {}, action) => {
  switch (action.type) {
    case 'SIGN_IN':
      return { ...state, jwt: action.jwt, displayLogin: false };
    case 'INVALIDATE_JWT':
      return { ...state, jwt: undefined, displayLogin: true };
    case 'CLEAR_ALL_REMOTE_DATA':
      return { ...state, jwt: undefined, existingCustomer: undefined };
    case 'ASYNC_OPERATION_ERRORED':
      const displayLogin =
        axios.isAxiosError(action.error) &&
        action.error?.response?.status === 401;
      return { ...state, ...(displayLogin ? { displayLogin: true } : {}) };
    case 'EXISTING_CUSTOMER':
      return { ...state, existingCustomer: action.existingCustomer };
    case 'UPDATE_REFERRAL_CODE_INFO':
      return {
        ...state,
        referralEngine: {
          ...state.referralEngine,
          ...action.referralInfo,
        },
      };
    default:
      return state;
  }
};

export default reducer;
