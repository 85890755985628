import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import Disclaimer from 'pages/disclaimer';

import Review from 'pages/questions/review';

import AddressQuestion from 'pages/questions/personalInformation/address';
import CoverageStartDateQuestion from 'pages/questions/personalInformation/coverageStartDate';
import CurrentlyLivingInGermanyQuestion from 'pages/questions/personalInformation/currentlyLivingInGermany';
import EmailQuestion from 'pages/questions/personalInformation/email';
import NameQuestion from 'pages/questions/personalInformation/name';
import MaritalStatusQuestion from 'pages/questions/personalInformation/maritalStatus';
import DateOfBirthQuestion from 'pages/questions/personalInformation/dateOfBirth';
import GenderQuestion from 'pages/questions/personalInformation/gender';
import PassportNumberQuestion from 'pages/questions/personalInformation/passportNumber';
import PassportExpiryQuestion from 'pages/questions/personalInformation/passportExpiry';
import NationalityQuestion from 'pages/questions/personalInformation/nationality';
import ResidencePermitStatusQuestion from 'pages/questions/personalInformation/residencePermitStatus';
import WhatTypeOfResidencePermitQuestion from 'pages/questions/personalInformation/whatTypeOfResidencePermit';

import EmployerNameQuestion from 'pages/questions/financialInformation/employerName';
import EmploymentStatusQuestion from 'pages/questions/financialInformation/employmentStatus';
import IncomeQuestion from 'pages/questions/financialInformation/income';
import HasGermanTaxIdQuestion from 'pages/questions/financialInformation/hasGermanTaxId';
import GermanTaxIdQuestion from 'pages/questions/financialInformation/germanTaxId';
import SchufaConsentQuestion from 'pages/questions/financialInformation/schufaConsent';
import WorkContractQuestion from 'pages/questions/financialInformation/workContract';
import WhatIsYourJobTitle from 'pages/questions/financialInformation/whatIsYourJobTitle';
import WorkingAsFreelancerSince from 'pages/questions/financialInformation/workingAsFreelancerSince';
import IncomeEstimate from 'pages/questions/financialInformation/incomeEstimate';
import IncomeExpectations from 'pages/questions/financialInformation/incomeExpectations';
import PreviousActivity from 'pages/questions/financialInformation/previousActivity';
import PreviousActivityExplanation from 'pages/questions/financialInformation/previousActivityExplanation';
import MonthlyIncomeBeforeTaxWhenEmployed from 'pages/questions/financialInformation/monthlyIncomeBeforeTaxWhenEmployed';
import IncomeStatement from 'pages/questions/financialInformation/incomeStatement';
import HasLinkedInProfile from 'pages/questions/financialInformation/hasLinkedInProfile';
import LinkedInProfileUrl from 'pages/questions/financialInformation/linkedInProfileUrl';
import HasWebsite from 'pages/questions/financialInformation/hasWebsite';
import WebsiteUrl from 'pages/questions/financialInformation/websiteUrl';
import WhatKindOfOtherEmployment from 'pages/questions/financialInformation/whatKindOfOtherEmployment';
import EmployedOutsideGermanyQuestion from 'pages/questions/financialInformation/employedOutsideGermany';

import CurrentHealthInsuranceQuestion from 'pages/questions/insuranceHistory/currentHealthInsurance';
import HasConsistentHealthInsuranceQuestion from 'pages/questions/insuranceHistory/hadConsistentHealthInsurance';
import HasHealthInsuranceQuestion from 'pages/questions/insuranceHistory/hasHealthInsurance';
import EndOfLastHealthInsuranceQuestion from 'pages/questions/insuranceHistory/endOfLastHealthInsurance';
import HasBeenInsuredBetweenHealthInsurancesQuestion from 'pages/questions/insuranceHistory/hasBeenInsuredBetweenHealthInsurances';

import HeightInCmQuestion from 'pages/questions/medicalHistory/heightInCm';
import WeightInKgQuestion from 'pages/questions/medicalHistory/weightInKg';
import SufferedFromConditionQuesiton from 'pages/questions/medicalHistory/sufferedFromCondition';
import SufferingFromConditionQuestion from 'pages/questions/medicalHistory/sufferingFromCondition';
import DentalConditionQuestion from 'pages/questions/medicalHistory/dentalCondition';
import MedicalHistoryYesNoQuestion from 'pages/questions/medicalHistory/medicalHistoryYesNoQuestion';
import HowManyTeethAreAffectedByConditonQuestion from 'pages/questions/medicalHistory/howManyTeethAreAffectedByConditon';
import UndergoingTreatmentQuestion from 'pages/questions/medicalHistory/undergoingTreatment';
import MedicalHistoryExplanation from 'pages/questions/medicalHistory/explanationQuestion';
import UnableToProduceChildren from 'pages/questions/medicalHistory/unableToProduceChildren';
import MissingBodyPart from 'pages/questions/medicalHistory/missingBodyPart';
import MaximumEyeDiopters from 'pages/questions/medicalHistory/maximumEyeDiopters';
import JawConditionQuestion from 'pages/questions/medicalHistory/jawConditionQuestion';
import MissingTeethUpperOrLowerJawQuestion from 'pages/questions/medicalHistory/missingTeethUpperOrLowerJaw';
import DiagnosedTreatedOrGivenMedicalAdviceQuestion from 'pages/questions/medicalHistory/diagnosedTreatedOrGivenMedicalAdvice';
import UpperJawMissingTeethQuestion from 'pages/questions/medicalHistory/missingTeeth/upperJaw';
import LowerJawMissingTeethQuestion from 'pages/questions/medicalHistory/missingTeeth/lowerJaw';
import AnswerCheck from 'pages/answerCheck';
import FinalStep from 'pages/finalStep';

import { FinancialHistoryQuestion } from 'models/questions/general/financialHistory';
import { PersonalInfoQuestion } from 'models/questions/general/personalInfo';

import { InsuranceHistoryQuestion } from 'models/questions/general/insuranceHistory';
import {
  MedicalHistoryQuestion,
  sufferingMedicalConditionMapping,
} from 'models/questions/general/medicalHistory';
import { Section, GeneralSectionId } from 'models/questions/general';
import { AssociatedShapeFromIdentifier } from 'utils/types';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import brokerMandate from 'pages/brokerMandate';
import BankStatement from 'pages/questions/financialInformation/bankStatement';
import FutureIncome from 'pages/questions/financialInformation/futureIncome';
import JobDescription from 'pages/questions/financialInformation/jobDescription';
import SelfEmploymentIntro from 'pages/selfEmploymentIntro';

export const personalInfoQuestionRoutes: {
  [Q in PersonalInfoQuestion['id']]: React.ComponentType;
} = {
  name: NameQuestion,
  gender: GenderQuestion,
  dateOfBirth: DateOfBirthQuestion,
  maritalStatus: MaritalStatusQuestion,
  address: AddressQuestion,
  coverageStartDate: CoverageStartDateQuestion,
  currentlyLivingInGermany: CurrentlyLivingInGermanyQuestion,
  nationality: NationalityQuestion,
  residencePermitStatus: ResidencePermitStatusQuestion,
  whatTypeOfResidencePermit: WhatTypeOfResidencePermitQuestion,
  passportNumber: PassportNumberQuestion,
  passportExpiry: PassportExpiryQuestion,
  email: EmailQuestion,
  personalInfoReview: () => (
    <Review questionId="personalInfoReview" sectionId="personalInfo" />
  ),
  phoneNumber: brokerMandate,
};

export const financialHistoryQuestionRoutes: {
  [Q in FinancialHistoryQuestion['id']]: React.ComponentType;
} = {
  employmentStatus: EmploymentStatusQuestion,
  employerName: EmployerNameQuestion,
  income: IncomeQuestion,
  employedOutsideGermany: EmployedOutsideGermanyQuestion,
  workContract: WorkContractQuestion,
  whatIsYourJobTitle: WhatIsYourJobTitle,
  workingAsFreelancerSince: WorkingAsFreelancerSince,
  incomeEstimate: IncomeEstimate,
  incomeExpectations: IncomeExpectations,
  previousActivity: PreviousActivity,
  previousActivityExplanation: PreviousActivityExplanation,
  monthlyIncomeBeforeTaxWhenEmployed: MonthlyIncomeBeforeTaxWhenEmployed,
  bankStatement: BankStatement,
  incomeStatement: IncomeStatement,
  futureIncome: FutureIncome,
  jobDescription: JobDescription,
  hasLinkedInProfile: HasLinkedInProfile,
  linkedInProfileUrl: LinkedInProfileUrl,
  hasWebsite: HasWebsite,
  websiteUrl: WebsiteUrl,
  whatKindOfOtherEmployment: WhatKindOfOtherEmployment,
  hasGermanTaxId: HasGermanTaxIdQuestion,
  germanTaxId: GermanTaxIdQuestion,
  schufaConsent: SchufaConsentQuestion,
  selfEmploymentIntro: SelfEmploymentIntro,
  financialHistoryReview: () => (
    <Review questionId="financialHistoryReview" sectionId="financialHistory" />
  ),
};

export const insuranceHistoryQuestionRoutes: {
  [Q in InsuranceHistoryQuestion['id']]: React.ComponentType;
} = {
  hadConsistentHealthInsurance: HasConsistentHealthInsuranceQuestion,
  hasHealthInsurance: HasHealthInsuranceQuestion,
  endOfLastHealthInsurance: EndOfLastHealthInsuranceQuestion,
  currentHealthInsurance: CurrentHealthInsuranceQuestion,
  hasBeenInsuredBetweenHealthInsurances: HasBeenInsuredBetweenHealthInsurancesQuestion,
  insuranceHistoryReview: () => (
    <Review questionId="insuranceHistoryReview" sectionId="insuranceHistory" />
  ),
};

export const medicalHistoryQuestionRoutes: {
  [Q in MedicalHistoryQuestion['id']]: React.ComponentType;
} = {
  insuranceLawDisclaimer: () => (
    <Disclaimer questionId="insuranceLawDisclaimer" />
  ),
  dataProcessingDisclaimer: () => (
    <Disclaimer questionId="dataProcessingDisclaimer" />
  ),
  heightInCm: HeightInCmQuestion,
  weightInKg: WeightInKgQuestion,
  diagnosedTreatedOrGivenMedicalAdvice: DiagnosedTreatedOrGivenMedicalAdviceQuestion,
  admittedToHospitalPast10years: function View() {
    const { t } = useTranslation();
    return (
      <MedicalHistoryYesNoQuestion
        questionId="admittedToHospitalPast10years"
        title={generalQuestionsTitleMapping.admittedToHospitalPast10years(t)}
      />
    );
  },
  hivPositive: function View() {
    const { t } = useTranslation();
    return (
      <MedicalHistoryYesNoQuestion
        questionId="hivPositive"
        title={generalQuestionsTitleMapping.hivPositive(t)}
      />
    );
  },
  hasReceivedPsychotherapy: function View() {
    const { t } = useTranslation();
    return (
      <MedicalHistoryYesNoQuestion
        questionId="hasReceivedPsychotherapy"
        title={generalQuestionsTitleMapping.hasReceivedPsychotherapy(t)}
      />
    );
  },
  unableToProduceChildren: UnableToProduceChildren,
  sufferedFromCondition: SufferedFromConditionQuesiton,
  planToUndergoMedicalTreatment: function View() {
    const { t } = useTranslation();
    return (
      <MedicalHistoryYesNoQuestion
        questionId="planToUndergoMedicalTreatment"
        title={generalQuestionsTitleMapping.planToUndergoMedicalTreatment(t)}
      />
    );
  },
  planToUndergoMedicalTreatmentExplanation: function View() {
    const { t } = useTranslation();
    return (
      <MedicalHistoryExplanation
        questionId="planToUndergoMedicalTreatmentExplanation"
        title={generalQuestionsTitleMapping.planToUndergoMedicalTreatmentExplanation(
          t
        )}
        explanationFor={generalQuestionsTitleMapping.planToUndergoMedicalTreatment(
          t
        )}
      />
    );
  },
  hasMissingBodyPart: function View() {
    const { t } = useTranslation();
    return (
      <MedicalHistoryYesNoQuestion
        questionId="hasMissingBodyPart"
        title={generalQuestionsTitleMapping.hasMissingBodyPart(t)}
      />
    );
  },
  missingBodyPart: MissingBodyPart,
  missinBodyPartExplanation: function View() {
    const { t } = useTranslation();
    return (
      <MedicalHistoryExplanation
        questionId="missinBodyPartExplanation"
        title={generalQuestionsTitleMapping.missinBodyPartExplanation(t)}
        explanationFor={generalQuestionsTitleMapping.missingBodyPart(t)}
      />
    );
  },
  sufferingFromCondition: SufferingFromConditionQuestion,
  upperJawMissingTeeth: UpperJawMissingTeethQuestion,
  lowerJawMissingTeeth: LowerJawMissingTeethQuestion,
  sufferingFromConditionExplanation: function View() {
    const { t } = useTranslation();
    return (
      <MedicalHistoryExplanation
        questionId="sufferingFromConditionExplanation"
        title={generalQuestionsTitleMapping.sufferingFromConditionExplanation(
          t
        )}
        explanationFor={sufferingMedicalConditionMapping(t).RECOGNIZED_HANDICAP}
      />
    );
  },
  hasTakenMedication: function View() {
    const { t } = useTranslation();
    return (
      <MedicalHistoryYesNoQuestion
        questionId="hasTakenMedication"
        title={generalQuestionsTitleMapping.hasTakenMedication(t)}
      />
    );
  },
  hasTakenMedicationExplanation: function View() {
    const { t } = useTranslation();
    return (
      <MedicalHistoryExplanation
        questionId="hasTakenMedicationExplanation"
        title={generalQuestionsTitleMapping.hasTakenMedicationExplanation(t)}
        explanationFor={generalQuestionsTitleMapping.hasTakenMedication(t)}
      />
    );
  },
  assistingVisionDevice: function View() {
    const { t } = useTranslation();
    return (
      <MedicalHistoryYesNoQuestion
        questionId="assistingVisionDevice"
        title={generalQuestionsTitleMapping.assistingVisionDevice(t)}
      />
    );
  },
  hasBeenAtHospitalBecauseOfVision: function View() {
    const { t } = useTranslation();
    return (
      <MedicalHistoryYesNoQuestion
        questionId="hasBeenAtHospitalBecauseOfVision"
        title={generalQuestionsTitleMapping.hasBeenAtHospitalBecauseOfVision(t)}
      />
    );
  },
  hadOperationBecauseOfVision: function View() {
    const { t } = useTranslation();
    return (
      <MedicalHistoryYesNoQuestion
        questionId="hadOperationBecauseOfVision"
        title={generalQuestionsTitleMapping.hadOperationBecauseOfVision(t)}
      />
    );
  },
  maximumEyeDiopters: MaximumEyeDiopters,
  undergoingTreatment: UndergoingTreatmentQuestion,
  hasMissingTeeth: function View() {
    const { t } = useTranslation();
    return (
      <MedicalHistoryYesNoQuestion
        questionId="hasMissingTeeth"
        title={generalQuestionsTitleMapping.hasMissingTeeth(t)}
      />
    );
  },
  missingTeethUpperOrLowerJaw: MissingTeethUpperOrLowerJawQuestion,
  dentalCondition: DentalConditionQuestion,
  howManyTeethAffectedByCondition: HowManyTeethAreAffectedByConditonQuestion,
  jawCondition: JawConditionQuestion,
  medicalHistoryReview: () => (
    <Review questionId="medicalHistoryReview" sectionId="medicalHistory" />
  ),
  finalSteps: FinalStep,
  answersCheck: AnswerCheck,
};

export const questionRoutes: {
  [S in GeneralSectionId]: {
    routes: {
      [Q in AssociatedShapeFromIdentifier<
        Section,
        'id',
        S
      >['question']['id']]: React.ComponentType;
    };
  };
} = {
  personalInfo: {
    routes: personalInfoQuestionRoutes,
  },
  financialHistory: {
    routes: financialHistoryQuestionRoutes,
  },
  insuranceHistory: {
    routes: insuranceHistoryQuestionRoutes,
  },
  medicalHistory: {
    routes: medicalHistoryQuestionRoutes,
  },
};
