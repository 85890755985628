import React, { useState, useEffect } from 'react';
import {
  AddOn,
  addOnNameMapping,
  tariffLongNameMapping,
} from '@getpopsure/private-health-insurance-pricing-engine';
import { Input, BottomOrRegularModal } from '@popsure/dirty-swan';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { useTranslation } from '@getpopsure/i18n-react';

import { ViewProps } from '..';
import { BankAccountDetails } from 'models';

import Loading from 'components/loading';

import PermissionToCollectIBAN from './permissionToCollectIBAN';
import healthInsuranceLogo from './img/health-insurance-logo.svg';
import styles from './style.module.scss';
import { ibanValidator, germanIbanValidator } from 'validators';
import AnimateHeight from 'react-animate-height';
import GenericError from 'components/genericError';
import infoSvg from './img/info.svg';
import { isBrokerVersion } from 'utils/isBrokerVersion';
import { preExistingConditionTitle } from 'components/liveQuote/components/util';
import {
  useReferralEngineFlag,
  ReferrerCodeVerify,
} from 'features/referralEngine';
import { getPersonalInfoMapping } from 'features/privatePayments';

export default ({
  quote,
  user,
  onSubmitBankAccountDetail,
  submitting,
  submittingError,
  loading,
  loadingError,
  fetchData,
  questionnaire,
}: ViewProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [IBAN, setIBAN] = useState('');
  const [BIC, setBIC] = useState('');
  const [SEPAMandate, setSEPAMandate] = useState(false);
  const [
    displayRiskSurchargeInfoNotice,
    setDisplayRiskSurchargeInfoNotice,
  ] = useState(false);
  const withReferralEngine = useReferralEngineFlag();

  useEffect(() => {
    setError(submittingError ?? '');
  }, [submittingError]);

  useEffect(() => {
    if (questionnaire === undefined) {
      throw new Error('Trying to sign sepa mandate without a questionnaire');
    }

    fetchData(questionnaire.id);
  }, [fetchData, questionnaire]);

  if (loading === true || quote === undefined || user === undefined) {
    return <Loading />;
  }

  if (loadingError !== undefined) {
    return <GenericError error={loadingError} />;
  }

  if (
    !questionnaire ||
    !questionnaire.answers ||
    !questionnaire.answers.personalInfo ||
    !questionnaire.answers.personalInfo.phoneNumber
  ) {
    throw new Error(`Incomplete questionnaire: ${questionnaire?.id}`);
  }

  const {
    ownContributions,
    employerContributions,
    tariffMonthlyPrice,
    totalMonthlyPrice,
    longTermCare,
    addOns,
    riskFactorSurcharge,
    statutorySurcharge,
    riskLevel,
  } = quote;

  const { firstName, lastName, dateOfBirth, email } = user;

  const {
    answers: {
      personalInfo: { address, phoneNumber, coverageStartDate },
    },
  } = questionnaire;

  const personalInfoMapping = getPersonalInfoMapping({
    t,
    firstName,
    lastName,
    dateOfBirth,
    email,
    address,
    phoneNumber,
  });

  const policyName = tariffLongNameMapping[quote.tariff];

  return (
    <>
      <div className="p-body">
        <img
          className={`d-block mt16 ${styles.logo}`}
          src={healthInsuranceLogo}
          alt=""
        />
        <div className="p-h4 tc-primary-500 ta-center mt16">
          {t('page.sepa.step.title', 'Step 3')}
        </div>
        <h1 className="p-h1 ta-center ws100">
          {t('page.sepa.title', 'Submit your application!')}
        </h1>
        <p className={`p-p ta-center wmx8 mt8 ${styles.description}`}>
          {t(
            'page.sepa.description',
            'This is the final step of your application. Below you can review your payment information.'
          )}
        </p>
        {riskLevel.level === 'X' && (
          <div className="p-notice--warning mt24">
            <h4 className="p-h4">
              {t('page.sepa.medicalhistory.warning.title', 'Please be aware')}
            </h4>
            <p className="p-p mt8">
              {t(
                'page.sepa.medicalhistory.warning.description',
                'Because of your medical history, further assessment of your responses will be needed to determine your precise risk level. If this results in a change in price, we will get in touch with you.'
              )}
            </p>
          </div>
        )}
        <div className={`mt32 ${styles['informations-container']}`}>
          <div className="w100">
            <div className={styles['policy-information']}>
              <h2 className="p-h2">
                {t('page.sepa.policyinfo.title', 'Policy information')}
              </h2>
              <div className={`mt24 ${styles.cell}`}>
                <div>
                  {t('page.sepa.policyinfo.name', '{{policyName}} Policy', {
                    policyName,
                  })}
                </div>
                <div className={`ta-right ${styles.value}`}>
                  {englishFormattedEuroCurrency(
                    tariffMonthlyPrice +
                      longTermCare +
                      (statutorySurcharge ?? 0)
                  )}
                </div>
              </div>
              <div className="p-p tc-grey-500 mt8">
                {t('page.sepa.deductible', '{{deductible}} annual deductible', {
                  deductible: englishFormattedEuroCurrency(quote.deductible, 0),
                })}
              </div>

              {riskLevel.level !== 'RG0' && (
                <>
                  <h4 className="p-h4 mt24">
                    {t(
                      'page.sepa.preexistingconditions.title',
                      'Pre-existing conditions'
                    )}
                  </h4>
                  <div className={`mt16 ${styles.cell}`}>
                    <div className="d-flex">
                      <div>{preExistingConditionTitle(riskLevel)}</div>
                      <img
                        className="c-pointer ml8"
                        role="button"
                        tabIndex={0}
                        src={infoSvg}
                        alt={t(
                          'page.sepa.risklevel',
                          'risk level informations'
                        )}
                        onClick={() => {
                          setDisplayRiskSurchargeInfoNotice(true);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setDisplayRiskSurchargeInfoNotice(true);
                          }
                        }}
                      />
                    </div>
                    <div className={`ta-right ${styles.value}`}>
                      {riskLevel.level === 'X'
                        ? '???'
                        : englishFormattedEuroCurrency(riskFactorSurcharge)}
                    </div>
                  </div>
                </>
              )}
              {addOns.length > 0 && (
                <>
                  <h4 className="p-h4 mt24">Add ons</h4>
                  {addOns.map(({ addOn, monthlyPrice }) => (
                    <div key={addOn} className={`mt16 ${styles.cell}`}>
                      <div>{addOnNameMapping[addOn as AddOn]}</div>
                      <div className={styles.value}>
                        {englishFormattedEuroCurrency(monthlyPrice)}
                      </div>
                    </div>
                  ))}
                </>
              )}
              <hr className={`mt24 ${styles.separator}`} />
              <div className={`mt16 ${styles.cell}`}>
                <div className={styles.total}>
                  {t('page.sepa.monthlytotal.title', 'Your monthly total')}
                </div>
                <div className={styles.total}>
                  {englishFormattedEuroCurrency(
                    ownContributions ?? totalMonthlyPrice
                  )}
                </div>
              </div>
              {employerContributions && (
                <div className={`mt16 ${styles.cell}`}>
                  <div>
                    {t(
                      'page.sepa.monthlytotal.employercontribution.title',
                      'Your employer will pay'
                    )}
                  </div>
                  <div className={styles.value}>
                    {englishFormattedEuroCurrency(employerContributions)}
                  </div>
                </div>
              )}
              {withReferralEngine && <ReferrerCodeVerify />}
            </div>
          </div>
          <div className="w100">
            <div className={styles['personal-information']}>
              <h2 className="p-h2">
                {t('page.sepa.personalinfo.title', 'Personal information')}
              </h2>
              <div className="mt8">
                {personalInfoMapping.map(({ key, value }) => (
                  <div key={key} className={`mt16 tc-grey-500 ${styles.cell}`}>
                    <div className={styles['personal-info-key']}>{key}</div>
                    <div className="ta-right">{value}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <h2 className="p-h2 mt32 d-block ta-center">
          {t('page.sepa.setupsepa.title', 'Set up direct debit (SEPA mandate)')}
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setError('');

            if (ibanValidator(IBAN) === false) {
              setError(
                t(
                  'page.sepa.setupsepa.incorrectiban.error',
                  'The IBAN you have entered was not recognised. Please re-enter a valid IBAN and try again'
                )
              );
              return;
            }

            const bankAccountDetails: BankAccountDetails = { iban: IBAN };
            if (BIC) {
              bankAccountDetails.bic = BIC;
            }

            onSubmitBankAccountDetail(bankAccountDetails);
          }}
        >
          <Input
            data-cy="sepa-mandate-field"
            className={`mt16 ws6 wmx100 d-block ${styles.iban}`}
            placeholder="IBAN"
            required={true}
            value={IBAN}
            error={error}
            onChange={(e) => {
              setIBAN(e.target.value);
            }}
          />
          {isBrokerVersion && (
            <AnimateHeight
              duration={300}
              height={
                IBAN.length >= 2 && !germanIbanValidator(IBAN) ? 'auto' : 0
              }
            >
              <Input
                data-cy="bic-field"
                className={`mt16 ws6 wmx100 d-block ${styles.iban}`}
                placeholder="BIC"
                required={
                  isBrokerVersion &&
                  IBAN.length >= 2 &&
                  !germanIbanValidator(IBAN)
                    ? true
                    : false
                }
                value={BIC}
                onChange={(e) => {
                  setBIC(e.target.value);
                }}
              />
            </AnimateHeight>
          )}
          <PermissionToCollectIBAN className="mt24" />
          <div className={`wmx7 p-p--small ${styles['sepa-checkbox']}`}>
            <input
              className="p-checkbox"
              type="checkbox"
              id="sepa-mandate-checkbox"
              required={true}
              checked={SEPAMandate}
              onChange={(e) => setSEPAMandate(e.target.checked)}
            />
            <label
              className="p-label ta-left"
              htmlFor="sepa-mandate-checkbox"
              data-cy="sepa-mandate-checkbox"
            >
              {t(
                'page.sepa.legal.agreement',
                'I agree to set up direct debit by SEPA mandate and confirm to have full access to the provided account.'
              )}
            </label>
          </div>
          <button
            data-cy="sepa-mandate-pay"
            type="submit"
            className={`p-btn--primary ${
              submitting ? 'p-btn--loading' : ''
            } mt24 ws3 ${styles['pay-button']}`}
          >
            {t('page.sepa.continue.action', 'Buy now')}
          </button>
        </form>
        <p
          className={`p-p--small d-block mt24 wmx8 ta-center ${styles['legal-text']}`}
        >
          {t(
            'page.sepa.legal.startdate.1',
            'Your cover will begin on {{coverageStartDate}} if you click "Buy now" but only if you have received the official confirmation of insurance coverage.',
            { coverageStartDate }
          )}{' '}
          <span className="fw-bold">
            {t(
              'page.sepa.legal.startdate.2',
              'A withdrawal of the contract can be declared within 14 days after receiving the official confirmation of insurance coverage.'
            )}
          </span>
        </p>
      </div>
      <BottomOrRegularModal
        title={'Risk surcharge'}
        isOpen={displayRiskSurchargeInfoNotice}
        onClose={() => setDisplayRiskSurchargeInfoNotice(false)}
      >
        <p className={`p-p ${styles['modal-content']}`}>
          {t(
            'page.sepa.risksurchage.notice.warning',
            'Because of your medical history, further assessment of your responses will be needed to determine your precise risk level. If this results in a change in price, we will get in touch with you.'
          )}
        </p>
      </BottomOrRegularModal>
    </>
  );
};
