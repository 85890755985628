import moment from 'moment';
import deepEqual from 'deep-equal';
import cloneDeep from 'lodash.clonedeep';

import { AppState } from 'reducers';
import {
  Section,
  GeneralSectionId,
  GeneralQuestionnaireModel,
} from 'models/questions/general';
import { MedicalHistoryQuestion } from 'models/questions/general/medicalHistory';
import { getSectionOrder, getQuestionOrder } from './ordering';
import { Question } from 'models/questions';
import { LabelType } from 'actions/type';
import { getBlocker } from './blocker';

export const getIsSectionCompleted = (
  state: AppState,
  section: GeneralSectionId
): boolean => {
  const reachableQuestionIds = getReachableQuestionsInSection(
    state,
    section
  ).map((q) => q.questionId);
  const order = getQuestionOrder(state, section).map((q) => q.questionId);
  const answerKeys = Object.keys(
    state.questionnaire[section] ?? []
  ).filter((answerKey) => reachableQuestionIds.includes(answerKey as any));

  return (
    answerKeys.slice(0).sort().toString() === order.slice(0).sort().toString()
  );
};

export const getReachableSections = (state: AppState): GeneralSectionId[] => {
  const sectionOrder = getSectionOrder();
  const indexOfCurrentSection = sectionOrder.findIndex(
    (sectionId) => getIsSectionCompleted(state, sectionId) === false
  );

  if (indexOfCurrentSection === -1) {
    // If we can’t find any incomplete section,
    // it means that all the section has been completed
    return sectionOrder;
  }

  return sectionOrder.slice(0, indexOfCurrentSection + 1);
};

export const getReachableQuestionsInSection = (
  state: AppState,
  sectionId: GeneralSectionId
): Question[] => {
  const order = getQuestionOrder(state, sectionId);

  const index = order.findIndex(({ type, questionId }) => {
    switch (type) {
      case 'general':
        if (getBlocker(state, sectionId, questionId) !== undefined) {
          return true;
        }

        return (
          (state.questionnaire as any)?.[sectionId]?.[questionId] === undefined
        );
      case 'investigation':
        return (state.investigation as any)?.[questionId] === undefined;
      default:
        return false;
    }
  });

  if (index === -1) {
    return order;
  }

  return order.slice(0, index + 1);
};

export const getLastReachableQuestion = (state: AppState): Question => {
  const reachableSections = getReachableSections(state);
  const lastReachableSection = reachableSections[reachableSections.length - 1];

  const reachableQuestions = getReachableQuestionsInSection(
    state,
    lastReachableSection
  );
  return reachableQuestions[reachableQuestions.length - 1];
};

export const getProgressForQuestion = (
  state: AppState,
  question: Question
):
  | {
      percentage: number;
      currentQuestion: number;
      totalNumberOfQuestions: number;
    }
  | undefined => {
  const order = getQuestionOrder(state, question.sectionId);

  const currentQuestion = order.findIndex((qo) => deepEqual(qo, question));

  if (currentQuestion === -1) {
    return undefined;
  }

  const totalNumberOfQuestions = order.length - 1;
  const percentage = (currentQuestion / totalNumberOfQuestions) * 100;

  return { percentage, currentQuestion, totalNumberOfQuestions };
};

export const getQuestionAfter = (
  state: AppState,
  question: Question
): Question | undefined => {
  const sectionOrder = getSectionOrder();
  const lastSection = sectionOrder[sectionOrder.length - 1];

  const reachableSections = getReachableSections(state);
  const reachableQuestions = getQuestionOrder(state, question.sectionId);
  const index = reachableQuestions.findIndex((rq) => deepEqual(rq, question));

  if (index === -1) {
    return undefined;
  }

  if (
    getIsSectionCompleted(state, question.sectionId) &&
    reachableQuestions[index + 1] === undefined
  ) {
    if (question.sectionId === lastSection) {
      // We've reached the last question of the last section
      return undefined;
    }

    const nextSection =
      reachableSections[reachableSections.indexOf(question.sectionId) + 1];
    const nextQuestion = getQuestionOrder(state, nextSection)[0];
    return nextQuestion;
  }

  return reachableQuestions[index + 1];
};

export const getQuestionBefore = (
  state: AppState,
  question: Question
): Question | undefined => {
  const orderedSections = getSectionOrder();
  const currentSectionIndex = orderedSections.indexOf(question.sectionId);
  const isFirstSection = currentSectionIndex === 0;
  const currentQuestionIndex = getQuestionOrder(
    state,
    question.sectionId
  ).findIndex((qo) => deepEqual(qo, question));
  const isFirstQuestionOfSection = currentQuestionIndex === 0;

  if (isFirstQuestionOfSection) {
    if (isFirstSection) {
      // First question of the first section
      // There's no previous question
      return undefined;
    }

    const previousSection = orderedSections[
      currentSectionIndex - 1
    ] as GeneralSectionId;
    const [previousQuestion] = getQuestionOrder(state, previousSection).slice(
      -1
    );

    return previousQuestion;
  }

  return getQuestionOrder(state, question.sectionId)[currentQuestionIndex - 1];
};

export const getAllAnswersAndLabelsForSection = (
  state: AppState,
  section: GeneralSectionId
): (Section['question'] & { label?: LabelType })[] => {
  const reachableQuestions = getReachableQuestionsInSection(state, section);
  return Object.entries(state.questionnaire[section] ?? {})
    .filter(([questionId]) =>
      reachableQuestions.map((q) => q.questionId).includes(questionId as any)
    )
    .map(
      ([id, answer]) =>
        ({
          id,
          answer,
          label: state.metadata.answers?.[section]?.[id],
        } as Section['question'])
    );
};

export const getQuestionsNeedInvestigation = (state: AppState): string[] => {
  const medicalHistory = state.questionnaire.medicalHistory;

  const toReturn: MedicalHistoryQuestion['id'][] = [];

  if (medicalHistory?.diagnosedTreatedOrGivenMedicalAdvice === true) {
    toReturn.push('diagnosedTreatedOrGivenMedicalAdvice');
  }

  if (medicalHistory?.admittedToHospitalPast10years === true) {
    toReturn.push('admittedToHospitalPast10years');
  }

  if (medicalHistory?.hasReceivedPsychotherapy === true) {
    toReturn.push('hasReceivedPsychotherapy');
  }

  if (
    medicalHistory?.sufferedFromCondition &&
    medicalHistory?.sufferedFromCondition?.length >= 1 &&
    medicalHistory?.sufferedFromCondition?.includes('NONE') === false
  ) {
    toReturn.push('sufferedFromCondition');
  }

  if (
    medicalHistory?.sufferingFromCondition &&
    medicalHistory?.sufferingFromCondition?.length >= 1
  ) {
    if (medicalHistory.sufferingFromCondition.includes('NONE')) {
      // nothing
    } else if (
      medicalHistory.sufferingFromCondition.length === 1 &&
      medicalHistory.sufferingFromCondition.includes('RECOGNIZED_HANDICAP')
    ) {
      // nothing
    } else {
      toReturn.push('sufferingFromCondition');
    }
  }

  return toReturn;
};

export const getQuestionnaireWithoutUnreachableQuestions = (
  state: AppState
): GeneralQuestionnaireModel => {
  const questionnaireCopy = cloneDeep(state.questionnaire);

  (Object.keys(questionnaireCopy) as GeneralSectionId[]).forEach(
    (sectionId) => {
      // TODO: Check if this work for non-general questions (investigation…)
      const reachableQuestionIds = getReachableQuestionsInSection(
        state,
        sectionId
      ).map((q) => q.questionId);
      const toDelete = Object.keys(
        (questionnaireCopy as any)[sectionId]
      ).filter(
        (k) =>
          reachableQuestionIds.includes(k as any) === false &&
          k !== 'phoneNumber' // PhoneNumber moved from PersonalInfoQuestionOrder
      );

      toDelete.forEach(
        (keyToDelete) =>
          delete (questionnaireCopy as any)[sectionId][keyToDelete]
      );
    }
  );

  return { ...questionnaireCopy };
};

export const getReachedAgeThisYear = (state: AppState): number | undefined => {
  return state.questionnaire.personalInfo?.dateOfBirth
    ? moment().year() -
        moment(state.questionnaire.personalInfo.dateOfBirth).year()
    : undefined;
};

export const getReachedAgeWhenPolicyStart = (
  state: AppState,
  policyStartYear = getPolicyStartYear(state)
): number | undefined => {
  const dateOfBirth = state.questionnaire.personalInfo?.dateOfBirth;
  if (!dateOfBirth || !policyStartYear) {
    return undefined;
  }

  const yearOfBirth = moment(dateOfBirth).year();

  return policyStartYear - yearOfBirth;
};

export const getPolicyStartYear = (state: AppState): number | undefined => {
  const coverageStartDate = state.questionnaire.personalInfo?.coverageStartDate;
  if (coverageStartDate) {
    return moment(coverageStartDate).year();
  }
  return undefined;
};

export const getEmail = (state: AppState) => {
  return (
    state.questionnaire.personalInfo?.email ?? state.remoteData.user?.email
  );
};
