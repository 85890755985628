import { storeAnsweredQuestion } from 'actions';
import { EmploymentStatus } from 'models/questions/general/financialHistory';

export const setDateOfBirth = (dateOfBirth: string) =>
  storeAnsweredQuestion(
    {
      sectionId: 'personalInfo',
      questionId: 'dateOfBirth',
      type: 'general',
    },
    dateOfBirth
  );

export const setIncome = (income: number) =>
  storeAnsweredQuestion(
    {
      sectionId: 'financialHistory',
      questionId: 'income',
      type: 'general',
    },
    income
  );

export const setEmploymentStatus = (employmentStatus: EmploymentStatus) =>
  storeAnsweredQuestion(
    {
      sectionId: 'financialHistory',
      questionId: 'employmentStatus',
      type: 'general',
    },
    employmentStatus
  );

export const setCoverageStartDate = (coverageStartDate: string) =>
  storeAnsweredQuestion(
    {
      sectionId: 'personalInfo',
      questionId: 'coverageStartDate',
      type: 'general',
    },
    coverageStartDate
  );
