import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import { legal } from '@getpopsure/private-constants';

export const REFERRAL_ENGINE_FEATURE_FLAG_ID = 'app_referral_engine';

export const FORMATTED_BONUS_AMOUNT = englishFormattedEuroCurrency(15, true);

export const REFERRAL_VERIFY_OPERATION_ID = 'post.referral.verify';

export const FORMATTED_LIMIT_AMOUNT = englishFormattedEuroCurrency(300, 0);

export const REFERRAL_ENGINE_TC_URL = legal.referralEngineTermsAndConditions;
