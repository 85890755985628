import { Address } from '@popsure/public-models';
import moment from 'moment';

import EmailValidator from 'email-validator';

import { UploadedFile } from 'models';

export function nameValidator(
  name:
    | {
        firstName?: string;
        lastName?: string;
      }
    | undefined
): boolean {
  if (!name) {
    return false;
  }

  const { firstName, lastName } = name;

  if (!firstName || !lastName) {
    return false;
  }

  return firstName.length > 0 && lastName.length > 0;
}

export const MINIMUM_SIGNUP_AGE = 18;
export function ageValidator(input?: string): boolean {
  const diff = moment.utc().diff(moment.utc(input), 'years');
  return diff >= MINIMUM_SIGNUP_AGE;
}

export function passportExpiryValidator(input?: string): boolean {
  const diff = moment.utc(input).diff(moment.utc(), 'days');
  return diff > 30;
}

export function trueValidator(input: boolean | undefined): boolean {
  return input === true;
}

export function nonEmptyStringValidator(input?: string): boolean {
  if (!input) {
    return false;
  }
  return input.length > 0;
}

export function nonEmptyValidator(input?: any): boolean {
  return input !== undefined;
}

export function nonEmptyArrayValidator(input?: any[]): boolean {
  if (!input) {
    return false;
  }
  return input.length > 0;
}

export function nonEmptySetValidator(input?: Set<any>): boolean {
  if (!input) {
    return false;
  }
  return input.size > 0;
}

export function arrayOfUploadedFilesValidator(input?: UploadedFile[]): boolean {
  return input !== undefined && input.length > 0;
}

export function emailValidator(input?: string): boolean {
  if (input) {
    return EmailValidator.validate(input);
  }

  return false;
}

export function addressValidator(input?: Partial<Address>): boolean {
  if (!input) {
    return false;
  }

  const { street, houseNumber, postcode, city, country } = input;

  if (!street || !houseNumber || !postcode || !city || !country) {
    return false;
  }

  return true;
}

// Simple IBAN validation
// We just check if the IBAN is in the form
// [A-Z] XXXXXXXXXX
export function ibanValidator(input: string): boolean {
  return /^[A-z]{2}[0-9]{2}[0-9A-z]+$/.test(input.replace(/ +/g, ''));
}

export function germanIbanValidator(input: string): boolean {
  const ibanCountry = input.substr(0, 2);
  if (ibanCountry.toLowerCase() === 'de') {
    return true;
  }
  return false;
}
