import jwtDecode from 'jwt-decode';
import { store } from 'reducers';
import { invalidateJWT } from 'actions/session';
import { clearAllRemoteData } from 'actions';

const IS_NEW_SESSION = 'IS_NEW_SESSION';

export class Session {
  public static setSessionHasStarted() {
    sessionStorage.setItem(IS_NEW_SESSION, 'false');
  }

  public static get isNewSession(): boolean {
    return sessionStorage.getItem(IS_NEW_SESSION) !== 'false';
  }
}

// Sets timer on token check with difference of expiration timestamp - current timestamp
export function setTokenCheckTimer(jwt: string) {
  const nowUnixTimeStamp = Math.floor(Date.now() / 1000);
  const expireTimeLimit = Math.max(
    getExpirationTimestamp(jwt) - nowUnixTimeStamp,
    0
  );

  return setTimeout(
    () =>
      checkTokenExpire(jwt, () => {
        store.dispatch(invalidateJWT());
        store.dispatch(clearAllRemoteData());
      }),
    expireTimeLimit * 1000
  );
}

// Returns token expiration in UNIX timestamp from JWT
export function getExpirationTimestamp(jwt: string): number {
  const { exp } = jwtDecode(jwt);

  if (exp === undefined) {
    throw new Error("JWT doesn't contain exp field");
  }

  return exp;
}

// Checks if token is expired - if expired, will dispatch log out and show modal for "session expired"
export function checkTokenExpire(jwt: string, callback: () => void) {
  const nowUnixTimeStamp = Math.floor(Date.now() / 1000);
  const isExpired = getExpirationTimestamp(jwt) <= nowUnixTimeStamp;

  if (isExpired) {
    callback();
  }
}
