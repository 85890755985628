import { Reducer } from 'redux';

import { Action } from 'actions/type';
import { BrokerInfo } from 'models/brokerInfo';

export type State = { answers?: any; brokerInfo?: BrokerInfo };

const initialState: State = {};

const reducer: Reducer<State, Action> = (
  state = initialState,
  action: Action
) => {
  switch (action.type) {
    case 'STORE_BROKER_INFO':
      return {
        ...state,
        brokerInfo: action.info,
      };
    case 'STORE_ANSWERED_QUESTION':
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.question.sectionId]: {
            ...state?.answers?.[action.question.sectionId],
            [action.question.questionId]: action.label,
          },
        },
      };
    case 'ANSWERED_SPECIFY_QUESTION':
      if (
        action.question.type !== 'specify' ||
        action.question.specifyQuestionId === undefined
      ) {
        return state;
      }

      const { conditionId, specifyQuestionId } = action.question;

      return {
        ...state,
        answers: {
          ...state.answers,
          specify: {
            ...state?.answers?.specify,
            [conditionId]: {
              ...state.answers?.specify?.[conditionId],
              [specifyQuestionId]: action.label,
            },
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
