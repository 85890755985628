import {
  Tariff,
  DeductibleForTariff,
  AddOn,
  tariffLongNameMapping,
} from '@getpopsure/private-health-insurance-pricing-engine';

import { AppState } from 'reducers';

export const getTariffAndDeductible = <T extends Tariff>(
  state: AppState
): { tariff: T; deductible: DeductibleForTariff<T> } | undefined => {
  const { tariff, deductible } = state.tariff;

  if (!tariff || !deductible) {
    return undefined;
  }

  return { deductible, tariff } as {
    tariff: T;
    deductible: DeductibleForTariff<T>;
  };
};

export const getTariffName = (state: AppState): string | null => {
  const tariffAndDeductible = getTariffAndDeductible(state);

  if (!tariffAndDeductible) {
    return null;
  }

  return tariffLongNameMapping[tariffAndDeductible.tariff];
};

export const getAddOns = (state: AppState): AddOn[] => {
  return state.tariff.addOns ?? [];
};

export const getSickDayPayout = (state: AppState) => {
  return state.tariff.sickDayPayout;
};
