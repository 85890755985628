import React, { useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from '@getpopsure/i18n-react';

import { InfoHintContainer, InfoHint } from 'components/infoHint';
import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { questionnaireLegalText } from 'models/questions/text/legal';
import { possibleCoverageStartDateFromTodaysDate } from './util';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.coverageStartDate?.(t)}
      </h1>
      <InfoHintContainer className="mt8">
        <WrappedForm
          validator={nonEmptyValidator}
          sectionId="personalInfo"
          questionId="coverageStartDate"
        >
          {({ value, setValue }) => {
            useEffect(() => {
              if (!value) {
                setValue(possibleCoverageStartDateFromTodaysDate()[1]);
              }
            }, [value, setValue]);

            return (
              <select
                className="p-select w100 ws5"
                id="coverageStartDate"
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                value={value}
              >
                {possibleCoverageStartDateFromTodaysDate().map((startDate) => (
                  <option key={startDate} value={startDate}>
                    {moment(startDate).format('Do MMMM YYYY')}
                  </option>
                ))}
              </select>
            );
          }}
        </WrappedForm>
        <InfoHint
          title={t(
            'questions.coveragestartdate.hint.title',
            'Choosing a start date'
          )}
        >
          {questionnaireLegalText.personalInfo?.coverageStartDate}
        </InfoHint>
      </InfoHintContainer>
    </Center>
  );
};
