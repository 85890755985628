import { connect } from 'react-redux';

import { AppState } from 'reducers';
import View from './components';
import { getPricesForTariff, getContributions } from 'selectors/price';
import { AddOn } from '@getpopsure/private-health-insurance-pricing-engine';
import { RiskLevel } from 'models/price';
import { getRiskLevel } from 'selectors/specify';
import { getTariffName } from 'selectors/tariff';

interface StateProps {
  totalPerMonth?: number;
  premium?: number;
  longTermCare?: number;
  statutorySurcharge?: number;
  riskLevel?: { level: RiskLevel; reason?: string };
  riskFactorSurcharge?: number;
  addOns?: { id: AddOn; surcharge: number }[];
  policyName?: string;
  ownContribution?: number;
  employerContribution?: number | null;
}

export type ViewProps = StateProps;

const mapStateToProps = (state: AppState): StateProps => {
  const prices = getPricesForTariff(state);
  const policyName = getTariffName(state);
  const contributions = getContributions(state);

  if (!prices || !policyName) {
    return {};
  }

  const riskLevel = getRiskLevel(state);

  return {
    totalPerMonth: prices.totalPrice,
    premium: prices.tariffPrice,
    statutorySurcharge: prices.statutorySurcharge,
    longTermCare: prices.longTermCare,
    riskLevel,
    riskFactorSurcharge: prices.riskSurcharge,
    addOns: Object.entries(prices.addOns).map(([k, v]) => ({
      id: k as AddOn,
      surcharge: v!,
    })),
    policyName,
    ownContribution: contributions?.ownContributions,
    employerContribution: contributions?.employerContributions,
  };
};

export default connect<StateProps, unknown, unknown, AppState>(mapStateToProps)(
  View
);
