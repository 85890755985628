import React, { useState } from 'react';
import { Button, BottomOrRegularModal } from '@popsure/dirty-swan';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from '@getpopsure/i18n-react';
import SignIn from 'components/singIn';
import { AppState } from 'reducers';
import { getEmail } from 'selectors';
import { restartQuestionnaire } from 'actions';

function SigninModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [displayLoginCode, setDisplayLoginCode] = useState(false);
  const displayLogin = useSelector(
    (state: AppState) => state.session.displayLogin
  );
  const email = useSelector(getEmail);

  if (!email) {
    return null;
  }

  return (
    <BottomOrRegularModal
      dismissible={false}
      title={t('page.sessionexpired.signin.title', 'Sign in to continue')}
      isOpen={!!displayLogin}
      onClose={() => null}
    >
      {displayLoginCode ? (
        <SignIn
          email={email}
          signedInCallback={() => {
            setDisplayLoginCode(false);
          }}
        />
      ) : (
        <div className="p24">
          <p className="p-p">
            {t(
              'page.sessionexpired.description',
              'We haven’t seen you in a while and want to make sure it’s you.'
            )}
          </p>
          <div className="mt16">
            <Button
              className="mr16"
              buttonTitle={t('page.sessionexpired.signin.action', 'Continue')}
              buttonType="primary"
              type="button"
              data-cy="sign-in-now-button"
              onClick={() => {
                setDisplayLoginCode(true);
              }}
            />
            <Button
              buttonTitle={t(
                'page.sessionexpired.startover.action',
                'Start over'
              )}
              buttonType="secondary"
              type="button"
              data-cy="start-over-button"
              onClick={() => {
                dispatch(restartQuestionnaire());
              }}
            />
          </div>
        </div>
      )}
    </BottomOrRegularModal>
  );
}

export default SigninModal;
